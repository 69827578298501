import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Alert } from '@/components/ui/alert'
import { useDeleteSSHKey, useGetSSHKeys } from '@/services/sshkeys'
import { CreateSSHKey } from './CreateSSHKey'
import { CopyButton } from '@/components/copy-button'
import { Spinner } from '@/components/spinner'
import { Trash2 } from 'lucide-react'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

export const DashboardSSHKeys = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean | string>(
    false
  )

  const {
    data: sshKeys = [],
    isLoading: isKeysLoading,
    error: keysError,
  } = useGetSSHKeys()

  const { mutate: deleteSSHKey, isPending: isDeletingSSHKey } = useDeleteSSHKey(
    {
      onSuccess: () => {
        setOpenDeleteDialog(false)
      },
    }
  )

  const handleDeleteSSHKey = async () => {
    if (typeof openDeleteDialog !== 'string') return
    deleteSSHKey(openDeleteDialog)
  }

  return (
    <div>
      <h1 className="text-3xl font-medium mb-4">SSH Access</h1>
      <div className="mb-6">
        <p className="text-foreground/60">
          Adding an SSH key allows you to securely access compute resources
          through the terminal using the corresponding private key. You can add
          a maximum of three separate SSH keys. The system automatically applies
          these keys during server deployment as an option.
        </p>
      </div>

      <h2 className="text-2xl font-medium mt-12 mb-4">
        Your SSH Keys ({sshKeys.length}/3)
      </h2>

      <div className="space-y-4 mb-12">
        {isKeysLoading ? (
          <Spinner className="mx-0" />
        ) : sshKeys.length > 0 ? (
          sshKeys.map((key) => (
            <div key={key.id} className="flex items-center space-x-4 rounded">
              <p className="flex-1 rounded-sm p-3 font-mono max-w-[500px] truncate text-sm bg-primary/10 font">
                {key.key}
              </p>

              <CopyButton textToCopy={key.key} variant="secondary" />

              <Button
                variant="outline-destructive"
                className="flex items-center gap-2"
                onClick={() => setOpenDeleteDialog(key.id)}
              >
                <Trash2 className="h-4 w-4" />
                Delete
              </Button>
            </div>
          ))
        ) : (
          <div className="text-foreground/50">No SSH Keys Available</div>
        )}
      </div>

      {sshKeys?.length < 3 && <CreateSSHKey />}

      {keysError?.message && (
        <Alert variant="destructive" className="mt-4">
          {keysError.message}
        </Alert>
      )}

      <ConfirmationDialog
        open={!!openDeleteDialog}
        onOpenChange={(open) => {
          if (!isDeletingSSHKey) {
            setOpenDeleteDialog(open ? openDeleteDialog : false)
          }
        }}
        title="Confirm Delete"
        description="Are you sure you want to delete this SSH Key? This action cannot be undone."
        buttons={{
          confirm: {
            label: 'Delete',
            isDestructive: true,
            isLoading: isDeletingSSHKey,
          },
          cancel: {
            label: 'Cancel',
          },
        }}
        onConfirm={handleDeleteSSHKey}
      />
    </div>
  )
}
