import {
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import { Avatar } from '@/components/ui/avatar'
import { ArrowUp } from 'lucide-react'
import { type NotificationItemProps } from './types'
import { formatTransactionDate } from './utils'
import { MarkAsReadButton } from '.'

export const TopUpNotification = ({
  notification,
  isLoading,
  onMarkAsRead,
}: NotificationItemProps) => (
  <>
    <CardHeader className="py-4 px-5 flex flex-row items-center justify-between space-y-0">
      <div className="flex items-center gap-3">
        <Avatar
          size="sm"
          className="flex items-center justify-center bg-emerald-600/20 dark:bg-emerald-600/30"
        >
          <ArrowUp className="h-4 w-4 text-emerald-600" />
        </Avatar>
        <CardTitle className="text-base font-semibold">
          Admin Organization Top Up
        </CardTitle>
      </div>
    </CardHeader>

    <CardContent className="px-5 py-3">
      <p className="text-sm text-foreground/80 leading-relaxed">
        {notification.detail}
      </p>
      <p className="text-xs text-foreground/60 mt-3">
        Updated on {formatTransactionDate(notification.created_at)}
      </p>
    </CardContent>

    {!notification.is_read && (
      <CardFooter className="px-5 py-2 flex justify-end">
        <MarkAsReadButton
          isLoading={isLoading}
          onMarkAsRead={() => onMarkAsRead(notification.notification_id)}
        />
      </CardFooter>
    )}
  </>
)
