export interface CSVRow {
  email: string
  type: string
}

export enum UserType {
  USER = 'User',
  ADMIN = 'Admin',
}

export type EmailEntry = {
  email: string
  type: UserType
}

export interface OrgUser {
  email: string
  status: string // "active" or "invited"
}

export interface EmailValidationResult {
  validEmails: Array<{ email: string; type: UserType }>
  existingCount: number
  duplicateCount: number
}
