import { useForm } from 'react-hook-form'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { useUserStore } from '@/store/UserStore'
import Gravatar from 'react-gravatar'
import { useUpdateUserProfile } from '@/services/profile'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { capitalize } from '@/helpers/string'
import { UserPen } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
  FileUploadSchema,
  ProfileFormValues,
  ValidationMessages,
} from './types'

const ProfileFormSchema = z.object({
  firstName: z
    .string()
    .min(1, ValidationMessages.required('First name'))
    .max(50, ValidationMessages.maxLength('First name', 50)),
  lastName: z
    .string()
    .min(1, ValidationMessages.required('Last name'))
    .max(50, ValidationMessages.maxLength('Last name', 50)),
  avatar: FileUploadSchema,
})

export const MyProfileForm = () => {
  const { user } = useUserStore()
  const { data } = useActiveOrgProfile()
  const { mutateAsync, isPending } = useUpdateUserProfile()

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(ProfileFormSchema),
    defaultValues: {
      firstName: data?.profile?.first_name || '',
      lastName: data?.profile?.last_name || '',
    },
  })

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      form.setValue('avatar', file)
    }
  }

  const onSubmit = async (formData: ProfileFormValues) => {
    const submissionData = new FormData()
    submissionData.append('first_name', capitalize(formData.firstName))
    submissionData.append('last_name', capitalize(formData.lastName))

    if (formData.avatar) {
      submissionData.append('file', formData.avatar)
    }

    await mutateAsync(submissionData)
  }

  const avatarPreviewUrl = (() => {
    const avatarFile = form.watch('avatar')
    if (avatarFile) {
      return URL.createObjectURL(avatarFile)
    }
    return data?.profile?.avatar_preview_url
  })()

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8"
      >
        <Card className="border-primary/20">
          <CardContent className="px-14 py-12 flex flex-col gap-6">
            <div className="mx-auto">
              <Label
                htmlFor="avatar"
                className="text-sm font-medium text-foreground"
              >
                Profile Picture
              </Label>
              <div className="relative w-16 h-16 mx-auto mt-2">
                {avatarPreviewUrl ? (
                  <img
                    src={avatarPreviewUrl}
                    alt="Avatar Preview"
                    className="w-16 h-16 rounded-full object-cover ring-2 ring-primary/20 border-2 border-primary"
                  />
                ) : (
                  <Gravatar
                    email={user?.email}
                    className="w-16 h-16 bg-muted rounded-full ring-2 ring-primary/20 border-2 border-primary"
                  />
                )}
                <div className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4">
                  <div className="w-6 h-6 bg-primary rounded-full flex items-center justify-center border border-background shadow-sm">
                    <UserPen className="w-4 h-4 text-primary-foreground" />
                  </div>
                </div>
                <input
                  id="avatar"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label
                htmlFor="email"
                className="text-sm font-medium text-foreground"
              >
                Email
              </Label>
              <Input
                disabled
                type="email"
                id="email"
                value={user?.email}
                className={cn(
                  'w-full bg-muted/50',
                  'disabled:opacity-50 disabled:cursor-not-allowed',
                  'focus:ring-primary/20'
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className={cn('w-full', 'focus:ring-primary/20')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className={cn('w-full', 'focus:ring-primary/20')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
        </Card>

        <Button
          type="submit"
          disabled={isPending}
          className="w-[150px] mx-auto"
        >
          {isPending ? 'Saving...' : 'Save'}
        </Button>
      </form>
    </Form>
  )
}
