import { useState } from 'react'
import { OrgStorageVolumeCard } from './OrgStorageVolumeCard'
import { UserStorageVolumeCard } from './UserStorageVolumeCard'

export const StorageVolume = () => {
  const [orgStorageLimit, setOrgStorageLimit] = useState<number>(0)

  return (
    <div className="space-y-8 p-4">
      <OrgStorageVolumeCard onLimitChange={setOrgStorageLimit} />
      <UserStorageVolumeCard orgStorageLimit={orgStorageLimit} />
    </div>
  )
}
