import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { SquarePen, Info } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { useUpdateOrgLimit } from '@/services/settings'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

interface OrgStorageVolumeCardProps {
  onLimitChange?: (newLimit: number) => void
}

export const OrgStorageVolumeCard = ({
  onLimitChange,
}: OrgStorageVolumeCardProps) => {
  const [storageLimit, setStorageLimit] = useState<number>(0)
  const [storageCountLimit, setStorageCountLimit] = useState<number>(0)
  const [isEditingOrgLimit, setIsEditingOrgLimit] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [draftLimit, setDraftLimit] = useState<number>(0)
  const [draftStorageCount, setDraftStorageCount] = useState<number>(0)

  const { data: activeOrgProfile } = useActiveOrgProfile()
  const updateOrgLimitMutation = useUpdateOrgLimit()

  useEffect(() => {
    if (activeOrgProfile?.org?.metadata?.limits) {
      const limits = activeOrgProfile.org.metadata.limits
      if (limits.org_per_user_storage_gb) {
        const newLimit = limits.org_per_user_storage_gb
        setStorageLimit(newLimit)
        if (onLimitChange) {
          onLimitChange(newLimit)
        }
      }
      if (limits.org_per_user_storage_count) {
        setStorageCountLimit(limits.org_per_user_storage_count)
      }
    }
  }, [activeOrgProfile, onLimitChange])

  return (
    <Card
      className={cn(
        'p-8 rounded-lg shadow border',
        isEditingOrgLimit && 'border-2 border-primary'
      )}
    >
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold mb-4">
            Current Organization-Wide Storage Volume Limit
          </h2>
          <p className="text-muted-foreground mt-1">
            This limit will apply to all users without specific overrides
          </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-right">
            <p className="text-2xl font-bold">
              {storageLimit} GB{' '}
              <span className="text-base font-normal">per user</span>
            </p>
            <p className="text-lg mt-1">
              Max {storageCountLimit} storage volumes per user
            </p>
          </div>
          {!isEditingOrgLimit && (
            <Button
              variant="outline"
              onClick={() => {
                setDraftLimit(storageLimit)
                setDraftStorageCount(storageCountLimit)
                setIsEditingOrgLimit(true)
              }}
              className="mt-2 text-foreground dark:text-foreground border-foreground 
              dark:border-foreground hover:bg-foreground dark:hover:bg-foreground hover:text-background dark:hover:text-background"
            >
              <SquarePen className="mr-2" size={16} />
              Update Org Storage Limit
            </Button>
          )}
        </div>
      </div>

      {isEditingOrgLimit && (
        <>
          <div className="border-t border my-4"></div>
          <div className="mt-4 space-y-4">
            <div>
              <p className="text-sm text-foreground mb-2">
                Set new storage limit (in GB)
              </p>
              <div className="flex items-center space-x-4">
                <Input
                  type="text"
                  value={draftLimit}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '')
                    const normalizedValue = numericValue.replace(/^0+/, '')
                    setDraftLimit(normalizedValue ? Number(normalizedValue) : 0)
                  }}
                  className={cn(
                    'w-[5.5rem] rounded-full text-center',
                    draftLimit <= 0 && 'border-destructive'
                  )}
                />
                <span className="text-sm text-foreground">
                  <strong>GB</strong> Storage Volume
                </span>
              </div>
              {draftLimit <= 0 && (
                <p className="text-destructive text-xs mt-1">
                  Storage GB limit must be greater than zero
                </p>
              )}
            </div>

            <div>
              <p className="text-sm text-foreground mb-2">
                Set max storage volumes per user
              </p>
              <div className="flex items-center space-x-4">
                <Input
                  type="text"
                  value={draftStorageCount}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '')
                    const normalizedValue = numericValue.replace(/^0+/, '')
                    setDraftStorageCount(
                      normalizedValue ? Number(normalizedValue) : 0
                    )
                  }}
                  className={cn(
                    'w-[5.5rem] rounded-full text-center',
                    draftStorageCount <= 0 && 'border-destructive'
                  )}
                />
                <span className="text-sm text-foreground">
                  <strong>Volumes</strong> per user
                </span>
                <Tooltip>
                  <TooltipTrigger>
                    <Info className="text-primary cursor-pointer" size={20} />
                  </TooltipTrigger>
                  <TooltipContent className="bg-primary/10 text-foreground p-3 shadow-md rounded-md max-w-xs">
                    <p className="text-sm">
                      Updating the organization-wide limit will override all
                      existing custom user limits. Proceeding with this change
                      will apply the new limit to all users.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
              {draftStorageCount <= 0 && (
                <p className="text-destructive text-xs mt-1">
                  Storage count limit must be greater than zero
                </p>
              )}
            </div>

            <div className="flex space-x-4">
              <Button
                variant="outline"
                onClick={() => setIsEditingOrgLimit(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="w-[150px] mx-auto"
                onClick={() => setIsConfirming(true)}
                disabled={draftLimit <= 0 || draftStorageCount <= 0}
              >
                Apply to All Users
              </Button>
            </div>
          </div>
        </>
      )}

      <ConfirmationDialog
        open={isConfirming}
        onOpenChange={setIsConfirming}
        title="Update Organization-Wide Storage Volume Limit"
        description={
          <>
            You are about to update the organization-wide volume limits to{' '}
            <b className="text-foreground/90">{draftLimit} GB storage</b> and{' '}
            <b className="text-foreground/90">
              {draftStorageCount} storage volumes
            </b>{' '}
            per user.
            <p className="mt-2">
              This change will affect all future resources, while existing
              resources will continue running with the limits that were in
              effect when they were created.
            </p>
          </>
        }
        buttons={{
          confirm: {
            label: 'Apply to All Users',
            isDestructive: true,
            isLoading: updateOrgLimitMutation.isPending,
          },
        }}
        confirmation={{
          text: `${draftLimit},${draftStorageCount}`,
          prompt:
            "Please type the storage limit and volume count in format 'GB,Count' to confirm:",
        }}
        info={{
          warningPoints: [
            'All customized user limits will be overwritten for future resources.',
            'Existing resources will continue running based on the limits from when they were created.',
          ],
        }}
        onConfirm={() => {
          updateOrgLimitMutation.mutate(
            {
              org_per_user_storage_gb: draftLimit,
              org_per_user_storage_count: draftStorageCount,
            },
            {
              onSuccess: () => {
                setStorageLimit(draftLimit)
                setStorageCountLimit(draftStorageCount)
                setIsConfirming(false)
                setIsEditingOrgLimit(false)
              },
              onError: (err) => {
                console.error(err)
              },
            }
          )
        }}
        onCancel={() => {
          setIsConfirming(false)
        }}
      />
    </Card>
  )
}
