import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  APIFetch,
  MutProps,
  showErrorToastAndThrow,
  showSuccessToast,
  useTokenAndOrgId,
} from '../utils'

export const useUpdateUserProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (formData: FormData) => {
      formData.append('org_id', String(orgId))

      const result = await APIFetch.post(`updateOrgUserProfileV2`, {
        token,
        orgId,
        body: formData,
      })

      if (result.error || result.status !== 'success') {
        showErrorToastAndThrow('Failed to update profile')
        return
      }

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['get-org-user-profile'],
        exact: false,
      })

      showSuccessToast('Profile updated successfully')

      return result.status
    },
    ...props,
  })
}

export const useUpdateOrgProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (formData: FormData) => {
      formData.append('org_id', String(orgId))

      const result = await APIFetch.post(`updateOrgProfile`, {
        token,
        orgId,
        body: formData,
      })

      if (result.error || result.status !== 'success') {
        showErrorToastAndThrow('Failed to update organization profile')
        return
      }

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['get-org-user-profile'],
        exact: false,
      })

      showSuccessToast('Organization profile updated successfully')

      return result.status
    },
    ...props,
  })
}
