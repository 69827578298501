import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { useElapsedTime } from '@/helpers/time'
import { DeleteServerDialog } from './DeleteServerDialog'
import { useRunningCostDisplay } from '@/hooks/useRunningCostDisplay'
import { cn } from '@/lib/utils'
import { Server, ExternalLink } from 'lucide-react'
import { Separator } from '@/components/ui/separator'

type ServerMetadata = {
  name: string
  status: string
  baseImage: string
  createdBy: string
  createdAt: string
  startedAt: string
}

type ServerPricing = {
  price: number
}

type UserMetadata = {
  first_name?: string
  last_name?: string
}

type ServerCardProps = {
  metadata: ServerMetadata
  pricing: ServerPricing
  instanceId: number
  user_metadata?: UserMetadata
  onViewDetails?: () => void
}

export const ServerCard = ({
  metadata: { name, status, baseImage, createdBy, startedAt },
  pricing: { price },
  instanceId,
  user_metadata,
  onViewDetails,
}: ServerCardProps) => {
  const uptime = useElapsedTime(startedAt)
  const runningCostDollars = useRunningCostDisplay({
    started_at: startedAt,
    hourly_price_cents: price,
  })

  const priceInDollars = price / 100
  const isRunning = status.toLowerCase() === 'running'

  return (
    <div
      className={cn(
        'p-6 rounded-lg shadow-sm border transition-all duration-200 hover:shadow-md group',
        'bg-card border-border',
        isRunning && 'border-primary border-2'
      )}
    >
      <div className="flex justify-between items-start">
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <Server className="w-5 h-5 text-primary/70" />
            <h3 className="text-2xl font-bold">{name}</h3>
          </div>

          <Badge
            variant="outline"
            className={cn(
              status === 'running'
                ? 'bg-primary/10 text-primary'
                : status === 'stopped'
                  ? 'bg-destructive/10 text-destructive'
                  : 'bg-foreground/10 text-foreground/80'
            )}
          >
            {status} • {uptime}
          </Badge>
        </div>

        <div className="text-right space-y-1">
          <div className="flex items-center justify-end gap-1">
            <p className="text-lg font-semibold">${runningCostDollars}</p>
          </div>
          <p className="text-foreground/60">${priceInDollars.toFixed(2)}/hr</p>
        </div>
      </div>

      <Separator className="my-4" />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="col-span-2 space-y-2 text-sm">
          <div className="flex gap-2">
            <span className="text-foreground/60 w-24">Template:</span>
            <span className="font-medium">{baseImage}</span>
          </div>

          <div className="flex gap-2">
            <span className="text-foreground/60 w-24">Created By:</span>
            <span className="font-medium">
              {createdBy}
              {user_metadata?.first_name && user_metadata?.last_name
                ? ` (${user_metadata.first_name} ${user_metadata.last_name})`
                : ''}
            </span>
          </div>
        </div>

        <div className="flex flex-col justify-end gap-2">
          <div className="flex items-center gap-2 justify-end mt-auto">
            <Button
              variant="default"
              className="flex items-center gap-1.5"
              onClick={onViewDetails}
            >
              <ExternalLink className="w-3.5 h-3.5" />
              Show Details
            </Button>
            <DeleteServerDialog instanceId={instanceId} serverName={name} />
          </div>
        </div>
      </div>
    </div>
  )
}
