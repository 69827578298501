import { useState } from 'react'
import { OrgCreditLimitCard } from './OrgCreditLimitCard'
import { UserCreditLimitCard } from './UserCreditLimitCard'

export const CreditLimit = () => {
  const [orgCreditLimit, setOrgCreditLimit] = useState<number>(0)

  return (
    <div className="space-y-8 p-4">
      <OrgCreditLimitCard onLimitChange={setOrgCreditLimit} />
      <UserCreditLimitCard orgCreditLimit={orgCreditLimit} />
    </div>
  )
}
