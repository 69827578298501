import { useActiveOrgProfile } from './useActiveOrgProfile'
import { useGetOrgUserLimit } from '@/services/settings'

export const useCurrentUserOrgLimit = () => {
  const { data: profileData } = useActiveOrgProfile()
  const userId = profileData?.userId
  const { data: limitData } = useGetOrgUserLimit(userId ?? 0)

  return limitData
}
