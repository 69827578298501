import {
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import { Avatar } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { CircleDollarSign } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
  type NotificationItemProps,
  type SpendingMetadata,
  type SpendingData,
} from './types'
import { formatTransactionDate } from './utils'
import { MarkAsReadButton } from '.'
import { DefaultNotification } from './DefaultNotification'

export const SpendingLimitNotification = ({
  notification,
  isLoading,
  onMarkAsRead,
}: NotificationItemProps) => {
  if (!notification.metadata) {
    return (
      <DefaultNotification
        notification={notification}
        isLoading={isLoading}
        onMarkAsRead={onMarkAsRead}
      />
    )
  }

  const spendingMetadata = notification.metadata as SpendingMetadata
  const spendingData: SpendingData = {
    spent: (spendingMetadata.spent || 0) / 100,
    total: (spendingMetadata.total || 0) / 100,
    remaining: (spendingMetadata.remaining || 0) / 100,
    percentSpent:
      ((spendingMetadata.spent || 0) / (spendingMetadata.total || 1)) * 100,
    isHighUsage:
      ((spendingMetadata.spent || 0) / (spendingMetadata.total || 1)) * 100 >
      75,
    isOverSpent: (spendingMetadata.remaining || 0) <= 0,
  }

  return (
    <>
      <CardHeader className="pt-5 pb-2 px-6">
        <div className="flex items-start">
          <div className="flex items-center gap-3">
            <Avatar className="flex items-center justify-center bg-destructive/10 h-8 w-8">
              <CircleDollarSign className="h-4 w-4 text-destructive" />
            </Avatar>
            <div>
              <CardTitle className="text-lg font-bold">
                Spending Limit Alert
              </CardTitle>
              <p className="text-foreground/60 text-sm mt-1">
                Account spending{' '}
                {spendingData.isOverSpent
                  ? 'limit exceeded'
                  : spendingData.isHighUsage
                    ? 'high usage warning'
                    : 'update'}
              </p>
            </div>
          </div>
        </div>
      </CardHeader>

      <CardContent className="px-6 py-3">
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 rounded-md bg-background/10 border border-background/20">
            <div className="space-y-1">
              <p className="text-sm font-medium">Usage Level</p>
              <div className="text-xs text-foreground/60">
                {spendingData.percentSpent.toFixed(1)}% of your budget used
              </div>
            </div>
            <Badge
              variant={
                spendingData.isOverSpent
                  ? 'destructive'
                  : spendingData.percentSpent > 90
                    ? 'destructive'
                    : spendingData.percentSpent > 75
                      ? 'secondary'
                      : 'outline'
              }
              className="text-xs font-medium"
            >
              {spendingData.isOverSpent
                ? 'Overspent'
                : spendingData.percentSpent > 90
                  ? 'Critical'
                  : spendingData.percentSpent > 75
                    ? 'High'
                    : 'Normal'}
            </Badge>
          </div>

          <div className="relative mt-2 mb-4">
            <div className="h-2.5 w-full bg-background/30 dark:bg-background/20 rounded-full overflow-hidden">
              <div
                className={cn(
                  'h-full rounded-full transition-all duration-500',
                  spendingData.isOverSpent
                    ? 'bg-destructive'
                    : spendingData.percentSpent > 90
                      ? 'bg-destructive'
                      : spendingData.percentSpent > 75
                        ? 'bg-primary'
                        : 'bg-accent'
                )}
                style={{
                  width: `${Math.min(spendingData.percentSpent, 100)}%`,
                }}
              />
            </div>

            {/* Progress markers */}
            <div className="absolute top-3 left-0 right-0 flex justify-between px-0.5">
              <div className="h-3 w-px bg-foreground/20"></div>
              <div className="h-3 w-px bg-foreground/20"></div>
              <div className="h-3 w-px bg-foreground/20"></div>
              <div className="h-3 w-px bg-foreground/20"></div>
              <div className="h-3 w-px bg-foreground/20"></div>
            </div>

            {/* Label markers */}
            <div className="absolute top-7 left-0 right-0 flex justify-between text-[10px] text-foreground/40 px-0.5 mb-6">
              <div>0%</div>
              <div>25%</div>
              <div>50%</div>
              <div>75%</div>
              <div>100%</div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mt-12 mb-3 pt-8">
            <div>
              <p className="text-sm text-foreground/60 mb-1">Spent</p>
              <p className="text-destructive text-lg font-bold">
                $
                {spendingData.spent.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
            <div>
              <p className="text-sm text-foreground/60 mb-1">Remaining</p>
              <p
                className={cn(
                  'text-lg font-bold',
                  spendingData.remaining < 0 ? 'text-destructive' : ''
                )}
              >
                ${spendingData.remaining < 0 ? '-' : ''}
                {Math.abs(spendingData.remaining).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
            <div>
              <p className="text-sm text-foreground/60 mb-1">Total</p>
              <p className="text-lg font-bold">
                $
                {spendingData.total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <p className="text-xs text-foreground/60 mt-2">
            Updated on {formatTransactionDate(notification.created_at)}
          </p>
        </div>
      </CardContent>

      {!notification.is_read && (
        <CardFooter className="px-6 py-3 flex justify-end border-t">
          <MarkAsReadButton
            isLoading={isLoading}
            onMarkAsRead={() => onMarkAsRead(notification.notification_id)}
          />
        </CardFooter>
      )}
    </>
  )
}
