import { Badge } from '@/components/ui/badge'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Microchip, Fan, Disc3, Check } from 'lucide-react'
import { cn } from '@/lib/utils'

type ServerSpecs = {
  cpu: {
    count: number
    ramGb: number
  }
  gpu: {
    count: number
    memoryGb: number
  }
  machineImage: string
}

type ServerTemplateCardProps = {
  id: string
  name: string
  specs: ServerSpecs
  price: number
  selected?: boolean
  onSelect: () => void
}

export const ServerTemplateCard = ({
  id,
  name,
  specs,
  price,
  selected = false,
  onSelect,
}: ServerTemplateCardProps) => {
  return (
    <Card
      key={id}
      className={cn(
        'group relative cursor-pointer',
        'border-2 rounded-lg',
        'transition-all duration-200',
        'hover:shadow-lg hover:scale-[1.01]',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
        selected
          ? 'border-primary bg-primary/5 shadow-primary/10'
          : 'border-border hover:border-primary/50'
      )}
      onClick={onSelect}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && onSelect()}
    >
      {selected && (
        <div className="absolute -right-2.5 -top-2.5 rounded-full bg-primary p-1.5 shadow-lg ring-2 ring-background">
          <Check className="h-3.5 w-3.5 text-primary-foreground" />
        </div>
      )}

      <CardHeader className="pb-3">
        <CardTitle className="flex items-center justify-between">
          <span className="text-xl font-semibold text-foreground">{name}</span>
          <div className="flex flex-col items-end">
            <Badge variant="secondary" className="text-lg font-bold px-3">
              ${price}
            </Badge>
            <span className="text-xs text-muted-foreground mt-1">per hour</span>
          </div>
        </CardTitle>
      </CardHeader>

      <CardContent>
        <div className="space-y-6">
          <div
            className={cn(
              'grid gap-4',
              // For single row of specs, use full width on mobile, 3 columns on larger screens
              'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'
            )}
          >
            <SpecCard
              icon={<Fan className="h-5 w-5" />}
              title="GPU"
              items={[
                { label: 'Count', value: `${specs.gpu.count}x` },
                { label: 'VRAM', value: `${specs.gpu.memoryGb} GB` },
              ]}
              variant="gpu"
            />

            <SpecCard
              icon={<Microchip className="h-5 w-5" />}
              title="CPU"
              items={[{ label: 'Count', value: `${specs.cpu.count}x` }]}
            />

            <SpecCard
              icon={<Microchip className="h-5 w-5 opacity-50" />}
              title="Memory"
              items={[{ label: 'RAM', value: `${specs.cpu.ramGb} GB` }]}
            />
          </div>

          <div className="pt-3 border-t border-border/40">
            <div className="flex items-center gap-2">
              <div className="bg-primary/10 rounded-md p-1.5">
                <Disc3 className="h-5 w-5 text-primary" />
              </div>
              <span className="text-sm font-medium">Machine Image:</span>
              <span className="text-sm text-muted-foreground">
                {specs.machineImage}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

type SpecCardProps = {
  icon: React.ReactNode
  title: string
  items: Array<{
    label: string
    value: string
  }>
  variant?: 'default' | 'gpu'
}

const SpecCard = ({
  icon,
  title,
  items,
  variant = 'default',
}: SpecCardProps) => (
  <div
    className={cn(
      'space-y-2 rounded-lg p-2.5 min-h-[100px] border transition-all',
      variant === 'default' && ['bg-foreground/5', 'border-border/40'],
      variant === 'gpu' && ['bg-primary/5', 'border-primary/20']
    )}
  >
    <div
      className={cn(
        'flex items-center gap-2 border-b pb-2',
        variant === 'default' ? 'border-border/40' : 'border-primary/10'
      )}
    >
      <div
        className={cn(
          'rounded-md p-1',
          variant === 'default' ? 'bg-muted/90' : 'bg-primary/20'
        )}
      >
        {icon}
      </div>
      <span
        className={cn(
          'text-sm font-semibold',
          variant === 'gpu' && 'text-primary'
        )}
      >
        {title}
      </span>
    </div>
    <div className="space-y-1.5">
      {items.map(({ label, value }) => (
        <div key={label} className="flex items-center justify-between gap-3">
          <span className="text-xs text-muted-foreground">{label}</span>
          <span
            className={cn(
              'text-sm font-medium px-2 py-0.5 rounded-md border',
              variant === 'default'
                ? 'bg-background border-border/50'
                : 'bg-primary/5 border-primary/20'
            )}
          >
            {value}
          </span>
        </div>
      ))}
    </div>
  </div>
)
