import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Card } from '@/components/ui/card'
import { Trash2 } from 'lucide-react'
import { DeleteApiKeyDialog } from './DeleteApiKeyDialog'
import { ApiKey, useGetApiKeys, useDeleteApiKey } from '@/services/apiKey'
import { useTokenAndOrgId } from '@/services/utils'
import { Spinner } from '@/components/spinner'

export const ApiKeyCard = () => {
  const { orgId } = useTokenAndOrgId()
  const { data, isLoading, error, isError } = useGetApiKeys(orgId)
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null)

  const { mutate: deleteApiKey, isPending: isDeletePending } = useDeleteApiKey()

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <p className="text-sm text-destructive">{error?.message}</p>
  }

  const keys = data?.keys ?? []

  if (keys.length === 0) {
    return (
      <div className="mx-auto flex flex-col items-center gap-16 mt-28">
        <h3 className="text-4xl font-medium">You have no API keys.</h3>
        <img
          src="/assets/image/empty-storage.png"
          alt="No API keys"
          style={{ width: '300px', height: 'auto' }}
        />
      </div>
    )
  }

  return (
    <div className="space-y-4">
      {keys.map((apiKey) => (
        <Card
          key={apiKey.id}
          className="p-4 flex flex-col gap-3 border shadow-sm"
        >
          <h2 className="text-lg font-semibold">{apiKey.name}</h2>

          <p className="text-sm font-mono">
            {apiKey.key_prefix}-***************
          </p>

          <div className="text-xs text-muted-foreground flex gap-6">
            <span>Date created: {apiKey.formatted_created_at}</span>
            <span>Last used: {apiKey.formatted_last_used}</span>
          </div>

          <div className="flex justify-between items-center">
            <Badge
              size="lg"
              variant={apiKey.is_active ? 'primary' : 'secondary'}
              className="flex items-center gap-1"
            >
              <span className="text-current">•</span>
              {apiKey.is_active ? 'Active' : 'Inactive'}
            </Badge>

            <Button
              variant="outline-destructive"
              className="flex items-center gap-2"
              onClick={() => setSelectedApiKey(apiKey)}
              disabled={isDeletePending}
            >
              {isDeletePending ? (
                <Spinner className="w-4 h-4 mr-2" />
              ) : (
                <Trash2 className="w-4 h-4" />
              )}
              Delete
            </Button>
          </div>
        </Card>
      ))}

      {selectedApiKey && (
        <DeleteApiKeyDialog
          apiKeyName={selectedApiKey.name}
          apiKeyValue={`${selectedApiKey.key_prefix}-***************`}
          open={!!selectedApiKey}
          onClose={() => setSelectedApiKey(null)}
          onConfirmDelete={() => {
            deleteApiKey({ id: selectedApiKey.id, orgId })
            setSelectedApiKey(null)
          }}
        />
      )}
    </div>
  )
}
