import {
  StorageVolumeResponse,
  StorageVolumeStatus,
  StorageVolume,
} from '@/types'
import {
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { APIFetch, MutProps, useTokenAndOrgId } from '../utils'
import {
  getStorageViewSort,
  StorageViewSortKeys,
} from '@/pages/DashboardStorage/types'
import { processPaginatedData } from '../utils'

export const useListStorageVolumes = ({
  status,
  showAllUsers = false,
  searchTerm = '',
  sort = undefined,
}: {
  status?: StorageVolumeStatus
  showAllUsers?: boolean
  searchTerm?: string
  sort?: StorageViewSortKeys
}) => {
  const { orgId, token } = useTokenAndOrgId()

  const sortData = sort
    ? getStorageViewSort(sort)
    : getStorageViewSort(StorageViewSortKeys.CREATED_AT_DESC)

  const fetchStorageVolumes = async ({ pageParam = 1 }) => {
    const body = {
      org_id: orgId,
      page_number: pageParam,
      page_size: 5,
      status,
      show_all_users: showAllUsers,
      search_text: searchTerm,
      sort_by: sortData.sortBy,
      sort_direction: sortData.sortDirection,
    }

    try {
      const result = await APIFetch.post('listStorageVolumes', {
        token,
        body,
        orgId,
      })

      if (!result || !result.data) {
        throw new Error('Invalid response from server')
      }

      const volumes = result.data.volumes || []
      const total = result.data.total || 0

      return {
        instances: volumes.map(
          (volume: StorageVolumeResponse): StorageVolume => ({
            id: volume.VolumeID,
            name: volume.VolumeName,
            size_gb: volume.SizeGB,
            status: volume.Status.toLowerCase() as StorageVolumeStatus,
            created_at: volume.CreatedAt,
            updated_at: volume.UpdatedAt,
            user_email: volume.User.email,
            user_profile: volume.UserOrganization?.profile_metadata
              ? {
                  first_name:
                    volume.UserOrganization.profile_metadata.first_name,
                  last_name: volume.UserOrganization.profile_metadata.last_name,
                }
              : undefined,
            server_instances: volume.server_instances,
          })
        ),
        total,
      }
    } catch (error) {
      console.error('Error fetching storage volumes:', error)
      return {
        instances: [],
        total: 0,
      }
    }
  }

  return useInfiniteQuery({
    queryKey: [
      'list-storage-volumes',
      orgId,
      token,
      status,
      showAllUsers,
      sort,
      searchTerm,
    ],
    queryFn: fetchStorageVolumes,
    enabled: !!orgId && !!token,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.instances.length === 5 ? pages.length + 1 : undefined
    },
    // Adapt `allInstances` to `allVolumes`
    select: (data) => {
      const processedData = processPaginatedData(data.pages)
      return {
        allVolumes: processedData.allInstances,
        total: processedData.total,
      }
    },
    initialPageParam: 1,
  })
}

export const useCreateStorageVolume = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { name: string; sizeGb: number }) => {
      const result = await APIFetch.post('createStorageVolume', {
        token,
        body: { name: data.name, size_gb: data.sizeGb, org_id: orgId },
        orgId,
      })

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['list-storage-volumes', orgId, token],
        exact: false,
      })

      return result.status
    },
    ...props,
  })
}

export const useDeleteStorageVolume = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (volumeId: string) => {
      const result = await APIFetch.post('deleteStorageVolume', {
        token,
        body: { volume_id: volumeId, org_id: orgId },
        orgId,
      })

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['list-storage-volumes', orgId, token],
        exact: false,
      })

      return result.status
    },
    ...props,
  })
}
