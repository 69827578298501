import { useListServerTemplates } from '@/services/template'
import { ServerTemplateCard } from './ServerTemplateCard'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Spinner } from '@/components/spinner'

const DashboardTemplates = () => {
  const { data: templates, isLoading, error } = useListServerTemplates()

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return (
      <div className="text-destructive">
        Error loading templates: {error.message}
      </div>
    )
  }

  return (
    <div className="mx-auto space-y-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-medium">Server Templates</h2>
        <Button disabled>
          <Plus className="w-4 h-4 mr-2" />
          New Template
        </Button>
      </div>

      {templates && templates.length > 0 ? (
        <div className="space-y-4">
          {templates.map((template) => (
            <ServerTemplateCard
              key={template.template_id}
              id={String(template.template_id)}
              name={template.template_name}
              specs={{
                cpu: {
                  count: Number(template.cpu_count),
                  ramGb: Number(template.ram_gb)
                },
                gpu: {
                  count: Number(template.gpu_request_count),
                  memoryGb: Number(template.gpu_request_gmem)
                },
                machineImage: template.machine_image
              }}
              price={parseFloat((template.price / 100).toFixed(2))}
              selected={false}
              onSelect={() => {}}
            />
          ))}
        </div>
      ) : (
        <div className="text-center p-10 bg-foreground/10 rounded-lg">
          <p className="text-lg">There are no templates available.</p>
        </div>
      )}
    </div>
  )
}

export default DashboardTemplates
