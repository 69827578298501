import { TopStats } from './TopStats'
import { UsageChart } from './UsageChart'

type OverviewTabProps = {
  userId?: number
}

export const OverviewTab = ({ userId }: OverviewTabProps) => {
  return (
    <>
      <TopStats userId={userId} />
      <UsageChart userId={userId} />
    </>
  )
}
