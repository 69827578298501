import { DashboardAnalyticsTab } from './AnalyticsTab'
import { DashboardHomeHeader } from './Header'
import { Tabs, TabsContent } from '@/components/ui/tabs'
import { useGetOrgBalanceSummary } from '@/services/home'
import { Spinner } from '@/components/spinner'
import { NotificationsTab } from './Notification'
import { OverviewTab } from '../shared/OverviewTab'

export const DashboardHomeAdmin = () => {
  const { isLoading } = useGetOrgBalanceSummary()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Tabs defaultValue="overview" className="w-full sm:w-auto">
      <DashboardHomeHeader />

      <TabsContent value="overview">
        <OverviewTab />
      </TabsContent>
      <TabsContent value="analytics">
        <DashboardAnalyticsTab />
      </TabsContent>
      <TabsContent value="notifications">
        <NotificationsTab />
      </TabsContent>
    </Tabs>
  )
}
