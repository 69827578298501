import { Button } from '@/components/ui/button'
import dayjs from 'dayjs'
import { capitalize } from '@/helpers/string'
import { useElapsedTime } from '@/helpers/time'
import { StorageVolume } from '@/types'
import { ServerMountDetails } from './ServerMountDetails'
import { StorageDeleteDialog } from './DeleteStorageDialog'

type StorageCardProps = StorageVolume & {
  user_profile?: {
    first_name: string
    last_name: string
  }
}

export const StorageCard = ({
  id,
  name,
  size_gb,
  created_at,
  user_email,
  status,
  server_instances,
  user_profile,
}: StorageCardProps) => {
  const uptime = useElapsedTime(created_at)

  const handleDeleteSuccess = () => {
    console.log(`Volume ${name} deleted successfully`)
  }

  return (
    <div
      className={`px-6 py-4 rounded-sm shadow-md border-2 ${
        status === 'mounted' ? 'border-primary' : 'border-border'
      }`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <h3 className="text-xl font-semibold">{name}</h3>
          {/*EditStorageName component for future use (currently commented out in the StorageCard component)*/}
          {/* <EditStorageName /> */}
        </div>

        <span className="text-text font-semibold">{size_gb} GB</span>
      </div>

      <p className="mt-2 text-foreground/80 text-sm font-semibold">
        Total Volume Size: {size_gb}GB
      </p>
      <p className="mt-2 text-foreground/80 text-sm font-medium">
        Volume ID: {id}
      </p>

      <p className="mt-2 text-foreground text-sm">
        Created by{' '}
        <span className="font-bold">
          {user_email}
          {user_profile?.first_name && user_profile?.last_name
            ? ` (${user_profile.first_name} ${user_profile.last_name})`
            : ''}
        </span>{' '}
        on {dayjs(created_at).format('MMMM DD, YYYY')}
      </p>

      <div className="flex w-full items-center justify-between gap-4 mt-3">
        <ServerMountDetails
          serversMounted={server_instances}
          TriggerButton={
            <Button size="default" variant="outline">
              {capitalize(status)} • {uptime}
            </Button>
          }
        />

        {/* StorageDeleteCard */}
        <StorageDeleteDialog
          id={id}
          name={name}
          status={status}
          onDeleteSuccess={handleDeleteSuccess}
        />
      </div>
    </div>
  )
}
