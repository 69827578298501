import {
  APIFetch,
  MutProps,
  showErrorToastAndThrow,
  showSuccessToast,
  useTokenAndOrgId,
} from '../utils'
import {
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { getUserSort } from '@/pages/DashboardUserManagement/types'
import { UsersSortKeys } from '@/pages/DashboardUserManagement/types'
export * from './types'
import { OrgUserResponse, OrgUser } from './types'
import { processPaginatedData } from '../utils'

export const LIST_USERS_PAGE_SIZE = 50

export const getOrgUsers = async (
  token: string,
  orgId: number,
  pageSize: number = LIST_USERS_PAGE_SIZE,
  pageNumber: number = 1,
  status: string, // Add status parameter here
  searchText: string = '',
  sort: UsersSortKeys = UsersSortKeys.CREATED_AT_DESC
): Promise<{ users: OrgUser[]; total: number }> => {
  const result: OrgUserResponse = await APIFetch.post('getOrgUsers', {
    token,
    body: {
      org_id: Number(orgId),
      page_size: pageSize,
      page_number: pageNumber,
      status, // Include status in the request payload
      search_text: searchText,
      sort_by: getUserSort(sort).sortBy,
      sort_direction: getUserSort(sort).sortDirection,
    },
    orgId,
  })

  if (result.error) {
    console.error(`Error from API: ${result.error}`)
    throw new Error(`Error from API: ${result.error}`)
  }

  const usersData = result.data?.users || []
  const total = result.data?.total || 0

  const users = usersData.map((user) => ({
    user_id: user.user_id,
    email: user.user.email,
    role_name: user.role.role_name,
    joinedDate: user.joined_at,
    expireDate: user.invitation?.expires_at || null,
    status: user.status,
    is_super: user.user.is_super,
    profile_metadata: {
      first_name: user.profile_metadata?.first_name || '',
      last_name: user.profile_metadata?.last_name || '',
      avatar_preview_url: user.profile_metadata?.avatar_preview_url || '',
    },
  }))

  return { users, total }
}

export const useGetOrgUsers = (
  status: string,
  searchText = '',
  sort: UsersSortKeys = UsersSortKeys.CREATED_AT_DESC
) => {
  const { orgId, token } = useTokenAndOrgId()

  const fetchOrgUsers = async ({ pageParam = 1 }) => {
    const result = await getOrgUsers(
      token,
      orgId,
      LIST_USERS_PAGE_SIZE,
      pageParam,
      status,
      searchText,
      sort
    )

    return {
      users: result.users,
      total: result.total,
    }
  }

  return useInfiniteQuery({
    queryKey: ['org-users', orgId, status, searchText, sort],
    queryFn: fetchOrgUsers,
    enabled: !!orgId && !!token,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.users.length === LIST_USERS_PAGE_SIZE
        ? pages.length + 1
        : undefined
    },
    select: (data) => {
      const processedData = processPaginatedData<OrgUser>(data.pages)
      return {
        allUsers: processedData.allInstances,
        total: processedData.total,
      }
    },
    initialPageParam: 1,
  })
}

export const useCreateOrgInvite = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { email: string; role: string }) => {
      const result = await APIFetch.post('createInvite', {
        token,
        body: {
          org_id: orgId,
          role: data.role,
          email: data.email,
        },
        orgId,
      })

      if (result.error) {
        showErrorToastAndThrow(`Failed to send invites: ${result.error}`)
        return
      }

      queryClient.invalidateQueries({
        queryKey: ['org-users', orgId],
        exact: false,
      })

      showSuccessToast('Invites sent successfully')

      return result
    },
    ...props,
  })
}

export const useResendOrgInvite = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { email: string; role: string }) => {
      try {
        const result = await APIFetch.post('createInvite', {
          token,
          body: { org_id: orgId, email: data.email, role: data.role },
          orgId,
        })

        if (result.error) {
          showErrorToastAndThrow(`Failed to resend invite: ${result.error}`)
          return
        }

        queryClient.invalidateQueries({
          queryKey: ['org-users', orgId],
          exact: false,
        })

        showSuccessToast('Invitation resent successfully')

        return result
      } catch (err) {
        showErrorToastAndThrow(err, 'Failed to resend invite')
      }
    },
    ...props,
  })
}

export const useCreateOrgInviteBatch = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    // TODO: property add TS type to 'role' field
    mutationFn: async (data: { email: string; role: string }[]) => {
      const result = await APIFetch.post('createInviteBatch', {
        token,
        body: { org_id: orgId, invites: data },
        orgId,
      })

      if (result.error) {
        showErrorToastAndThrow(`Failed to create invites: ${result.error}`)
        return
      }

      queryClient.invalidateQueries({
        queryKey: ['org-users', orgId],
        exact: false,
      })

      showSuccessToast('Invites sent successfully')

      return result
    },
    ...props,
  })
}

export const useRemoveOrgUser = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (userId: number) => {
      const result = await APIFetch.post('deleteOrgUser', {
        token,
        body: { org_id: orgId, user_id: userId },
        orgId,
      })

      if (result.error) {
        showErrorToastAndThrow(`Failed to remove user: ${result.error}`)
        return
      }

      queryClient.invalidateQueries({
        queryKey: ['org-users'],
        exact: false,
      })

      showSuccessToast('User removed successfully')

      return result
    },
    ...props,
  })
}

export const useUpdateOrgUserRole = (props?: MutProps) => {
  const { orgId, token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { userId: number; roleId: number }) => {
      const result = await APIFetch.post('updateOrgUser', {
        token,
        body: { org_id: orgId, role_id: data.roleId, user_id: data.userId },
        orgId,
      })

      if (result.error) {
        showErrorToastAndThrow(`Failed to update user role: ${result.error}`)
        return
      }

      queryClient.invalidateQueries({
        queryKey: ['org-users', orgId],
        exact: false,
      })

      showSuccessToast('User role updated successfully')

      return result
    },
    ...props,
  })
}
