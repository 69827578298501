import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  APIFetch,
  MutProps,
  useTokenAndOrgId,
  showErrorToastAndThrow,
  showSuccessToast,
} from '../utils'
import { STATUS_SUCCESS } from '@/constants/status'
import {
  SpendingLimit,
  OrgUserLimit,
  UpdateOrgLimitPayload,
  UpdateOrgUserLimitPayload,
} from './types'

// ======== useGetOrgSpendingLimit ========
export const useGetOrgSpendingLimit = () => {
  const { token, orgId } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['org-spending-limit', token, orgId],
    enabled: !!token && !!orgId,
    queryFn: async () => {
      const result = await APIFetch.post('getOrgSpendingLimit', {
        token,
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to fetch org spending limit. ERROR: ${result.error}`
        )
      }

      return result.data as SpendingLimit
    },
  })
}

// ======== useCreateOrgSpendingLimit ========
export const useCreateOrgSpendingLimit = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: { cent: number }) => {
      const result = await APIFetch.post('createOrgSpendingLimit', {
        token,
        body: payload,
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to create org spending limit. ERROR: ${result.error}`
        )
      }

      queryClient.invalidateQueries({
        queryKey: ['org-spending-limit', token, orgId],
      })
      showSuccessToast('Successfully created organization spending limit')
      return result.status
    },
    ...props,
  })
}

// ======== useCreateOrgUserSpendingLimit ========
export const useCreateOrgUserSpendingLimit = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: { cent: number; user_id: number }) => {
      const result = await APIFetch.post('createOrgUserSpendingLimit', {
        token,
        body: payload,
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to create user spending limit. ERROR: ${result.error}`
        )
      }

      queryClient.invalidateQueries({
        queryKey: ['org-user-spending-limit', token, orgId, payload.user_id],
      })
      showSuccessToast('Successfully created user spending limit')
      return result.status
    },
    ...props,
  })
}

// ======== useGetOrgUserSpendingLimit ========
export const useGetOrgUserSpendingLimit = (userId: number) => {
  const { token, orgId } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['org-user-spending-limit', token, orgId, userId],
    enabled: !!token && !!orgId && !!userId,
    queryFn: async () => {
      const result = await APIFetch.post('getOrgUserSpendingLimit', {
        token,
        body: { user_id: userId },
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to fetch user spending limit. ERROR: ${result.error}`
        )
      }

      return { cent: result.data.max_spend_cent }
    },
  })
}

// ======== useGetOrgUserLimit ========
export const useGetOrgUserLimit = (userId: number) => {
  const { token, orgId } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['org-user-limit', token, orgId, userId],
    enabled: !!token && !!orgId && !!userId,
    queryFn: async () => {
      const result = await APIFetch.post('getOrgUserLimit', {
        token,
        body: { user_id: userId },
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to fetch user limits. ERROR: ${result.error}`
        )
      }

      return result.data as OrgUserLimit
    },
  })
}

// ======== useUpdateOrgLimit ========
export const useUpdateOrgLimit = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: UpdateOrgLimitPayload) => {
      const result = await APIFetch.post('updateOrgLimit', {
        token,
        body: payload,
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to update organization limits. ERROR: ${result.error}`
        )
      }

      queryClient.invalidateQueries({ queryKey: ['org-limit', token, orgId] })
      queryClient.invalidateQueries({
        queryKey: ['get-org-user-profile'],
        exact: false,
      })
      showSuccessToast('Successfully updated organization limits')
      return result.status
    },
    ...props,
  })
}

// ======== useUpdateOrgUserLimit ========
export const useUpdateOrgUserLimit = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: UpdateOrgUserLimitPayload) => {
      const result = await APIFetch.post('updateOrgUserLimit', {
        token,
        body: payload,
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to update user limits. ERROR: ${result.error}`
        )
      }

      queryClient.invalidateQueries({
        queryKey: ['org-user-limit', token, orgId, payload.user_id],
      })
      showSuccessToast('Successfully updated user limits')
      return result.status
    },
    ...props,
  })
}
