import { Server } from 'lucide-react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Button } from '@/components/ui/button'
import { StorageVolume } from '@/types'

interface ServerMountDetailsProps {
  TriggerButton: React.ReactNode
  serversMounted: StorageVolume['server_instances']
}

export const ServerMountDetails = ({
  TriggerButton,
  serversMounted,
}: ServerMountDetailsProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{TriggerButton}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Storage Status
          </DialogTitle>
          <p className="text-muted-foreground">
            This storage volume is currently mounted on the following servers:
          </p>
        </DialogHeader>

        <ScrollArea className="h-[300px] pr-4">
          {serversMounted && serversMounted.length > 0 ? (
            <div className="space-y-4">
              {serversMounted.map((server, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-4 rounded-lg border p-4 transition-colors hover:bg-muted"
                >
                  <Server className="h-6 w-6 flex-shrink-0 text-primary" />
                  <div className="flex-grow">
                    <p className="font-medium">{server.instance_name}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="h-3 w-3 flex-shrink-0 rounded-full bg-primary" />
                    <span className="text-sm text-muted-foreground">
                      Active
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-muted-foreground">
              No servers currently mounted.
            </p>
          )}
        </ScrollArea>

        <DialogFooter className="sm:justify-between">
          <p className="text-sm text-muted-foreground">
            Total servers: {serversMounted ? serversMounted.length : 0}
          </p>
          <DialogClose asChild>
            <Button variant="secondary">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
