import { Spinner } from '@/components/spinner'
import { useOrgRedirect } from '@/hooks/useOrgRedirect'
import { useUserStore } from '@/store/UserStore'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useEffect } from 'react'

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  const user = useUserStore((state) => state.user)
  const navigate = useNavigate()

  const { mutate } = useOrgRedirect()

  useEffect(() => {
    if (user) {
      mutate()
    } else {
      navigate({ to: '/login' })
    }
  }, [user])

  return (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  )
}

export default Index
