import { X } from 'lucide-react'
import { EmailEntry } from './types'

type EmailListProps = {
  emails: EmailEntry[]
  onEmailRemove: (email: string) => void
  onAllEmailsRemove: () => void
}

export const EmailList = ({
  emails,
  onEmailRemove,
  onAllEmailsRemove,
}: EmailListProps) => (
  <div className="space-y-4">
    <div className="space-y-2 max-h-[200px] overflow-y-auto">
      {emails.map((email, index) => (
        <div
          key={index}
          className="flex items-center justify-between bg-foreground/10 px-3 py-2 rounded-full"
        >
          <span className="text-sm w-full">{email.email}</span>
          <span className="text-xs text-foreground/50 pr-4">{email.type}</span>
          <button
            onClick={() => onEmailRemove(email.email)}
            className="text-foreground/40 hover:text-foreground/60"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
    {emails.length > 0 && (
      <button
        onClick={onAllEmailsRemove}
        className="text-destructive/50 text-sm text-right hover:underline w-fit ml-auto"
      >
        Remove all
      </button>
    )}
  </div>
)
