import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { useRemoveOrgUser } from '@/services/user-management'
import { useUserStore } from '@/store/UserStore'
import Gravatar from 'react-gravatar'
import { UsersViewType } from '../types'
import { Trash2 } from 'lucide-react'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

// RemoveUserDialog Component
type RemoveUserDialogProps = {
  userId: string
  userEmail: string
  usersViewType: UsersViewType
}

export const RemoveUserDialog = ({
  userId,
  userEmail,
  usersViewType,
}: RemoveUserDialogProps) => {
  const myEmail = useUserStore((state) => state.user?.email)
  const [open, setOpen] = useState(false)

  const { mutate, isPending } = useRemoveOrgUser({
    onSuccess: () => setOpen(false),
  })

  const isTriggerDisabled = myEmail === userEmail
  const buttonText =
    usersViewType === UsersViewType.PENDING ? 'Cancel' : 'Delete'

  const onConfirm = () => mutate(Number(userId))
  const onOpenChange = (isOpen: boolean) => setOpen(isOpen)

  return (
    <>
      <Button
        variant="outline-destructive"
        className="flex items-center gap-2"
        onClick={() => setOpen(true)}
        disabled={isTriggerDisabled}
      >
        <Trash2 className="h-4 w-4" />
        {buttonText}
      </Button>

      <ConfirmationDialog
        open={open}
        onOpenChange={onOpenChange}
        title="Remove User from Organization"
        description={`This will permanently delete the user with email: "${userEmail}". This action is irreversible!`}
        buttons={{
          confirm: {
            label: isPending ? 'Deleting...' : 'Delete',
            isDestructive: true,
            isLoading: isPending,
          },
          cancel: {
            label: 'Cancel',
          },
        }}
        confirmation={{
          text: userEmail,
          prompt:
            "If you want to continue removing this user, please type the user's email below to confirm:",
        }}
        info={{
          warningPoints: [
            'This action is irreversible and will remove the user from the organization.',
            'All associated data will be permanently deleted.',
          ],
          alert: {
            title: 'User Information',
            description: (
              <div className="flex items-center mt-2 gap-2">
                <Gravatar email={userEmail} className="h-6 w-6 rounded-full" />
                <p className="text-sm text-foreground/50">{userEmail}</p>
              </div>
            ),
          },
        }}
        onConfirm={onConfirm}
      />
    </>
  )
}
