import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { APIFetch, MutProps, useTokenAndOrgId } from '../utils'
import { ServerInstance, ServerInstanceResponse } from './types'
import {
  getServerViewSort,
  ServerViewSortKeys,
} from '@/pages/DashboardServers/types'
import { processPaginatedData } from '../utils'

const LIST_SERVER_PAGE_SIZE = 10

export const useListServerInstances = ({
  showAllUsers = false,
  sort = undefined,
  searchText = '',
  refetchInterval,
  staleTime,
}: {
  showAllUsers?: boolean
  sort?: ServerViewSortKeys
  searchText?: string
  refetchInterval?: number
  staleTime?: number
}) => {
  const { orgId, token } = useTokenAndOrgId()

  const sortData = sort
    ? getServerViewSort(sort)
    : getServerViewSort(ServerViewSortKeys.STARTED_AT_DESC)

  const fetchServerInstances = async ({ pageParam = 1 }) => {
    const body = {
      org_id: orgId,
      page_number: pageParam,
      page_size: LIST_SERVER_PAGE_SIZE,
      show_all_users: showAllUsers,
      sort_direction: sortData.sortDirection,
      sort_by: sortData.sortBy,
      search_text: searchText,
    }

    try {
      const result = await APIFetch.post('listServerInstances', {
        token,
        body,
        orgId,
      })

      // Validate and transform API response
      const instances = result?.data?.instances ?? []
      if (!Array.isArray(instances)) {
        console.error('Invalid API Response Format:', result)
        throw new Error('Invalid response from server')
      }

      // Map over instances if they exist
      const processedInstances = instances.map(
        (instance: ServerInstanceResponse) => ({
          ...instance,
          user_metadata: instance.user_metadata
            ? {
                first_name: instance.user_metadata.first_name,
                last_name: instance.user_metadata.last_name,
              }
            : undefined,
        })
      )

      return {
        instances: processedInstances,
        total: result?.data?.total ?? 0,
      }
    } catch (error) {
      console.error('Error fetching server instances:', error)
      return {
        instances: [],
        total: 0,
      }
    }
  }

  return useInfiniteQuery({
    queryKey: [
      'list-server-instances',
      orgId,
      token,
      showAllUsers,
      sort,
      searchText,
    ],
    queryFn: fetchServerInstances,
    enabled: !!orgId && !!token,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !lastPage.instances) {
        return undefined
      }
      return lastPage.instances.length === LIST_SERVER_PAGE_SIZE
        ? pages.length + 1
        : undefined
    },
    // Use processPaginatedData for consistent and reusable logic
    select: (data) => processPaginatedData(data.pages),
    refetchInterval,
    staleTime,
  })
}

interface UseGetServerInstanceOptions {
  enabled?: boolean
}

export const useGetServerInstance = (
  instanceId: number,
  options?: UseGetServerInstanceOptions
) => {
  const { orgId, token } = useTokenAndOrgId()

  return useQuery<ServerInstance>({
    queryKey: ['get-server-instance', instanceId, orgId, token],
    enabled: (options?.enabled ?? true) && !!orgId && !!token && !!instanceId,
    queryFn: async () => {
      const body = {
        instance_id: instanceId,
        org_id: orgId,
      }
      const result = await APIFetch.post('getServerInstance', {
        token,
        body,
        orgId,
      })
      return result.data as ServerInstance
    },
  })
}

export const useCreateServerInstance = (
  props?: MutProps
): UseMutationResult<
  unknown,
  Error,
  {
    instanceName: string
    templateId: number
    storageVolumeId?: number
    allowSSH: boolean
    extraPorts: number[]
  }
> => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data) => {
      const result = await APIFetch.post('createServerInstance', {
        token,
        orgId,
        body: {
          org_id: orgId,
          instance_name: data.instanceName,
          template_id: data.templateId,
          ...(data.storageVolumeId && {
            storage_volume_id: data.storageVolumeId,
          }),
          allow_ssh: data.allowSSH,
          extra_ports: data.extraPorts,
        },
      })

      queryClient.invalidateQueries({
        queryKey: ['list-server-instances'],
        exact: false,
      })

      return result.status
    },
    ...props,
  })
}

export const useDeleteServerInstance = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (instanceId: number) => {
      const result = await APIFetch.post('deleteServerInstance', {
        token,
        orgId,
        body: {
          org_id: orgId,
          instance_id: instanceId,
        },
      })

      queryClient.invalidateQueries({
        queryKey: ['list-server-instances'],
        exact: false,
      })

      return result.status
    },
    ...props,
  })
}
export const useUpdateServerInstance = (
  props?: MutProps
): UseMutationResult<
  unknown,
  Error,
  { instanceId: number; instanceName: string }
> => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { instanceId: number; instanceName: string }) => {
      const result = await APIFetch.post('updateServerInstance', {
        token,
        orgId,
        body: {
          instance_id: data.instanceId,
          instance_name: data.instanceName,
        },
      })

      queryClient.invalidateQueries({
        queryKey: ['list-server-instances'],
        exact: false,
      })
      queryClient.invalidateQueries({
        queryKey: ['get-server-instance', data.instanceId, orgId, token],
        exact: false,
      })

      return result.status
    },
    ...props,
  })
}

interface ServerMetricsResponse {
  status: string
  data: {
    CPU: {
      limit: number
      usage: number
    }
    Memory: {
      limit: number
      usage: number
    }
    timestamp: string
  }
}

function toGiB(bytes: number) {
  return bytes / 1024 ** 3
}

export function useGetServerInstanceMetrics(
  instanceId: number,
  orgId: number,
  token?: string
) {
  // refetchInterval set to 10s
  const result = useQuery<ServerMetricsResponse>({
    queryKey: ['serverInstanceMetrics', instanceId],
    queryFn: async () => {
      if (!token) {
        throw new Error('No auth token provided')
      }
      const result = await APIFetch.post('getServerInstanceMetrics', {
        token,
        orgId,
        body: {
          instance_id: instanceId,
        },
      })

      return result as Promise<ServerMetricsResponse>
    },
    enabled: Boolean(instanceId && orgId && token),
    refetchInterval: 10000,
  })

  let cpuUsage = 0
  let cpuLimit = 0
  let memoryUsage = 0
  let memoryLimit = 0
  let cpuPercent = 0
  let memoryPercent = 0
  let memoryUsageGiB = 0
  let memoryLimitGiB = 0

  if (result.data && result.data.status === 'success') {
    const { CPU, Memory } = result.data.data
    cpuUsage = CPU.usage
    cpuLimit = CPU.limit
    memoryUsage = Memory.usage
    memoryLimit = Memory.limit

    cpuPercent = cpuLimit > 0 ? (cpuUsage / cpuLimit) * 100 : 0
    memoryPercent = memoryLimit > 0 ? (memoryUsage / memoryLimit) * 100 : 0

    memoryUsageGiB = toGiB(memoryUsage)
    memoryLimitGiB = toGiB(memoryLimit)
  }

  return {
    ...result,
    cpuUsage,
    cpuLimit,
    memoryUsage,
    memoryLimit,
    cpuPercent,
    memoryPercent,
    memoryUsageGiB,
    memoryLimitGiB,
  }
}
