import { useState, useEffect } from 'react'
import { Plus } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { DialogTrigger } from '@radix-ui/react-dialog'
import {
  useCreateOrgInviteBatch,
  getOrgUsers,
} from '@/services/user-management'
import { toast } from 'react-toastify'
import { useTokenAndOrgId } from '@/services/utils'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tabs } from '@radix-ui/react-tabs'

import { ManualEntryTab } from './ManualEntryTab'
import { CsvUploadTab } from './CsvUploadTab'
import { EmailList } from './EmailList'
import { EmailEntry } from './types'
type OrgUser = {
  email: string
  status: string
}

// Main component
export const InviteUsers = () => {
  const { token, orgId } = useTokenAndOrgId()

  const [isOpen, setIsOpen] = useState(false)
  const [emails, setEmails] = useState<EmailEntry[]>([])
  const [existingUsers, setExistingUsers] = useState<OrgUser[]>([])

  const { data: orgData } = useActiveOrgProfile()

  const storageLimitGB =
    orgData?.org.metadata.limits.org_per_user_storage_gb || 0

  // Fetch existing users
  useEffect(() => {
    if (!isOpen || !token || !orgId) return

    const fetchOrgUsers = async () => {
      try {
        const activeUsers = await getOrgUsers(token, orgId, 100, 1, 'active')
        const invitedUsers = await getOrgUsers(token, orgId, 100, 1, 'invited')
        setExistingUsers([...activeUsers.users, ...invitedUsers.users])
      } catch (error) {
        console.error('Failed to fetch existing users:', error)
        toast.error('Failed to load existing users.')
      }
    }

    fetchOrgUsers()
  }, [isOpen, token, orgId])

  // Email list management
  const addEmails = (newEmails: EmailEntry[]) => {
    setEmails((prev) => [...prev, ...newEmails])
  }

  const removeEmail = (emailToRemove: string) => {
    setEmails((prev) => prev.filter((email) => email.email !== emailToRemove))
  }

  const removeAllEmails = () => {
    setEmails([])
  }

  // API integration
  const { mutateAsync: batchInvite, isPending: sendingInvites } =
    useCreateOrgInviteBatch({
      onSuccess: () => {
        setIsOpen(false)
        setEmails([])
      },
    })

  const onInviteSubmit = async () => {
    await batchInvite(
      emails.map((email) => ({ email: email.email, role: email.type }))
    )
  }

  const validateEmail = (
    email: string
  ): { valid: boolean; errorMessage: string } => {
    const trimmedEmail = email.trim()

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
      return {
        valid: false,
        errorMessage: 'Please enter a valid email address.',
      }
    }

    const existingUser = existingUsers.find(
      (user) => user.email === trimmedEmail
    )
    if (existingUser?.status === 'active') {
      return {
        valid: false,
        errorMessage: `${trimmedEmail} is already a member of the organization.`,
      }
    }

    if (emails.some((e) => e.email === trimmedEmail)) {
      return {
        valid: false,
        errorMessage: `${trimmedEmail} is already in the invite list.`,
      }
    }

    // Here we're just validating, but the type is preserved for API consistency
    return { valid: true, errorMessage: '' }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          Invite Users
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Invite new users</DialogTitle>
        </DialogHeader>

        <Tabs defaultValue="manual" className="w-full">
          <TabsList>
            <TabsTrigger value="manual">Manual Entry</TabsTrigger>
            <TabsTrigger value="csv">CSV Upload</TabsTrigger>
          </TabsList>

          <TabsContent value="manual">
            <ManualEntryTab
              onEmailAdd={(email) => {
                const validation = validateEmail(email.email)
                if (validation.valid) {
                  addEmails([email])
                  return { success: true, message: '' }
                }
                return { success: false, message: validation.errorMessage }
              }}
            />
          </TabsContent>

          <TabsContent value="csv">
            <CsvUploadTab
              existingUsers={existingUsers}
              currentEmails={emails}
              onEmailsAdd={addEmails}
            />
          </TabsContent>
        </Tabs>

        <EmailList
          emails={emails}
          onEmailRemove={removeEmail}
          onAllEmailsRemove={removeAllEmails}
        />

        <p className="text-sm text-foreground/50">
          Invited users will have access to shared compute resources and{' '}
          {storageLimitGB}GB of Storage.
        </p>

        <DialogFooter>
          <Button
            className="w-full"
            onClick={onInviteSubmit}
            disabled={!emails.length || sendingInvites}
          >
            {sendingInvites
              ? 'Sending Invites...'
              : `Send Invites (${emails.length})`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
