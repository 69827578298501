import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { useUserStore } from '@/store/UserStore'
import { toast } from 'react-toastify'

export const API_URL = import.meta.env.VITE_API_URL as string

type FetchOptions = {
  token: string
}

// must have for future API designs
type OrgId = {
  orgId: number
}

export class APIFetch {
  // authenticated requests

  // static async get(endpoint: string, op: FetchOptions) {
  //   const resRaw = await fetch(`${API_URL}/${endpoint}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${op.token}`,
  //     },
  //   });
  //   const res = await resRaw.json();
  //   if (!resRaw.ok) {
  //     throw new Error(res.error);
  //   }
  //   return res;
  // }

  static async post(
    endpoint: string,
    op: FetchOptions & OrgId & { body?: object }
  ) {
    const headers: HeadersInit = {
      Authorization: `Bearer ${op.token}`,
      'Org-ID': op.orgId.toString(),
    }

    if (!(op.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json'
    }

    const resRaw = await fetch(`${API_URL}/${endpoint}`, {
      method: 'POST',
      headers,
      body: op.body instanceof FormData ? op.body : JSON.stringify(op.body),
    })

    const res = await resRaw.json()

    if (!resRaw.ok) {
      throw new Error(res.error)
    }

    return res
  }

  static async post_public(endpoint: string, op: { body?: object }) {
    const resRaw = await fetch(`${API_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(op.body),
    })

    const res = await resRaw.json()

    if (!resRaw.ok) {
      throw new Error(res.error)
    }

    return res
  }
}

// utility function to get the token & orgId
export const useTokenAndOrgId = () => {
  const { orgId } = useActiveOrgId()
  const token = useUserStore((state) => state.user?.token) || ''
  return { orgId, token }
}

export type MutProps = {
  onSuccess?: (data: unknown) => void
  onError?: (error: Error) => void
}

export const processPaginatedData = <T>(
  pages: Array<{ instances?: T[]; users?: T[]; total?: number } | undefined>
) => {
  const allInstances = pages.flatMap(
    (page) => page?.instances || page?.users || []
  )
  const total = pages[0]?.total || 0

  return { allInstances, total }
}

export const showErrorToastAndThrow = (
  error: unknown,
  customMessage?: string
) => {
  const errorMessage = customMessage || 'Operation failed'
  const details = error instanceof Error ? error.message : String(error)
  toast.error(`${errorMessage}. ERROR: ${details}`)
  throw error
}

export const showSuccessToast = (message: string) => {
  toast.success(message)
}
