import { useState } from 'react'
import { TrashIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useDeleteServerInstance } from '@/services/server'

export const DeleteServerDialog = ({
  instanceId,
  serverName,
  onDeleteConfirm,
}: {
  instanceId: number
  serverName: string
  onDeleteConfirm?: () => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { mutateAsync: deleteServerMutation, isPending } =
    useDeleteServerInstance({
      onSuccess: () => {
        onDeleteConfirm?.()
        setIsOpen(false)
      },
    })

  return (
    <>
      <Button
        variant="outline-destructive"
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2"
      >
        <TrashIcon className="h-4 w-4" />
        Delete
      </Button>

      <ConfirmationDialog
        open={isOpen}
        onOpenChange={setIsOpen}
        title="Delete Server"
        description={`This will permanently delete server '${serverName}' and all associated data.`}
        buttons={{
          confirm: {
            label: isPending ? 'Deleting...' : 'Delete Server',
            isDestructive: true,
            isLoading: isPending,
          },
          cancel: {
            label: 'Cancel',
          },
        }}
        confirmation={{
          text: serverName,
          prompt: `Type the server name to confirm deletion:`,
        }}
        info={{
          warningPoints: [
            'All server data will be permanently deleted',
            'Any running applications will be terminated immediately',
            'Connected users will lose access to this server',
          ],
        }}
        onConfirm={() => deleteServerMutation(instanceId)}
      />
    </>
  )
}
