import { Card } from '@/components/ui/card'
import { School, User, Plus } from 'lucide-react'
import { BalanceCardProps } from '../types'
import { OrgTopUpForm } from './OrgTopUpForm'
import { UserTopUpForm } from './UserTopUpForm'
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useState } from 'react'

type TopUpType = 'org' | 'personal' | null

export const BalanceCard = ({ userId }: BalanceCardProps) => {
  const [activeTopUp, setActiveTopUp] = useState<TopUpType>(null)

  const onOpenChange = (type: TopUpType) => (open: boolean) => {
    setActiveTopUp(open ? type : null)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-[1000px]">
      <CreditCard
        type="org"
        title="Organization Credits"
        icon={School}
        isActive={activeTopUp === 'org'}
        onOpenChange={onOpenChange('org')}
      />
      <CreditCard
        type="personal"
        title="Personal Credits"
        icon={User}
        userId={Number(userId)}
        isActive={activeTopUp === 'personal'}
        onOpenChange={onOpenChange('personal')}
      />
    </div>
  )
}

type CreditCardProps = {
  type: 'org' | 'personal'
  title: string
  icon: typeof School | typeof User
  userId?: number
  isActive: boolean
  onOpenChange: (open: boolean) => void
}

export const CreditCard = ({
  type,
  title,
  icon: Icon,
  userId,
  isActive,
  onOpenChange,
}: CreditCardProps) => {
  if (type === 'personal' && !userId) {
    return null
  }

  return (
    <Card className="p-6 hover:shadow-md transition-all duration-300">
      <div className="flex justify-between mb-6">
        <div className="flex items-center gap-2">
          <Icon className="w-5 h-5 text-primary" />
          <h3 className="font-semibold">{title}</h3>
        </div>
        <Dialog open={isActive} onOpenChange={onOpenChange}>
          <DialogTrigger asChild>
            <Button size="sm" className="gap-1">
              <Plus className="w-4 h-4" /> Top Up
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[600px]">
            {type === 'org' ? (
              <OrgTopUpForm onClose={() => onOpenChange(false)} />
            ) : (
              <UserTopUpForm
                userId={userId!}
                onClose={() => onOpenChange(false)}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
      <div className="flex justify-between">
        {type === 'org' ? (
          <OrgTopUpForm variant="display-only" />
        ) : (
          <UserTopUpForm userId={userId!} variant="display-only" />
        )}
        <Icon className="w-24 h-24 text-[#FADADD] dark:text-[#323232]" />
      </div>
    </Card>
  )
}
