import { useParams, useNavigate } from '@tanstack/react-router'

export const useActiveOrgId = () => {
  const navigate = useNavigate()
  const orgIdParam = useParams({
    select: (params) => params.orgId,
    strict: false,
  })
  const orgId = Number(orgIdParam)

  const setOrgId = (newOrgId: number) => {
    navigate({
      to: '/$orgId/dashboard/home',
      params: { orgId: newOrgId.toString() },
    })
  }

  return { orgId, setOrgId }
}
