import { useState } from 'react'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { ServerInstance } from '@/services/server/types'
import { PencilLine } from 'lucide-react'
import { EditNameDialog } from './EditNameDialog'
import { useUpdateServerInstance } from '@/services/server'
import { toast } from 'react-toastify'
import { cn } from '@/lib/utils'

export type ServerPricingInfo = {
  runningCostDollars: string
  priceInDollars: number
}

type ServerDetailProps = {
  serverId: number
  serverInstance: ServerInstance
  uptime: string
  pricingInfo: ServerPricingInfo
  createdDate: string
}

type EditState = {
  isEditing: boolean
  tempName: string
}

export const ServerDetailHeader = ({
  serverId,
  serverInstance,
  uptime,
  pricingInfo,
  createdDate,
}: ServerDetailProps) => {
  const { instance_name, status, template_name, image, email } =
    serverInstance
  const { runningCostDollars, priceInDollars } = pricingInfo

  const [editState, setEditState] = useState<EditState>({
    isEditing: false,
    tempName: instance_name || '',
  })

  const updateMutation = useUpdateServerInstance()

  const onEditNameClick = () => {
    setEditState({
      isEditing: true,
      tempName: instance_name || '',
    })
  }

  const onEditNameSubmit = () => {
    const { tempName } = editState
    if (tempName && tempName !== instance_name) {
      updateMutation.mutate(
        { instanceId: serverId, instanceName: tempName },
        {
          onError: (err) => {
            toast.error(`Failed to update name: ${err.message}`)
          },
        }
      )
    }
    setEditState((prev) => ({ ...prev, isEditing: false }))
  }

  const onEditNameCancel = () => {
    setEditState({
      isEditing: false,
      tempName: instance_name || '',
    })
  }

  return (
    <>
      <div className="flex justify-between items-start">
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl font-bold">
              {instance_name ?? 'No instance name'}
            </h2>
            <Button
              variant="ghost"
              size="sm"
              onClick={onEditNameClick}
              className="h-auto p-1.5 text-foreground/60 hover:text-foreground hover:bg-foreground/5"
            >
              <PencilLine size={18} />
            </Button>
          </div>

          <Badge
            variant="outline"
            className={cn(
              status === 'running'
                ? 'bg-primary/10 text-primary'
                : status === 'stopped'
                  ? 'bg-destructive/10 text-destructive'
                  : 'bg-foreground/10 text-foreground/80'
            )}
          >
            {status ?? 'Unknown'} • {uptime}
          </Badge>
        </div>

        <div className="text-right space-y-1">
          <div className="flex items-center justify-end gap-1">
            <p className="text-lg font-semibold">${runningCostDollars}</p>
            {status === 'running' && (
              <span
                className="h-2 w-2 rounded-full bg-emerald-600 animate-pulse"
                title="Cost updating in real-time"
              ></span>
            )}
          </div>
          <p className="text-foreground/60">${priceInDollars.toFixed(2)}/hr</p>
        </div>
      </div>

      <div className="mt-6 space-y-2 text-sm">
        <div className="flex gap-2">
          <span className="text-foreground/60">Template:</span>
          <span className="font-medium">{template_name ?? 'N/A'}</span>
        </div>
        <div className="flex gap-2">
          <span className="text-foreground/60">Base Image:</span>
          <span className="font-medium">{image ?? 'N/A'}</span>
        </div>
        <div className="flex gap-2">
          <span className="text-foreground/60">Created By:</span>
          <span className="font-medium">{email ?? 'N/A'}</span>
        </div>
        <div className="flex gap-2">
          <span className="text-foreground/60">Created On:</span>
          <span className="font-medium">{createdDate}</span>
        </div>
      </div>

      <EditNameDialog
        isOpen={editState.isEditing}
        onClose={onEditNameCancel}
        onSubmit={onEditNameSubmit}
        initialValue={editState.tempName}
        onChange={(value) =>
          setEditState((prev) => ({ ...prev, tempName: value }))
        }
      />
    </>
  )
}
