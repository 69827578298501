import { useMemo, useState } from 'react'
import { useUserStore } from '@/store/UserStore'
import { StorageVolume } from '@/types'
import { ManageStorageHeader } from './Header'
import { StorageCard } from './StorageCard'
import { useListStorageVolumes } from '@/services/storage'
import { StorageViewSortKeys, StorageViewType } from './types'
import { Spinner } from '@/components/spinner'
import { Virtuoso } from 'react-virtuoso'
import { Link, Plus, ServerCog } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { CreateStorageVolume } from './CreateStorageVolume'

export const DashboardStorage = () => {
  const { user } = useUserStore()
  const currentUserEmail = user?.email
  const { orgId: activeOrgId } = useActiveOrgId()
  const [storageView, onStorageViewChange] = useState(
    StorageViewType.MY_STORAGE
  )
  const [storageSort, onStorageSortChange] = useState(
    StorageViewSortKeys.CREATED_AT_DESC
  )
  const [searchTerm, setSearchTerm] = useState('')

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListStorageVolumes({
    sort: storageSort,
    searchTerm,
    showAllUsers: storageView !== StorageViewType.MY_STORAGE,
  })

  const allVolumes: StorageVolume[] = useMemo(
    () => (data?.allVolumes as StorageVolume[]) || [],
    [data]
  )
  const totalVolumes = data?.total || 0

  const myVolumesList: StorageVolume[] = useMemo(() => {
    return allVolumes.filter(
      (volume: StorageVolume) => volume.user_email === currentUserEmail
    )
  }, [allVolumes, currentUserEmail])

  const currentVolumes: StorageVolume[] =
    storageView === StorageViewType.MY_STORAGE ? myVolumesList : allVolumes

  return (
    <div>
      <ManageStorageHeader
        storageViewType={storageView}
        onStorageViewChange={onStorageViewChange}
        storageViewSortType={storageSort}
        onStorageViewSortChange={onStorageSortChange}
        onSearchChange={setSearchTerm}
      />

      {/* Display the total count below the header */}
      {!isLoading && !isError && totalVolumes > 0 && (
        <div className="text-left ml-4 my-2 text-sm text-foreground/75">
          Showing {currentVolumes.length} of {totalVolumes} storage volumes
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <div className="mx-auto text-center text-red-500">
          Failed to load storage volumes.
        </div>
      ) : currentVolumes.length === 0 ? (
        <div className="mx-auto flex flex-col items-center gap-16 mt-28">
          {searchTerm.length > 0 ? (
            <h3 className="text-4xl font-medium">No search results.</h3>
          ) : storageView === StorageViewType.MY_STORAGE ? (
            <>
              <h3 className="text-2xl font-medium">
                You have no storage volumes.
              </h3>
              <ServerCog className="w-20 h-20 text-muted-foreground/50" />
              <p className="text-muted-foreground max-w-md text-center">
                Create your first server instance to get started with cloud
                computing resources
              </p>
            </>
          ) : (
            <>
              <h3 className="text-2xl font-medium">
                None of the Users have any storage volumes.
              </h3>
              <ServerCog className="w-20 h-20 text-muted-foreground/50" />
              <p className="text-muted-foreground max-w-md text-center">
                None of the users in this organization have any storage volumes.
              </p>
            </>
          )}

          <CreateStorageVolume />
        </div>
      ) : (
        <Virtuoso
          useWindowScroll
          data={currentVolumes}
          endReached={() => {
            if (hasNextPage) {
              fetchNextPage()
            }
          }}
          itemContent={(index, volume) => {
            return (
              <div key={index} className="my-4">
                <StorageCard {...volume} />
              </div>
            )
          }}
          components={{
            Footer: () => {
              return (
                <div className="text-center py-8">
                  {isFetchingNextPage ? (
                    <Spinner />
                  ) : hasNextPage ? null : (
                    <p className="text-sm text-foreground/50">End of list.</p>
                  )}
                </div>
              )
            },
          }}
        />
      )}
    </div>
  )
}
