import { Spinner } from '@/components/spinner'

export type UsageStatsProps = {
  periodSpend: string
  rollingAverageRate: string
  spendRate: string
  isLoading: boolean
}

export const UsageStats = ({
  periodSpend,
  rollingAverageRate,
  spendRate,
  isLoading,
}: UsageStatsProps) => {
  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="pl-6 mb-4">
        <span className="block text-foreground/60">Period Spend</span>
        <span className="text-xl font-semibold">{periodSpend}</span>
      </div>
      <div className="pl-6 mb-8">
        <div className="inline-block mr-8">
          <span className="block text-foreground/60">Rolling Average</span>
          <span className="text-xl font-semibold">
            {rollingAverageRate} / day
          </span>
        </div>
        <div className="inline-block">
          <span className="block text-foreground/60">Current Spend Rate</span>
          <span className="text-xl font-semibold">{spendRate} / hr</span>
        </div>
      </div>
    </>
  )
}
