import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { MyProfileForm } from './MyProfileForm'
import { OrgProfileForm } from './OrgProfileForm'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { isRoleAdmin } from '@/services/auth/utils'

export const DashboardProfile = () => {
  const [activeTab, setActiveTab] = useState('my')

  // Get the current user's profile and check if they are an admin
  const { data: activeOrgProfile } = useActiveOrgProfile()
  const isAdmin = isRoleAdmin(activeOrgProfile?.role?.role_name)

  // If the user is not an admin, directly show the MyProfileForm without tabs
  if (!isAdmin) {
    return (
      <div className="">
        <h1 className="text-3xl font-medium mb-6">Edit Profile</h1>
        <div className="max-w-2xl mt-12">
          <MyProfileForm />
        </div>
      </div>
    )
  }

  // If the user is an admin, show both tabs
  return (
    <div className="">
      <h1 className="text-3xl font-medium mb-6">Edit Profile</h1>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="mb-6">
          <TabsTrigger value="my">My Profile</TabsTrigger>
          <TabsTrigger value="org">Organization Profile</TabsTrigger>
        </TabsList>

        <TabsContent value="my" className="max-w-2xl mt-12">
          <MyProfileForm />
        </TabsContent>

        <TabsContent value="org" className="max-w-2xl mt-12">
          <OrgProfileForm />
        </TabsContent>
      </Tabs>
    </div>
  )
}
