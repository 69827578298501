import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { DateRange } from 'react-day-picker'
import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { CalendarIcon, ChevronDown } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import utc from 'dayjs/plugin/utc'
import { cn } from '@/lib/utils'

type CalendarPopoverProps = {
  range: DateRange
  onSelect: (range: DateRange) => void
  shouldDisable?: (date: Date) => boolean
}

export const CalendarPopover = ({
  range,
  onSelect,
  shouldDisable,
}: CalendarPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [_range, _onSelect] = useState<DateRange | undefined>(range)
  const [timeSlot, setTimeSlot] = useState('week')
  const [startDateInput, setStartDateInput] = useState('')
  const [endDateInput, setEndDateInput] = useState('')
  const [dateError, setDateError] = useState<string | null>(null)
  const [numberOfMonths, setNumberOfMonths] = useState(2)

  // Update number of months based on window width
  const updateMonthCount = useCallback(() => {
    setNumberOfMonths(window.innerWidth < 768 ? 1 : 2)
  }, [])

  // Initialize and set up resize listener
  useEffect(() => {
    updateMonthCount()
    window.addEventListener('resize', updateMonthCount)
    return () => window.removeEventListener('resize', updateMonthCount)
  }, [updateMonthCount])

  // Format date to YYYY-MM-DD for input fields
  const formatDateForInput = (date: Date | undefined) => {
    if (!date) return ''
    return dayjs(date).format('YYYY-MM-DD')
  }

  // Update input fields when range changes
  useEffect(() => {
    if (_range) {
      setStartDateInput(formatDateForInput(_range.from))
      setEndDateInput(formatDateForInput(_range.to))
    }
  }, [_range])

  const validateAndParseDate = (dateString: string): Date | null => {
    // Check if the date is in valid format (YYYY-MM-DD)
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return null
    }

    const parsedDate = dayjs(dateString).toDate()
    // Check if the date is valid and not in the future
    if (isNaN(parsedDate.getTime()) || parsedDate > new Date()) {
      return null
    }

    return parsedDate
  }

  const handleDateInputChange = (type: 'start' | 'end', value: string) => {
    setDateError(null)

    if (type === 'start') {
      setStartDateInput(value)
    } else {
      setEndDateInput(value)
    }

    // Only update range if both dates are valid
    if (!value) return

    const startDate =
      type === 'start' ? validateAndParseDate(value) : _range?.from
    const endDate = type === 'end' ? validateAndParseDate(value) : _range?.to

    if (!startDate || !endDate) return

    // Ensure start date is not after end date
    if (startDate > endDate) {
      setDateError(
        type === 'start'
          ? 'Start date cannot be after end date'
          : 'End date cannot be before start date'
      )
      return
    }

    const newRange = { from: startDate, to: endDate }
    _onSelect(newRange)
    onSelect(newRange)
  }

  const handleTimeSlotChange = (value: string) => {
    setTimeSlot(value)
    let newRange: DateRange
    dayjs.extend(utc)

    switch (value) {
      case 'day': {
        const yesterdayUTC = dayjs.utc().subtract(1, 'day')
        newRange = {
          from: yesterdayUTC.startOf('day').toDate(),
          to: yesterdayUTC.endOf('day').toDate(),
        }
        break
      }
      case 'week': {
        newRange = {
          from: dayjs().subtract(6, 'day').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        }
        break
      }
      case 'month': {
        newRange = {
          from: dayjs().subtract(4, 'week').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        }
        break
      }
      case 'year': {
        newRange = {
          from: dayjs().subtract(1, 'year').startOf('year').toDate(),
          to: dayjs().subtract(1, 'day').endOf('day').toDate(),
        }
        break
      }
      default:
        newRange = range
    }
    _onSelect(newRange)
    onSelect(newRange)
  }

  return (
    <div className="flex items-center gap-2 ml-4">
      {/* "Report Period:" Label */}
      <span className="text-sm text-foreground">Report Period:</span>

      {/* Dropdown for Past X Selection */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="link" className="px-0">
            {`Past ${timeSlot}`}
            <ChevronDown className="h-4 w-4 ml-1 inline" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Select Time Slot</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={timeSlot}
            onValueChange={handleTimeSlotChange}
          >
            <DropdownMenuRadioItem value="day">Past day</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="week">
              Past week
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="month">
              Past month
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="year">
              Past year
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* "or" Text */}
      <span className="text-sm text-muted-foreground pl-4">or</span>

      {/* Calendar Popover for Custom Time Period */}
      <Popover
        open={open}
        onOpenChange={(newOpen) => {
          if (newOpen === false) {
            if (!_range || !_range.to) return
          }
          setOpen(newOpen)

          if (newOpen === false && _range && _range.to) {
            onSelect(_range)
          }
        }}
      >
        <PopoverTrigger asChild>
          <Button variant="link" className="px-0">
            <CalendarIcon className="h-4 w-4 mr-1" />
            Customize time period
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-4 max-w-[calc(100vw-4rem)]"
          align="start"
        >
          {/* Text input fields for date range */}
          <div className="flex flex-col gap-3 mb-4">
            <h3 className="text-sm font-medium">Enter date range</h3>
            <div className="grid grid-cols-2 gap-3">
              <div className="space-y-1">
                <Label
                  htmlFor="start-date"
                  className="text-xs font-normal text-muted-foreground"
                >
                  From
                </Label>
                <Input
                  id="start-date"
                  type="date"
                  value={startDateInput}
                  onChange={(e) =>
                    handleDateInputChange('start', e.target.value)
                  }
                  placeholder="YYYY-MM-DD"
                  max={endDateInput || undefined}
                  className={cn(
                    'text-sm [&::-webkit-calendar-picker-indicator]:hidden [&::-moz-calendar-picker-indicator]:hidden [&::-ms-clear]:hidden',
                    dateError && dateError.includes('Start date')
                      ? 'border-destructive'
                      : ''
                  )}
                />
              </div>
              <div className="space-y-1">
                <Label
                  htmlFor="end-date"
                  className="text-xs font-normal text-muted-foreground"
                >
                  To
                </Label>
                <Input
                  id="end-date"
                  type="date"
                  value={endDateInput}
                  onChange={(e) => handleDateInputChange('end', e.target.value)}
                  placeholder="YYYY-MM-DD"
                  min={startDateInput || undefined}
                  className={cn(
                    'text-sm [&::-webkit-calendar-picker-indicator]:hidden [&::-moz-calendar-picker-indicator]:hidden [&::-ms-clear]:hidden',
                    dateError && dateError.includes('End date')
                      ? 'border-destructive'
                      : ''
                  )}
                />
              </div>
            </div>
            {dateError && (
              <p className="text-xs text-destructive mt-1">{dateError}</p>
            )}
          </div>

          <div className="relative my-2 pt-2 border-t">
            <div className="absolute -top-[9px] left-1/2 -translate-x-1/2 bg-background px-2 text-xs text-muted-foreground">
              or select from calendar
            </div>
          </div>

          <Calendar
            mode="range"
            numberOfMonths={numberOfMonths}
            disabled={(date) =>
              date > new Date() ||
              date < new Date('1900-01-01') ||
              shouldDisable?.(date) ||
              false
            }
            selected={_range}
            onSelect={(newRange) => {
              _onSelect(newRange)

              // Update text inputs when calendar selection changes
              if (newRange?.from) {
                setStartDateInput(formatDateForInput(newRange.from))
              }
              if (newRange?.to) {
                setEndDateInput(formatDateForInput(newRange.to))
              }

              if (newRange && newRange.from && newRange.to) {
                onSelect(newRange)
              }
            }}
            initialFocus
          />

          <div className="flex items-center justify-center py-2">
            {!_range ? (
              <p className="text-sm text-destructive">Select date range</p>
            ) : !_range.to ? (
              <p className="text-sm text-destructive">Select end date</p>
            ) : null}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
