import { cn } from '@/lib/utils'
import { LoaderPinwheel } from 'lucide-react'

type SpinnerProps = {
  className?: string
}

export const Spinner = ({ className }: SpinnerProps) => {
  return (
    <LoaderPinwheel
      className={cn('animate-spin h-5 w-5 mx-auto my-3', className)}
    />
  )
}
