import { Sort } from '@/constants/sort'

export enum ServerViewType {
  MY_SERVER = 'my-servers',
  USERS_SERVER = 'users-servers',
}

// Define the enum for sort keys
export enum ServerViewSortKeys {
  STARTED_AT_ASC = 'STARTED_AT_ASC',
  STARTED_AT_DESC = 'STARTED_AT_DESC',
  INSTANCE_NAME_ASC = 'INSTANCE_NAME_ASC',
  INSTANCE_NAME_DESC = 'INSTANCE_NAME_DESC',
}

// Create the sort configurations
export const ServerViewSortBy = {
  [ServerViewSortKeys.STARTED_AT_ASC]: {
    sortBy: 'started_at',
    sortDirection: 'asc' as const,
  },
  [ServerViewSortKeys.STARTED_AT_DESC]: {
    sortBy: 'started_at',
    sortDirection: 'desc' as const,
  },
  [ServerViewSortKeys.INSTANCE_NAME_ASC]: {
    sortBy: 'instance_name',
    sortDirection: 'asc' as const,
  },
  [ServerViewSortKeys.INSTANCE_NAME_DESC]: {
    sortBy: 'instance_name',
    sortDirection: 'desc' as const,
  },
} as const

// Derive the type from the const object
export type ServerViewSortBy =
  (typeof ServerViewSortBy)[keyof typeof ServerViewSortBy]

// Helper function to ensure type safety when accessing sort values
export function getServerViewSort(key: ServerViewSortKeys): Sort {
  return ServerViewSortBy[key]
}
