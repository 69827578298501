// Helper functions for formatting
export const formatRelativeTime = (timestamp: string): string => {
  const now = new Date()
  const notificationTime = new Date(timestamp)
  const diffInMs = now.getTime() - notificationTime.getTime()

  const diffInMinutes = Math.floor(diffInMs / (1000 * 60))
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

  if (diffInMinutes < 1) return 'Just now'
  if (diffInMinutes < 60)
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`
  if (diffInHours < 24)
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`

  if (diffInDays < 2) {
    return `Yesterday at ${notificationTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
  }

  if (diffInDays < 7) {
    return `${diffInDays} days ago • ${notificationTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
  }

  return `${new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year:
      notificationTime.getFullYear() !== now.getFullYear()
        ? 'numeric'
        : undefined,
  }).format(
    notificationTime
  )} • ${notificationTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
}

export const formatTransactionDate = (timestamp: string): string => {
  const date = new Date(timestamp)
  return `${new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  }).format(
    date
  )} at ${date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
}

export const formatShortDate = (timestamp: string): string => {
  const date = new Date(timestamp)
  return `${date.toLocaleDateString('en-US', { month: 'short' })} ${date.getDate()} • ${date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
}

// Export for backward compatibility
export const formatTimestamp = formatRelativeTime
