/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as R404Import } from './routes/404'
import { Route as IndexImport } from './routes/index'
import { Route as OrgIdIndexImport } from './routes/$orgId/index'
import { Route as OrgIdDashboardRouteImport } from './routes/$orgId/dashboard/route'
import { Route as OrgIdDashboardIndexImport } from './routes/$orgId/dashboard/index'
import { Route as OrgIdDashboardUsersImport } from './routes/$orgId/dashboard/users'
import { Route as OrgIdDashboardStorageImport } from './routes/$orgId/dashboard/storage'
import { Route as OrgIdDashboardSshkeysImport } from './routes/$orgId/dashboard/sshkeys'
import { Route as OrgIdDashboardSettingsImport } from './routes/$orgId/dashboard/settings'
import { Route as OrgIdDashboardProfileImport } from './routes/$orgId/dashboard/profile'
import { Route as OrgIdDashboardHomeImport } from './routes/$orgId/dashboard/home'
import { Route as OrgIdDashboardBillingImport } from './routes/$orgId/dashboard/billing'
import { Route as OrgIdDashboardAuditImport } from './routes/$orgId/dashboard/audit'
import { Route as OrgIdDashboardApikeysImport } from './routes/$orgId/dashboard/apikeys'
import { Route as OrgIdDashboardTemplatesIndexImport } from './routes/$orgId/dashboard/templates/index'
import { Route as OrgIdDashboardServersIndexImport } from './routes/$orgId/dashboard/servers/index'
import { Route as OrgIdDashboardTemplatesNewImport } from './routes/$orgId/dashboard/templates/new'
import { Route as OrgIdDashboardServersNewImport } from './routes/$orgId/dashboard/servers/new'
import { Route as OrgIdDashboardServersServerIdImport } from './routes/$orgId/dashboard/servers/$serverId'

// Create Virtual Routes

const SettingsLazyImport = createFileRoute('/settings')()
const LoginLazyImport = createFileRoute('/login')()
const ClustersLazyImport = createFileRoute('/clusters')()
const AboutLazyImport = createFileRoute('/about')()

// Create/Update Routes

const SettingsLazyRoute = SettingsLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/settings.lazy').then((d) => d.Route))

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const ClustersLazyRoute = ClustersLazyImport.update({
  id: '/clusters',
  path: '/clusters',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/clusters.lazy').then((d) => d.Route))

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const R404Route = R404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const OrgIdIndexRoute = OrgIdIndexImport.update({
  id: '/$orgId/',
  path: '/$orgId/',
  getParentRoute: () => rootRoute,
} as any)

const OrgIdDashboardRouteRoute = OrgIdDashboardRouteImport.update({
  id: '/$orgId/dashboard',
  path: '/$orgId/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const OrgIdDashboardIndexRoute = OrgIdDashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardUsersRoute = OrgIdDashboardUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardStorageRoute = OrgIdDashboardStorageImport.update({
  id: '/storage',
  path: '/storage',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardSshkeysRoute = OrgIdDashboardSshkeysImport.update({
  id: '/sshkeys',
  path: '/sshkeys',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardSettingsRoute = OrgIdDashboardSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardProfileRoute = OrgIdDashboardProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardHomeRoute = OrgIdDashboardHomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardBillingRoute = OrgIdDashboardBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardAuditRoute = OrgIdDashboardAuditImport.update({
  id: '/audit',
  path: '/audit',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardApikeysRoute = OrgIdDashboardApikeysImport.update({
  id: '/apikeys',
  path: '/apikeys',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardTemplatesIndexRoute =
  OrgIdDashboardTemplatesIndexImport.update({
    id: '/templates/',
    path: '/templates/',
    getParentRoute: () => OrgIdDashboardRouteRoute,
  } as any)

const OrgIdDashboardServersIndexRoute = OrgIdDashboardServersIndexImport.update(
  {
    id: '/servers/',
    path: '/servers/',
    getParentRoute: () => OrgIdDashboardRouteRoute,
  } as any,
)

const OrgIdDashboardTemplatesNewRoute = OrgIdDashboardTemplatesNewImport.update(
  {
    id: '/templates/new',
    path: '/templates/new',
    getParentRoute: () => OrgIdDashboardRouteRoute,
  } as any,
)

const OrgIdDashboardServersNewRoute = OrgIdDashboardServersNewImport.update({
  id: '/servers/new',
  path: '/servers/new',
  getParentRoute: () => OrgIdDashboardRouteRoute,
} as any)

const OrgIdDashboardServersServerIdRoute =
  OrgIdDashboardServersServerIdImport.update({
    id: '/servers/$serverId',
    path: '/servers/$serverId',
    getParentRoute: () => OrgIdDashboardRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/404': {
      id: '/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof R404Import
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/clusters': {
      id: '/clusters'
      path: '/clusters'
      fullPath: '/clusters'
      preLoaderRoute: typeof ClustersLazyImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsLazyImport
      parentRoute: typeof rootRoute
    }
    '/$orgId/dashboard': {
      id: '/$orgId/dashboard'
      path: '/$orgId/dashboard'
      fullPath: '/$orgId/dashboard'
      preLoaderRoute: typeof OrgIdDashboardRouteImport
      parentRoute: typeof rootRoute
    }
    '/$orgId/': {
      id: '/$orgId/'
      path: '/$orgId'
      fullPath: '/$orgId'
      preLoaderRoute: typeof OrgIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/$orgId/dashboard/apikeys': {
      id: '/$orgId/dashboard/apikeys'
      path: '/apikeys'
      fullPath: '/$orgId/dashboard/apikeys'
      preLoaderRoute: typeof OrgIdDashboardApikeysImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/audit': {
      id: '/$orgId/dashboard/audit'
      path: '/audit'
      fullPath: '/$orgId/dashboard/audit'
      preLoaderRoute: typeof OrgIdDashboardAuditImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/billing': {
      id: '/$orgId/dashboard/billing'
      path: '/billing'
      fullPath: '/$orgId/dashboard/billing'
      preLoaderRoute: typeof OrgIdDashboardBillingImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/home': {
      id: '/$orgId/dashboard/home'
      path: '/home'
      fullPath: '/$orgId/dashboard/home'
      preLoaderRoute: typeof OrgIdDashboardHomeImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/profile': {
      id: '/$orgId/dashboard/profile'
      path: '/profile'
      fullPath: '/$orgId/dashboard/profile'
      preLoaderRoute: typeof OrgIdDashboardProfileImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/settings': {
      id: '/$orgId/dashboard/settings'
      path: '/settings'
      fullPath: '/$orgId/dashboard/settings'
      preLoaderRoute: typeof OrgIdDashboardSettingsImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/sshkeys': {
      id: '/$orgId/dashboard/sshkeys'
      path: '/sshkeys'
      fullPath: '/$orgId/dashboard/sshkeys'
      preLoaderRoute: typeof OrgIdDashboardSshkeysImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/storage': {
      id: '/$orgId/dashboard/storage'
      path: '/storage'
      fullPath: '/$orgId/dashboard/storage'
      preLoaderRoute: typeof OrgIdDashboardStorageImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/users': {
      id: '/$orgId/dashboard/users'
      path: '/users'
      fullPath: '/$orgId/dashboard/users'
      preLoaderRoute: typeof OrgIdDashboardUsersImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/': {
      id: '/$orgId/dashboard/'
      path: '/'
      fullPath: '/$orgId/dashboard/'
      preLoaderRoute: typeof OrgIdDashboardIndexImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/servers/$serverId': {
      id: '/$orgId/dashboard/servers/$serverId'
      path: '/servers/$serverId'
      fullPath: '/$orgId/dashboard/servers/$serverId'
      preLoaderRoute: typeof OrgIdDashboardServersServerIdImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/servers/new': {
      id: '/$orgId/dashboard/servers/new'
      path: '/servers/new'
      fullPath: '/$orgId/dashboard/servers/new'
      preLoaderRoute: typeof OrgIdDashboardServersNewImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/templates/new': {
      id: '/$orgId/dashboard/templates/new'
      path: '/templates/new'
      fullPath: '/$orgId/dashboard/templates/new'
      preLoaderRoute: typeof OrgIdDashboardTemplatesNewImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/servers/': {
      id: '/$orgId/dashboard/servers/'
      path: '/servers'
      fullPath: '/$orgId/dashboard/servers'
      preLoaderRoute: typeof OrgIdDashboardServersIndexImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
    '/$orgId/dashboard/templates/': {
      id: '/$orgId/dashboard/templates/'
      path: '/templates'
      fullPath: '/$orgId/dashboard/templates'
      preLoaderRoute: typeof OrgIdDashboardTemplatesIndexImport
      parentRoute: typeof OrgIdDashboardRouteImport
    }
  }
}

// Create and export the route tree

interface OrgIdDashboardRouteRouteChildren {
  OrgIdDashboardApikeysRoute: typeof OrgIdDashboardApikeysRoute
  OrgIdDashboardAuditRoute: typeof OrgIdDashboardAuditRoute
  OrgIdDashboardBillingRoute: typeof OrgIdDashboardBillingRoute
  OrgIdDashboardHomeRoute: typeof OrgIdDashboardHomeRoute
  OrgIdDashboardProfileRoute: typeof OrgIdDashboardProfileRoute
  OrgIdDashboardSettingsRoute: typeof OrgIdDashboardSettingsRoute
  OrgIdDashboardSshkeysRoute: typeof OrgIdDashboardSshkeysRoute
  OrgIdDashboardStorageRoute: typeof OrgIdDashboardStorageRoute
  OrgIdDashboardUsersRoute: typeof OrgIdDashboardUsersRoute
  OrgIdDashboardIndexRoute: typeof OrgIdDashboardIndexRoute
  OrgIdDashboardServersServerIdRoute: typeof OrgIdDashboardServersServerIdRoute
  OrgIdDashboardServersNewRoute: typeof OrgIdDashboardServersNewRoute
  OrgIdDashboardTemplatesNewRoute: typeof OrgIdDashboardTemplatesNewRoute
  OrgIdDashboardServersIndexRoute: typeof OrgIdDashboardServersIndexRoute
  OrgIdDashboardTemplatesIndexRoute: typeof OrgIdDashboardTemplatesIndexRoute
}

const OrgIdDashboardRouteRouteChildren: OrgIdDashboardRouteRouteChildren = {
  OrgIdDashboardApikeysRoute: OrgIdDashboardApikeysRoute,
  OrgIdDashboardAuditRoute: OrgIdDashboardAuditRoute,
  OrgIdDashboardBillingRoute: OrgIdDashboardBillingRoute,
  OrgIdDashboardHomeRoute: OrgIdDashboardHomeRoute,
  OrgIdDashboardProfileRoute: OrgIdDashboardProfileRoute,
  OrgIdDashboardSettingsRoute: OrgIdDashboardSettingsRoute,
  OrgIdDashboardSshkeysRoute: OrgIdDashboardSshkeysRoute,
  OrgIdDashboardStorageRoute: OrgIdDashboardStorageRoute,
  OrgIdDashboardUsersRoute: OrgIdDashboardUsersRoute,
  OrgIdDashboardIndexRoute: OrgIdDashboardIndexRoute,
  OrgIdDashboardServersServerIdRoute: OrgIdDashboardServersServerIdRoute,
  OrgIdDashboardServersNewRoute: OrgIdDashboardServersNewRoute,
  OrgIdDashboardTemplatesNewRoute: OrgIdDashboardTemplatesNewRoute,
  OrgIdDashboardServersIndexRoute: OrgIdDashboardServersIndexRoute,
  OrgIdDashboardTemplatesIndexRoute: OrgIdDashboardTemplatesIndexRoute,
}

const OrgIdDashboardRouteRouteWithChildren =
  OrgIdDashboardRouteRoute._addFileChildren(OrgIdDashboardRouteRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId/dashboard': typeof OrgIdDashboardRouteRouteWithChildren
  '/$orgId': typeof OrgIdIndexRoute
  '/$orgId/dashboard/apikeys': typeof OrgIdDashboardApikeysRoute
  '/$orgId/dashboard/audit': typeof OrgIdDashboardAuditRoute
  '/$orgId/dashboard/billing': typeof OrgIdDashboardBillingRoute
  '/$orgId/dashboard/home': typeof OrgIdDashboardHomeRoute
  '/$orgId/dashboard/profile': typeof OrgIdDashboardProfileRoute
  '/$orgId/dashboard/settings': typeof OrgIdDashboardSettingsRoute
  '/$orgId/dashboard/sshkeys': typeof OrgIdDashboardSshkeysRoute
  '/$orgId/dashboard/storage': typeof OrgIdDashboardStorageRoute
  '/$orgId/dashboard/users': typeof OrgIdDashboardUsersRoute
  '/$orgId/dashboard/': typeof OrgIdDashboardIndexRoute
  '/$orgId/dashboard/servers/$serverId': typeof OrgIdDashboardServersServerIdRoute
  '/$orgId/dashboard/servers/new': typeof OrgIdDashboardServersNewRoute
  '/$orgId/dashboard/templates/new': typeof OrgIdDashboardTemplatesNewRoute
  '/$orgId/dashboard/servers': typeof OrgIdDashboardServersIndexRoute
  '/$orgId/dashboard/templates': typeof OrgIdDashboardTemplatesIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId': typeof OrgIdIndexRoute
  '/$orgId/dashboard/apikeys': typeof OrgIdDashboardApikeysRoute
  '/$orgId/dashboard/audit': typeof OrgIdDashboardAuditRoute
  '/$orgId/dashboard/billing': typeof OrgIdDashboardBillingRoute
  '/$orgId/dashboard/home': typeof OrgIdDashboardHomeRoute
  '/$orgId/dashboard/profile': typeof OrgIdDashboardProfileRoute
  '/$orgId/dashboard/settings': typeof OrgIdDashboardSettingsRoute
  '/$orgId/dashboard/sshkeys': typeof OrgIdDashboardSshkeysRoute
  '/$orgId/dashboard/storage': typeof OrgIdDashboardStorageRoute
  '/$orgId/dashboard/users': typeof OrgIdDashboardUsersRoute
  '/$orgId/dashboard': typeof OrgIdDashboardIndexRoute
  '/$orgId/dashboard/servers/$serverId': typeof OrgIdDashboardServersServerIdRoute
  '/$orgId/dashboard/servers/new': typeof OrgIdDashboardServersNewRoute
  '/$orgId/dashboard/templates/new': typeof OrgIdDashboardTemplatesNewRoute
  '/$orgId/dashboard/servers': typeof OrgIdDashboardServersIndexRoute
  '/$orgId/dashboard/templates': typeof OrgIdDashboardTemplatesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/404': typeof R404Route
  '/about': typeof AboutLazyRoute
  '/clusters': typeof ClustersLazyRoute
  '/login': typeof LoginLazyRoute
  '/settings': typeof SettingsLazyRoute
  '/$orgId/dashboard': typeof OrgIdDashboardRouteRouteWithChildren
  '/$orgId/': typeof OrgIdIndexRoute
  '/$orgId/dashboard/apikeys': typeof OrgIdDashboardApikeysRoute
  '/$orgId/dashboard/audit': typeof OrgIdDashboardAuditRoute
  '/$orgId/dashboard/billing': typeof OrgIdDashboardBillingRoute
  '/$orgId/dashboard/home': typeof OrgIdDashboardHomeRoute
  '/$orgId/dashboard/profile': typeof OrgIdDashboardProfileRoute
  '/$orgId/dashboard/settings': typeof OrgIdDashboardSettingsRoute
  '/$orgId/dashboard/sshkeys': typeof OrgIdDashboardSshkeysRoute
  '/$orgId/dashboard/storage': typeof OrgIdDashboardStorageRoute
  '/$orgId/dashboard/users': typeof OrgIdDashboardUsersRoute
  '/$orgId/dashboard/': typeof OrgIdDashboardIndexRoute
  '/$orgId/dashboard/servers/$serverId': typeof OrgIdDashboardServersServerIdRoute
  '/$orgId/dashboard/servers/new': typeof OrgIdDashboardServersNewRoute
  '/$orgId/dashboard/templates/new': typeof OrgIdDashboardTemplatesNewRoute
  '/$orgId/dashboard/servers/': typeof OrgIdDashboardServersIndexRoute
  '/$orgId/dashboard/templates/': typeof OrgIdDashboardTemplatesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId/dashboard'
    | '/$orgId'
    | '/$orgId/dashboard/apikeys'
    | '/$orgId/dashboard/audit'
    | '/$orgId/dashboard/billing'
    | '/$orgId/dashboard/home'
    | '/$orgId/dashboard/profile'
    | '/$orgId/dashboard/settings'
    | '/$orgId/dashboard/sshkeys'
    | '/$orgId/dashboard/storage'
    | '/$orgId/dashboard/users'
    | '/$orgId/dashboard/'
    | '/$orgId/dashboard/servers/$serverId'
    | '/$orgId/dashboard/servers/new'
    | '/$orgId/dashboard/templates/new'
    | '/$orgId/dashboard/servers'
    | '/$orgId/dashboard/templates'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId'
    | '/$orgId/dashboard/apikeys'
    | '/$orgId/dashboard/audit'
    | '/$orgId/dashboard/billing'
    | '/$orgId/dashboard/home'
    | '/$orgId/dashboard/profile'
    | '/$orgId/dashboard/settings'
    | '/$orgId/dashboard/sshkeys'
    | '/$orgId/dashboard/storage'
    | '/$orgId/dashboard/users'
    | '/$orgId/dashboard'
    | '/$orgId/dashboard/servers/$serverId'
    | '/$orgId/dashboard/servers/new'
    | '/$orgId/dashboard/templates/new'
    | '/$orgId/dashboard/servers'
    | '/$orgId/dashboard/templates'
  id:
    | '__root__'
    | '/'
    | '/404'
    | '/about'
    | '/clusters'
    | '/login'
    | '/settings'
    | '/$orgId/dashboard'
    | '/$orgId/'
    | '/$orgId/dashboard/apikeys'
    | '/$orgId/dashboard/audit'
    | '/$orgId/dashboard/billing'
    | '/$orgId/dashboard/home'
    | '/$orgId/dashboard/profile'
    | '/$orgId/dashboard/settings'
    | '/$orgId/dashboard/sshkeys'
    | '/$orgId/dashboard/storage'
    | '/$orgId/dashboard/users'
    | '/$orgId/dashboard/'
    | '/$orgId/dashboard/servers/$serverId'
    | '/$orgId/dashboard/servers/new'
    | '/$orgId/dashboard/templates/new'
    | '/$orgId/dashboard/servers/'
    | '/$orgId/dashboard/templates/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  R404Route: typeof R404Route
  AboutLazyRoute: typeof AboutLazyRoute
  ClustersLazyRoute: typeof ClustersLazyRoute
  LoginLazyRoute: typeof LoginLazyRoute
  SettingsLazyRoute: typeof SettingsLazyRoute
  OrgIdDashboardRouteRoute: typeof OrgIdDashboardRouteRouteWithChildren
  OrgIdIndexRoute: typeof OrgIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  R404Route: R404Route,
  AboutLazyRoute: AboutLazyRoute,
  ClustersLazyRoute: ClustersLazyRoute,
  LoginLazyRoute: LoginLazyRoute,
  SettingsLazyRoute: SettingsLazyRoute,
  OrgIdDashboardRouteRoute: OrgIdDashboardRouteRouteWithChildren,
  OrgIdIndexRoute: OrgIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/404",
        "/about",
        "/clusters",
        "/login",
        "/settings",
        "/$orgId/dashboard",
        "/$orgId/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/404": {
      "filePath": "404.tsx"
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/clusters": {
      "filePath": "clusters.lazy.tsx"
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/settings": {
      "filePath": "settings.lazy.tsx"
    },
    "/$orgId/dashboard": {
      "filePath": "$orgId/dashboard/route.tsx",
      "children": [
        "/$orgId/dashboard/apikeys",
        "/$orgId/dashboard/audit",
        "/$orgId/dashboard/billing",
        "/$orgId/dashboard/home",
        "/$orgId/dashboard/profile",
        "/$orgId/dashboard/settings",
        "/$orgId/dashboard/sshkeys",
        "/$orgId/dashboard/storage",
        "/$orgId/dashboard/users",
        "/$orgId/dashboard/",
        "/$orgId/dashboard/servers/$serverId",
        "/$orgId/dashboard/servers/new",
        "/$orgId/dashboard/templates/new",
        "/$orgId/dashboard/servers/",
        "/$orgId/dashboard/templates/"
      ]
    },
    "/$orgId/": {
      "filePath": "$orgId/index.tsx"
    },
    "/$orgId/dashboard/apikeys": {
      "filePath": "$orgId/dashboard/apikeys.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/audit": {
      "filePath": "$orgId/dashboard/audit.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/billing": {
      "filePath": "$orgId/dashboard/billing.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/home": {
      "filePath": "$orgId/dashboard/home.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/profile": {
      "filePath": "$orgId/dashboard/profile.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/settings": {
      "filePath": "$orgId/dashboard/settings.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/sshkeys": {
      "filePath": "$orgId/dashboard/sshkeys.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/storage": {
      "filePath": "$orgId/dashboard/storage.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/users": {
      "filePath": "$orgId/dashboard/users.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/": {
      "filePath": "$orgId/dashboard/index.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/servers/$serverId": {
      "filePath": "$orgId/dashboard/servers/$serverId.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/servers/new": {
      "filePath": "$orgId/dashboard/servers/new.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/templates/new": {
      "filePath": "$orgId/dashboard/templates/new.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/servers/": {
      "filePath": "$orgId/dashboard/servers/index.tsx",
      "parent": "/$orgId/dashboard"
    },
    "/$orgId/dashboard/templates/": {
      "filePath": "$orgId/dashboard/templates/index.tsx",
      "parent": "/$orgId/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
