import { useForm } from 'react-hook-form'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Upload } from 'lucide-react'
import { ChangeEvent } from 'react'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useUpdateOrgProfile } from '@/services/profile'
import { cn } from '@/lib/utils'
import { US_STATES } from './constants'
import {
  FileUploadSchema,
  OrgProfileFormValues,
  ValidationMessages,
} from './types'

const stateEnum = z.enum(
  US_STATES.map((state) => state.value) as [string, ...string[]]
)

const OrgProfileFormSchema = z.object({
  orgName: z
    .string()
    .min(1, ValidationMessages.required('Organization name'))
    .max(50, ValidationMessages.maxLength('Organization name', 50)),
  department: z
    .string()
    .max(20, ValidationMessages.maxLength('Department name', 20)),
  address1: z
    .string()
    .min(1, ValidationMessages.required('Address'))
    .max(50, ValidationMessages.maxLength('Address', 50)),
  address2: z.string().max(50, ValidationMessages.maxLength('Address', 50)),
  city: z
    .string()
    .min(1, ValidationMessages.required('City'))
    .max(20, ValidationMessages.maxLength('City name', 20)),
  state: stateEnum,
  zip: z
    .string()
    .min(1, ValidationMessages.required('ZIP code'))
    .max(10, ValidationMessages.maxLength('ZIP code', 10)),
  logo: FileUploadSchema,
})

export const OrgProfileForm = () => {
  const { data: orgData } = useActiveOrgProfile()
  const { mutateAsync, isPending } = useUpdateOrgProfile()

  const form = useForm<OrgProfileFormValues>({
    resolver: zodResolver(OrgProfileFormSchema),
    defaultValues: {
      orgName: orgData?.org?.name || '',
      department: orgData?.org?.profile_metadata?.department_name || '',
      address1: orgData?.org?.profile_metadata?.address1 || '',
      address2: orgData?.org?.profile_metadata?.address2 || '',
      city: orgData?.org?.profile_metadata?.city || '',
      state: orgData?.org?.profile_metadata?.state || '',
      zip: orgData?.org?.profile_metadata?.zip_code || '',
    },
  })

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    // Validate file type
    if (!file.type.startsWith('image/')) {
      form.setError('logo', { message: 'Please select an image file' })
      return
    }

    // Validate file size (500KB)
    if (file.size > 500 * 1024) {
      form.setError('logo', { message: 'File size must be less than 500KB' })
      return
    }

    form.setValue('logo', file)
    form.clearErrors('logo')
  }

  const logoPreviewUrl = (() => {
    const logoFile = form.watch('logo')
    if (logoFile instanceof File) {
      return URL.createObjectURL(logoFile)
    }
    return orgData?.org?.profile_metadata?.avatar_preview_url
  })()

  async function onSubmit(data: OrgProfileFormValues) {
    const formData = new FormData()
    formData.append('org_name', data.orgName)
    formData.append('department_name', data.department)
    formData.append('address1', data.address1)
    formData.append('address2', data.address2)
    formData.append('city', data.city)
    formData.append('state', data.state)
    formData.append('zip_code', data.zip)
    formData.append('country', 'US')

    if (data.logo) {
      formData.append('file', data.logo)
    }

    await mutateAsync(formData)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8"
      >
        <Card className="border-primary/20">
          <CardContent className="px-14 py-12 flex flex-col gap-6">
            <FormField
              control={form.control}
              name="orgName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Organization Name</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn('w-full', 'focus:ring-primary/20')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="department"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Department</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn('w-full', 'focus:ring-primary/20')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address 1</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn('w-full', 'focus:ring-primary/20')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address 2</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn('w-full', 'focus:ring-primary/20')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-3 gap-4">
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className={cn('w-full', 'focus:ring-primary/20')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>State</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="focus:ring-primary/20">
                          <SelectValue placeholder="Select state" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {US_STATES.map((state) => (
                          <SelectItem key={state.value} value={state.value}>
                            {state.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="zip"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>ZIP Code</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className={cn('w-full', 'focus:ring-primary/20')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="logo"
              render={() => (
                <FormItem>
                  <FormLabel>Organization Logo</FormLabel>
                  <FormControl>
                    <div className="border-2 border-dashed border-border hover:border-primary/20 rounded-lg p-4 text-center transition-colors">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileSelect}
                        className="hidden"
                        id="logo-upload"
                      />
                      <label
                        htmlFor="logo-upload"
                        className="cursor-pointer block"
                      >
                        {logoPreviewUrl ? (
                          <img
                            src={logoPreviewUrl}
                            alt="Organization Logo"
                            className="max-h-48 mx-auto mb-2 rounded ring-2 ring-primary/20"
                          />
                        ) : (
                          <div className="flex flex-col items-center">
                            <Upload className="h-12 w-12 text-muted-foreground mb-2" />
                            <span className="text-sm text-muted-foreground">
                              Click to upload (max 500KB)
                            </span>
                          </div>
                        )}
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </Card>

        <Button
          type="submit"
          disabled={isPending}
          className="w-[150px] mx-auto"
        >
          {isPending ? 'Saving...' : 'Save'}
        </Button>
      </form>
    </Form>
  )
}
