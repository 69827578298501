import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { SquarePen, Info } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { useUpdateOrgLimit } from '@/services/settings'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

interface OrgServerLimitCardProps {
  onLimitChange?: (newLimit: number) => void
}

export const OrgServerLimitCard = ({
  onLimitChange,
}: OrgServerLimitCardProps) => {
  const [isEditingOrgLimit, setIsEditingOrgLimit] = useState(false)
  const [serverLimit, setServerLimit] = useState<number>(0)
  const [isConfirming, setIsConfirming] = useState(false)
  const [draftServerLimit, setDraftServerLimit] = useState<number>(0)

  const { data: activeOrgProfile } = useActiveOrgProfile()
  const updateOrgLimitMutation = useUpdateOrgLimit()

  useEffect(() => {
    if (activeOrgProfile?.org?.metadata?.limits?.org_per_user_server_count) {
      const newLimit =
        activeOrgProfile.org.metadata.limits.org_per_user_server_count
      setServerLimit(newLimit)

      if (onLimitChange) {
        onLimitChange(newLimit)
      }
    }
  }, [activeOrgProfile, onLimitChange])

  return (
    <Card
      className={cn(
        'p-8 rounded-lg shadow border',
        isEditingOrgLimit && 'border-2 border-primary'
      )}
    >
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold mb-4">
            Current Organization-Wide Server Limit
          </h2>
          <p className="text-muted-foreground mt-1">
            This limit will apply to all users without specific overrides
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-2xl font-bold">
            {serverLimit} active servers{' '}
            <span className="text-base font-normal">per user</span>
          </p>
          {!isEditingOrgLimit && (
            <Button
              variant="outline"
              onClick={() => {
                setDraftServerLimit(serverLimit)
                setIsEditingOrgLimit(true)
              }}
              className="mt-2 text-foreground dark:text-foreground border-foreground 
              dark:border-foreground hover:bg-foreground dark:hover:bg-foreground hover:text-background dark:hover:text-background"
            >
              <SquarePen className="mr-2" size={16} />
              Update Org Server Limit
            </Button>
          )}
        </div>
      </div>

      {isEditingOrgLimit && (
        <>
          <div className="border-t border my-4"></div>
          <div className="mt-4 space-y-2">
            <p className="text-sm text-foreground">Set new org limit</p>
            <div className="flex items-center space-x-4">
              <Input
                type="text"
                value={draftServerLimit}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '')
                  const normalizedValue = numericValue.replace(/^0+/, '')
                  setDraftServerLimit(
                    normalizedValue ? Number(normalizedValue) : 0
                  )
                }}
                className="w-[5.5rem] rounded-full text-center"
              />
              <Tooltip>
                <TooltipTrigger>
                  <Info className="text-primary cursor-pointer" size={20} />
                </TooltipTrigger>
                <TooltipContent className="bg-primary/10 text-foreground p-3 shadow-md rounded-md max-w-xs">
                  <p className="text-sm">
                    Updating the organization-wide limit will override all
                    existing custom user limits. Proceeding with this change
                    will apply the new limit to all users.
                  </p>
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="flex space-x-4">
              <Button
                variant="outline"
                onClick={() => setIsEditingOrgLimit(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="w-[150px] mx-auto"
                onClick={() => setIsConfirming(true)}
              >
                Apply to All Users
              </Button>
            </div>
          </div>
        </>
      )}

      <ConfirmationDialog
        open={isConfirming}
        onOpenChange={setIsConfirming}
        title="Update Organization-Wide Server Limit"
        description={
          <>
            You are about to update the organization-wide server limit to{' '}
            <b className="text-foreground/90">
              {draftServerLimit} active servers per user
            </b>
            . This change will affect all future resources, while all current
            resources will continue running based on the limits that were in
            effect when they were created.
          </>
        }
        buttons={{
          confirm: {
            label: 'Apply to All Users',
            isDestructive: true,
            isLoading: updateOrgLimitMutation.isPending,
          },
        }}
        confirmation={{
          text: String(draftServerLimit),
        }}
        info={{
          warningPoints: [
            'All customized user limits will be overwritten for future resources.',
            'Existing resources will continue running based on the limits from when they were created.',
          ],
        }}
        onConfirm={() => {
          updateOrgLimitMutation.mutate(
            { org_per_user_server_count: draftServerLimit },
            {
              onSuccess: () => {
                setIsConfirming(false)
                setIsEditingOrgLimit(false)
              },
              onError: (err) => {
                console.error(err)
              },
            }
          )
        }}
        onCancel={() => {
          setIsConfirming(false)
        }}
      />
    </Card>
  )
}
