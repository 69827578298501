import { TrashIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useDeleteStorageVolume } from '@/services/storage'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

type StorageDeleteDialogProps = {
  id: string
  name: string
  status: string
  onDeleteSuccess: () => void
}

export const StorageDeleteDialog = ({
  id,
  name,
  status,
  onDeleteSuccess,
}: StorageDeleteDialogProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { mutate, isPending } = useDeleteStorageVolume({
    onSuccess: () => {
      setIsOpen(false)
      toast.success('Volume deleted successfully')
      onDeleteSuccess()
    },
    onError: () => {
      toast.error('Failed to delete volume')
    },
  })

  const onDeleteClick = () => {
    if (status === 'mounted') {
      toast.warning(
        'Cannot delete storage volume while it is mounted on a server, please delete the associated server first'
      )
      return
    }
    setIsOpen(true)
  }

  const onConfirm = () => {
    mutate(id)
  }

  return (
    <>
      <Button
        variant="outline"
        className="flex items-center gap-2"
        onClick={onDeleteClick}
      >
        <TrashIcon className="h-4 w-4" /> Delete
      </Button>

      <ConfirmationDialog
        open={isOpen}
        onOpenChange={setIsOpen}
        title="Delete Storage Volume"
        description="Are you sure you want to delete this storage volume?"
        buttons={{
          confirm: {
            label: isPending ? 'Deleting...' : 'Delete',
            isDestructive: true,
            isLoading: isPending,
          },
        }}
        confirmation={{
          text: name,
          prompt: 'Please type the Storage Volume name to confirm deletion:',
        }}
        info={{
          warningPoints: [
            'This action is destructive and cannot be undone.',
            'All associated data will be permanently lost.',
          ],
          alert: {
            title: <span>Storage Volume Details:</span>,
            description: (
              <>
                Name: <b>{name}</b> | ID: <b>{id}</b>
              </>
            ),
          },
        }}
        onConfirm={onConfirm}
      />
    </>
  )
}
