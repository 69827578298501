import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { useGetOrgUserProfile } from '@/services/auth'
import { useGetOrgSpendRate, useGetOrgUsageGraph } from '@/services/home'
import { formatCurrency } from '@/lib/utils'
import { getFormattedUnixDate } from '@/helpers/time'
import { useMemo } from 'react'
import { DateRange } from 'react-day-picker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useListServerInstances } from '@/services/server'
dayjs.extend(utc)

export type UsageStats = {
  periodSpend: string
  rollingAverageRate: string
  spendRate: string
  isLoading: boolean
}

export const useUsageChartData = (range: DateRange, userId?: number) => {
  const { orgId } = useActiveOrgId()
  const { data: org, isLoading: isOrgUserProfileLoading } =
    useGetOrgUserProfile(orgId)

  // Check if user has server instances
  const { data: serverData } = useListServerInstances({
    showAllUsers: userId ? false : true,
  })

  const totalDaysDiff = dayjs(range.to).diff(dayjs(range.from), 'day')

  const { data: spentRate, isLoading: isOrgSpendRateLoading } =
    useGetOrgSpendRate(userId)

  const { data, isLoading } = useGetOrgUsageGraph(
    {
      interval: 'day',
      start_at: getFormattedUnixDate(range.from, { time: 'startOfDay' }),
      end_at: getFormattedUnixDate(range.to, { time: 'endOfDay' }),
    },
    userId
  )

  const chartData = useMemo(() => {
    if (!data?.items) return []

    return data.items.map((item) => ({
      date: dayjs.utc(item.date).format('D MMM'),
      'Total Cost': item.total_cost / 100,
      formatted_total_cost: formatCurrency(item.total_cost / 100),
    }))
  }, [data])

  const stats = useMemo((): UsageStats => {
    const formattedSpendRate = !spentRate
      ? '0'
      : formatCurrency(spentRate.total_cost / 100)

    if (chartData.length === 0) {
      return {
        periodSpend: '$0.00',
        rollingAverageRate: '$0.00',
        spendRate: formattedSpendRate,
        isLoading: isOrgSpendRateLoading,
      }
    }

    const totalCost = chartData.reduce(
      (acc, item) => acc + item['Total Cost'],
      0
    )

    const periodSpend = formatCurrency(totalCost)
    const rollingAverageRate = formatCurrency(totalCost / chartData.length)

    return {
      periodSpend,
      rollingAverageRate,
      spendRate: formattedSpendRate,
      isLoading: isOrgSpendRateLoading,
    }
  }, [chartData, spentRate, isOrgSpendRateLoading])

  const isDateDisabled = (date: Date) => {
    return isOrgUserProfileLoading
      ? true
      : dayjs(date).isBefore(dayjs(org?.org.created_at))
  }

  // Check if we should show empty state - either no data OR no servers
  const hasServers = useMemo(() => {
    return serverData?.allInstances && serverData.allInstances.length > 0
  }, [serverData])

  return {
    data: chartData,
    isLoading,
    totalDaysDiff,
    isDateDisabled,
    isEmpty: chartData.length === 0,
    hasNoServers: !hasServers,
    stats,
  }
}
