import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { toast } from 'react-toastify'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { useCreateSSHKey } from '@/services/sshkeys'
import { Plus } from 'lucide-react'

const FormSchema = z.object({
  sshKey: z
    .string()
    .refine((val) => /^ssh-(rsa|dss|ed25519|ecdsa) \S+/.test(val.trim()), {
      message: 'Invalid SSH key',
    }),
})

export const CreateSSHKey = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      sshKey: '',
    },
  })

  const { mutateAsync, isPending, reset } = useCreateSSHKey({
    onSuccess: () => {
      toast('SSH key added successfully', { type: 'success' })
    },
    onError: (error) => {
      toast(error.message, { type: 'error' })
    },
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    await mutateAsync({
      alias: 'default',
      sshKey: data.sshKey,
    })

    reset()
    form.reset()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4">
        <FormField
          disabled={isPending}
          control={form.control}
          name="sshKey"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  placeholder="Paste your public SSH key here"
                  className="w-full pt-4 min-h-[100px]"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="mt-4" type="submit" disabled={isPending}>
          {isPending ? (
            'Creating...'
          ) : (
            <>
              <Plus className="w-4 h-4 mr-2" />
              Save SSH Key
            </>
          )}
        </Button>
      </form>
    </Form>
  )
}
