import { Card, CardContent } from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { useState } from 'react'
import { Spinner } from '@/components/spinner'
import { CalendarPopover } from '@/components/calendar-popover'
import { DateRange } from 'react-day-picker'
import { useUsageChartData } from './useUsageChartData'
import { UsageStats } from './UsageStats'
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts'
import dayjs from 'dayjs'

type UsageChartProps = {
  userId?: number
}

export const UsageChart = ({ userId }: UsageChartProps) => {
  const [range, setRange] = useState<DateRange>({
    from: dayjs().subtract(1, 'week').toDate(),
    to: dayjs().toDate(),
  })

  const {
    data: chartData,
    isLoading,
    isDateDisabled,
    totalDaysDiff,
    stats,
    isEmpty,
    hasNoServers,
  } = useUsageChartData(range, userId)

  const chartConfig = {
    desktop: {
      label: 'Desktop',
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig

  return (
    <>
      <div className="flex items-center mb-2 ml-2">
        <h3 className="text-xl font-semibold mr-8">Usage</h3>
        <CalendarPopover
          range={range}
          onSelect={setRange}
          shouldDisable={isDateDisabled}
        />
      </div>

      <Card className="p-4 pl-0 min-h-[500px]">
        {isLoading ? (
          <Spinner />
        ) : (
          <CardContent className="mt-auto pl-0">
            <UsageStats
              periodSpend={stats.periodSpend}
              rollingAverageRate={stats.rollingAverageRate}
              spendRate={stats.spendRate}
              isLoading={stats.isLoading}
            />

            {hasNoServers ? (
              <EmptyComponent
                title="No Servers Found"
                description="You don't have any active servers"
                image="/assets/image/gpu-pink.png"
                action="Create a server to start tracking usage"
              />
            ) : isEmpty ? (
              <EmptyComponent
                title="No Usage Data"
                description="There's no activity for the selected period"
                image="/assets/image/empty-storage.png"
                action="Try selecting a different date range"
              />
            ) : (
              <ChartContainer
                config={chartConfig}
                className="h-[350px] bg-card/50 rounded-lg p-4"
              >
                <BarChart
                  accessibilityLayer
                  data={chartData}
                  margin={{
                    top: 20,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <YAxis tickLine={false} tickMargin={10} axisLine={false} />
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 6)}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent className="w-[150px]" />}
                  />
                  <Bar
                    dataKey="Total Cost"
                    fill="hsl(var(--chart-1))"
                    radius={8}
                  >
                    <LabelList
                      dataKey="formatted_total_cost"
                      className="fill-foreground"
                      content={(data) => {
                        const { x, y, height, value } = data

                        if (totalDaysDiff > 50) return null

                        const isOverflowing = totalDaysDiff > 10

                        return (
                          <g>
                            <text
                              className="fill-foreground"
                              style={{
                                writingMode: isOverflowing
                                  ? 'vertical-rl'
                                  : 'horizontal-tb',
                              }}
                              x={Number(x) + (isOverflowing ? 10 : 0)}
                              y={
                                Number(y) +
                                (isOverflowing ? 10 : -10) +
                                (Number(height) < 60 && isOverflowing ? -60 : 0)
                              }
                            >
                              {value}
                            </text>
                          </g>
                        )
                      }}
                    />
                  </Bar>
                </BarChart>
              </ChartContainer>
            )}
          </CardContent>
        )}
      </Card>
    </>
  )
}

type EmptyComponentProps = {
  title: string
  description: string
  image: string
  action: string
}

const EmptyComponent = ({
  title,
  description,
  image,
  action,
}: EmptyComponentProps) => {
  return (
    <div className="flex flex-col items-center justify-center p-6 h-[350px]">
      <div className="text-2xl font-medium mb-4 text-center">
        <span className="block text-primary font-bold">{title}</span>
        <span className="text-sm text-foreground/60 block mt-1">
          {description}
        </span>
      </div>
      <div className="border-2 border-dashed border-foreground/20 rounded-xl p-4 bg-background/10 mb-4">
        <img
          src={image}
          alt="No usage data"
          style={{ width: '200px', height: 'auto' }}
          className="opacity-90"
        />
      </div>
      <p className="text-foreground/60 text-sm text-center">{action}</p>
    </div>
  )
}
