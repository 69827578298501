import { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import {
  Download,
  ChevronsUpDown,
  ArrowUp,
  ArrowDown,
  Search,
} from 'lucide-react'
import { Input } from '@/components/ui/input'
import { CalendarPopover } from '@/components/calendar-popover'
import { DateRange } from 'react-day-picker'
import { useGetOrgInvoices } from '@/services/billing'
import dayjs from 'dayjs'
import Gravatar from 'react-gravatar'
import { getFormattedUnixDate } from '@/helpers/time'
import utc from 'dayjs/plugin/utc'
import { Spinner } from '@/components/spinner'
import { cn } from '@/lib/utils'
dayjs.extend(utc)

export const TableDemo = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dateRange, setDateRange] = useState<DateRange>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  })

  const [sortBy, setSortBy] = useState<string>('created_at')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useGetOrgInvoices(
      {
        start_at: getFormattedUnixDate(dateRange.from, { time: 'startOfDay' }),
        end_at: getFormattedUnixDate(dateRange.to, { time: 'endOfDay' }),
        sort_by: sortBy,
        sort_direction: sortDirection,
        search: searchTerm,
      },
      10
    )

  const invoices = data?.pages.flatMap((page) => page.data.items) || []

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortDirection('desc')
    }
  }

  const observerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!hasNextPage || isFetching) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchNextPage()
        }
      },
      { root: null, rootMargin: '100px', threshold: 1.0 }
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => observer.disconnect()
  }, [hasNextPage, isFetching, fetchNextPage])

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4 flex-grow">
          <h2 className="text-xl font-semibold">Invoices</h2>
          <div className="relative flex-grow">
            <Search className="absolute w-4 h-4 left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
            <Input
              type="text"
              placeholder="Search Invoice ID, Date, or Amount..."
              className="pl-10 w-full"
              value={searchTerm}
              onChange={handleSearchChange}
              maxLength={100}
            />
          </div>
        </div>
        <CalendarPopover
          range={dateRange}
          onSelect={(range) => {
            if (range?.from && range?.to) {
              setDateRange({ from: range.from, to: range.to })
            }
          }}
        />
      </div>

      <div className="rounded-md border min-h-[500px]">
        {isLoading ? (
          <div className="flex justify-center items-center h-[500px]">
            <Spinner />
          </div>
        ) : (
          <div
            className={cn(
              !invoices.length && 'min-h-[400px] flex items-center justify-center'
            )}
          >
            {invoices.length ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead
                      className="py-6 pl-12 font-semibold cursor-pointer"
                      onClick={() => handleSort('stripe_invoice_id')}
                    >
                      <div className="flex items-center gap-2">
                        Invoice ID
                        {sortBy === 'stripe_invoice_id' ? (
                          sortDirection === 'asc' ? (
                            <ArrowUp size={16} />
                          ) : (
                            <ArrowDown size={16} />
                          )
                        ) : (
                          <ChevronsUpDown size={16} />
                        )}
                      </div>
                    </TableHead>
                    <TableHead
                      className="py-6 font-semibold cursor-pointer"
                      onClick={() => handleSort('created_at')}
                    >
                      <div className="flex items-center gap-2">
                        Date
                        {sortBy === 'created_at' ? (
                          sortDirection === 'asc' ? (
                            <ArrowUp size={16} />
                          ) : (
                            <ArrowDown size={16} />
                          )
                        ) : (
                          <ChevronsUpDown size={16} />
                        )}
                      </div>
                    </TableHead>
                    <TableHead className="py-6 font-semibold">Amount</TableHead>
                    <TableHead className="py-6 font-semibold">User</TableHead>
                    <TableHead className="py-6 font-semibold">Type</TableHead>
                    <TableHead className="py-6 font-semibold"></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {invoices.map((invoice) => {
                    const userProfile =
                      invoice.UserOrganization?.profile_metadata || {}
                    const userEmail = invoice.User?.email || 'N/A'
                    const downloadUrl = invoice.metadata?.download_page || ''

                    return (
                      <TableRow key={invoice.stripe_invoice_id}>
                        <TableCell className="py-6 pl-12 font-medium">
                          {invoice.stripe_invoice_id}
                        </TableCell>
                        <TableCell className="py-6">
                          {invoice.formattedDate}
                        </TableCell>
                        <TableCell className="py-6">{invoice.formattedAmount}</TableCell>
                        <TableCell className="py-6">
                          <div className="flex items-center gap-2">
                            {userProfile.avatar_preview_url ? (
                              <img
                                src={userProfile.avatar_preview_url}
                                alt={`${userProfile.first_name} ${userProfile.last_name}`}
                                className="w-8 h-8 rounded-full"
                              />
                            ) : (
                              <Gravatar
                                email={userEmail}
                                className="w-8 h-8 rounded-full"
                              />
                            )}
                            <div>
                              <p>
                                {userProfile.first_name} {userProfile.last_name}
                              </p>
                              <p className="text-sm text-muted-foreground">
                                {userEmail}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="py-6">{invoice.invoiceTypeLabel}</TableCell>
                        <TableCell className="py-6">
                          <Button
                            variant="ghost"
                            size="sm"
                            className="flex items-center gap-2 disabled:opacity-50"
                            disabled={!downloadUrl}
                          >
                            <a
                              href={downloadUrl || '#'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Download className="w-4 h-4" />
                              Download
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (
              <p className="text-muted-foreground">No invoices available</p>
            )}
          </div>
        )}
        {isFetching && hasNextPage && (
          <div className="flex justify-center py-4">
            <Spinner />
          </div>
        )}
      </div>

      <div ref={observerRef} className="h-10" />
    </div>
  )
}
