import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
} from '@/components/ui/sidebar'
import { useUserStore } from '@/store/UserStore'
import Gravatar from 'react-gravatar'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useState } from 'react'
import { useGetOrgUserProfile } from '@/services/auth'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { Navigate, useNavigate } from '@tanstack/react-router'
import { LogOut } from 'lucide-react'

export const DashboardSidebarFooter = () => {
  const user = useUserStore((state) => state.user)
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)

  const { orgId } = useActiveOrgId()
  const { data: profile } = useGetOrgUserProfile(orgId)

  const firstName = profile?.profile.first_name || ''
  const lastName = profile?.profile.last_name || ''
  const avatarUrl = profile?.profile.avatar_preview_url || ''

  const name = firstName || lastName ? `${firstName} ${lastName}` : '- -'

  if (!user) {
    return <Navigate to="/login" />
  }

  return (
    <>
      <SidebarFooter className="border-t border-border/50 mt-auto">
        <SidebarMenu>
          <SidebarMenuItem>
            <div className="flex items-center gap-3 text-sm h-14 pl-2">
              {avatarUrl ? (
                <img
                  src={avatarUrl}
                  alt={`${name}'s avatar`}
                  className="h-9 w-9 rounded-full object-cover border border-border/30"
                />
              ) : (
                <Gravatar
                  email={user.email}
                  className="h-9 w-9 rounded-full border border-border/30"
                />
              )}
              <div className="flex-grow">
                <div className="font-medium truncate">{name}</div>
                <div className="text-xs text-muted-foreground truncate">
                  {user.email}
                </div>
              </div>
            </div>

            <Button
              size="sm"
              variant="ghost"
              className="h-8 w-8 rounded-full p-0 hover:bg-destructive/10 hover:text-destructive"
              onClick={() => setIsLogoutDialogOpen(true)}
              title="Logout"
            >
              <LogOut className="h-4 w-4" />
            </Button>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>

      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        setIsOpen={setIsLogoutDialogOpen}
      />
    </>
  )
}

type LogoutDialogProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const LogoutDialog = ({ isOpen, setIsOpen }: LogoutDialogProps) => {
  const navigate = useNavigate()
  const { clearAuth } = useUserStore()

  const handleLogout = () => {
    clearAuth()
    navigate({ to: '/login' })
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogDescription>
            Are you sure you want to log out?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleLogout}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
