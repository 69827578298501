import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { APIFetch, MutProps, useTokenAndOrgId } from '@/services/utils'
import { STATUS_SUCCESS } from '@/constants/status'

interface SSHKeyResponse {
  key_id: number
  user_id: number
  org_id: number
  alias: string
  public_key: string
  created_at: string
  updated_at: string
}

interface SSHKey {
  id: string
  key: string
}

export const useGetSSHKeys = () => {
  const { token, orgId } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['ssh-keys', token, orgId],
    enabled: !!token && !!orgId,
    queryFn: async (): Promise<SSHKey[]> => {
      const result = await APIFetch.post('listSshKeys', {
        token,
        body: { org_id: orgId },
        orgId,
      })

      if (result.error) {
        throw new Error(`Error: ${result.error}`)
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error('Failed to list SSH keys, please try again.')
      }

      const keysData = result?.data.Keys

      if (!Array.isArray(keysData)) {
        throw new Error(
          `Expected data.Keys to be an array but got ${typeof keysData}`
        )
      }

      return keysData.map((key: SSHKeyResponse) => ({
        id: String(key.key_id),
        key: key.public_key,
      }))
    },
  })
}

export const useDeleteSSHKey = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      const result = await APIFetch.post('deleteSshKey', {
        token,
        body: { key_id: Number(id), org_id: orgId },
        orgId,
      })

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['ssh-keys', token, orgId] })

      return result.status
    },
    ...props,
  })
}

export const useCreateSSHKey = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (op: { alias: string; sshKey: string }) => {
      const result = await APIFetch.post('createSshKey', {
        token,
        body: {
          org_id: orgId,
          alias: op.alias,
          ssh_public_key: op.sshKey.trim(),
        },
        orgId,
      })

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['ssh-keys', token, orgId] })

      return result.status
    },
    ...props,
  })
}
