import {
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { type NotificationItemProps } from './types'
import { formatTransactionDate } from './utils'
import {
  NotificationTypeIcon,
  MarkAsReadButton,
  FormattedNotificationContent,
} from '.'

export const DefaultNotification = ({
  notification,
  isLoading,
  onMarkAsRead,
}: NotificationItemProps) => (
  <>
    <CardHeader className="py-3 px-4 flex flex-row items-center justify-between space-y-0">
      <div className="flex items-center gap-3">
        <NotificationTypeIcon type={notification.notification_type} />
        <CardTitle
          className={cn(
            'text-base font-semibold',
            notification.is_read ? 'text-foreground/70' : 'text-foreground'
          )}
        >
          {notification.title}
        </CardTitle>
      </div>
    </CardHeader>

    <CardContent className="px-4 py-2">
      <FormattedNotificationContent
        content={notification.detail || 'No details available.'}
      />
      <p className="text-xs text-foreground/60 mt-3">
        Updated on {formatTransactionDate(notification.created_at)}
      </p>
    </CardContent>

    {!notification.is_read && (
      <CardFooter className="px-4 py-2 flex justify-end border-t bg-background/20 dark:bg-background/10">
        <MarkAsReadButton
          isLoading={isLoading}
          onMarkAsRead={() => onMarkAsRead(notification.notification_id)}
        />
      </CardFooter>
    )}
  </>
)
