import { TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Badge } from '@/components/ui/badge'
import { useTotalUnreadCount } from '@/services/notification'

export const DashboardHomeHeader = () => {
  const { data: totalUnreadCount = 0, isLoading } = useTotalUnreadCount()

  return (
    <div className="mb-4 flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
      <TabsList>
        <TabsTrigger value="overview">Overview</TabsTrigger>
        <TabsTrigger value="analytics">Analytics</TabsTrigger>
        <TabsTrigger value="notifications" className="relative">
          Notifications
          {!isLoading && totalUnreadCount > 0 && (
            <Badge className="absolute -top-2 -right-4 bg-destructive text-destructive-foreground rounded-full px-2 py-1 text-xs">
              {totalUnreadCount}
            </Badge>
          )}
        </TabsTrigger>
      </TabsList>
    </div>
  )
}
