import { useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover'
import {
  Command,
  CommandInput,
  CommandList,
  CommandItem,
  CommandEmpty,
  CommandGroup,
} from '@/components/ui/command'
import { Check, ChevronsUpDown } from 'lucide-react'
import { SearchableUserSelectProps } from './types'
import { cn } from '@/lib/utils'

export function SearchableUserSelect({
  userList,
  selectedUser,
  setSelectedUser,
}: SearchableUserSelectProps) {
  // Control whether the Popover is open
  const [open, setOpen] = useState(false)
  // Search term
  const [searchTerm, setSearchTerm] = useState('')

  // Filtered user list based on the search term
  const filteredUsers = userList.filter((user) => {
    const { first_name, last_name } = user.profile_metadata
    const displayName = first_name ? `${first_name} ${last_name}` : user.email
    // Also allow searching by email
    return (
      displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  // Display name for the selected user on the button
  const currentUserDisplay = (() => {
    if (!selectedUser) return 'Select a user'
    const found = userList.find(
      (u) => String(u.user_id) === String(selectedUser)
    )
    if (!found) return 'Select a user'
    const { first_name, last_name } = found.profile_metadata
    const displayName = first_name ? `${first_name} ${last_name}` : found.email
    // Show both name and email for clarity
    return `${displayName} (${found.email})`
  })()

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {/* Trigger button */}
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'w-full max-w-md justify-between border-border',
            selectedUser
              ? 'text-foreground border-border'
              : 'text-muted-foreground'
          )}
        >
          {currentUserDisplay}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      {/* Dropdown content */}
      <PopoverContent className="p-0 w-full max-w-md border">
        <Command>
          {/* Search input */}
          <CommandInput
            placeholder="Search user by name or email..."
            value={searchTerm}
            onValueChange={(val) => setSearchTerm(val)}
          />
          <CommandList>
            <CommandEmpty>No users found.</CommandEmpty>
            <CommandGroup>
              {filteredUsers.map((user) => {
                const { first_name, last_name } = user.profile_metadata
                const displayName = first_name
                  ? `${first_name} ${last_name}`
                  : user.email
                return (
                  <CommandItem
                    key={user.user_id ?? user.email}
                    onSelect={() => {
                      // Update state after selection
                      setSelectedUser(String(user.user_id))
                      // Close the dropdown
                      setOpen(false)
                    }}
                  >
                    {/* Show both name and email for clarity */}
                    <span>
                      {displayName}{' '}
                      <span className="text-muted-foreground">
                        ({user.email})
                      </span>
                    </span>
                    <Check
                      className={
                        selectedUser === String(user.user_id)
                          ? 'ml-auto h-4 w-4 opacity-100'
                          : 'ml-auto h-4 w-4 opacity-0'
                      }
                    />
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
