import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  APIFetch,
  MutProps,
  useTokenAndOrgId,
  showErrorToastAndThrow,
  showSuccessToast,
} from '../utils'
import { STATUS_SUCCESS } from '@/constants/status'

// ======== Types ========

export interface ApiKey {
  created_at: string
  id: number
  is_active: boolean
  is_deleted: boolean
  key_hash: string
  key_prefix: string
  last_used_at: string
  name: string
  org_id: number
  user_id: number
  formatted_created_at: string
  formatted_last_used: string
}

// ======== useGetApiKeys ========

export const useGetApiKeys = (orgId: number) => {
  const { token } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['api-keys', token, orgId],
    enabled: !!token && !!orgId,
    queryFn: async () => {
      const result = await APIFetch.post('listApiKeys', {
        token,
        body: { org_id: orgId },
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to fetch API keys. ERROR: ${result.error}`
        )
      }

      return {
        keys: (result.data.keys as ApiKey[]).map((key) => ({
          ...key,
          id: key.id,
          formatted_created_at: new Date(key.created_at).toLocaleDateString(),
          formatted_last_used: key.last_used_at
            ? new Date(key.last_used_at).toLocaleDateString()
            : 'Never',
        })),
        total: result.data.total as number,
      }
    },
  })
}

// ======== useCreateApiKey ========

export interface CreateApiKeyPayload {
  name: string
  org_id: number
}

export const useCreateApiKey = (props?: MutProps) => {
  const { token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: CreateApiKeyPayload) => {
      const result = await APIFetch.post('createApiKey', {
        token,
        body: payload,
        orgId: payload.org_id,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to create API key. ERROR: ${result.error}`
        )
      }

      // Invalidate to refetch the list
      queryClient.invalidateQueries({
        queryKey: ['api-keys', token, payload.org_id],
      })

      showSuccessToast('Successfully created API key')
      return result.data.key as string
    },
    ...props,
  })
}

// ======== useDeleteApiKey ========

export const useDeleteApiKey = (props?: MutProps) => {
  const { token } = useTokenAndOrgId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, orgId }: { id: number; orgId: number }) => {
      const result = await APIFetch.post('deleteApiKey', {
        token,
        body: { id },
        orgId,
      })

      if (result.error || result.status !== STATUS_SUCCESS) {
        showErrorToastAndThrow(
          `Failed to delete API key. ERROR: ${result.error}`
        )
      }

      // Invalidate to refetch the list
      queryClient.invalidateQueries({ queryKey: ['api-keys', token, orgId] })

      showSuccessToast('Successfully deleted API key')
      return result.status
    },
    ...props,
  })
}
