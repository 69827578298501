import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/$orgId/dashboard/')({
  beforeLoad: async ({ params }) => {
    throw redirect({
      to: '/$orgId/dashboard/home',
      params,
    })
  },
  component: () => <></>,
})
