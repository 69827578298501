import { useState, useRef } from 'react'
import { Download, Upload } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Alert, AlertDescription } from '@/components/ui/alert'
import Papa from 'papaparse'
import { UserType, EmailEntry } from './types'

type CSVRow = {
  email: string
  type?: string
}

type OrgUser = {
  email: string
  status: string
}

type EmailValidationResult = {
  validEmails: EmailEntry[]
  existingCount: number
  duplicateCount: number
}

type CsvUploadTabProps = {
  existingUsers: OrgUser[]
  currentEmails: EmailEntry[]
  onEmailsAdd: (emails: EmailEntry[]) => void
}

export const CsvUploadTab = ({
  existingUsers,
  currentEmails,
  onEmailsAdd,
}: CsvUploadTabProps) => {
  const [state, setState] = useState({
    csvUploaded: false,
    csvWarning: '',
  })
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onCsvUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    setState({
      csvUploaded: false,
      csvWarning: '',
    })

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const validEmailRows = (results.data as CSVRow[])
          .filter((row) => row.email && /\S+@\S+\.\S+/.test(row.email))
          .map((row) => ({
            email: row.email.toLowerCase().trim(),
            type: row.type === 'Admin' ? UserType.ADMIN : UserType.USER,
          }))

        const { validEmails, existingCount, duplicateCount } =
          validateAndFilterEmails(validEmailRows, existingUsers, currentEmails)

        const warningParts: string[] = []
        if (existingCount > 0) {
          warningParts.push(
            `${existingCount} email${existingCount === 1 ? ' was' : 's were'} omitted because they are already part of the organization`
          )
        }

        if (duplicateCount > 0) {
          warningParts.push(
            `${duplicateCount} duplicate email${duplicateCount === 1 ? ' was' : 's were'} removed`
          )
        }

        setState({
          csvUploaded: true,
          csvWarning:
            warningParts.join(' and ') + (warningParts.length ? '.' : ''),
        })

        onEmailsAdd(validEmails)

        if (fileInputRef.current) fileInputRef.current.value = ''
      },
    })
  }

  return (
    <div className="space-y-4 py-4">
      <p className="text-sm text-foreground">
        You can download our sample CSV and pre-populate it with multiple email
        addresses so that you can bulk import large numbers of users. Download
        the file below.
      </p>

      <div className="text-sm text-muted-foreground flex justify-center">
        <Download className="w-4 h-4 mr-1" />
        <a href="/sample-invite-users.csv" download className="hover:underline">
          Download sample CSV
        </a>
      </div>

      <div className="flex items-center gap-2">
        <input
          type="file"
          accept=".csv"
          onChange={onCsvUpload}
          className="hidden"
          ref={fileInputRef}
        />
        <Button
          variant="outline"
          onClick={() => fileInputRef.current?.click()}
          className="w-full"
        >
          <Upload className="w-4 h-4 mr-2" />
          Upload CSV
        </Button>
      </div>

      {state.csvUploaded && (
        <p className="text-sm text-primary/70">
          CSV file uploaded successfully!
        </p>
      )}
      {state.csvWarning && (
        <Alert variant="warning" className="mt-2">
          <AlertDescription>{state.csvWarning}</AlertDescription>
        </Alert>
      )}
    </div>
  )
}

// Helper function
const validateAndFilterEmails = (
  newEmails: EmailEntry[],
  existingUsers: OrgUser[],
  currentEmails: EmailEntry[]
): EmailValidationResult => {
  const existingEmailSet = new Set(
    existingUsers
      .filter((user) => user.status === 'active')
      .map((user) => user.email)
  )
  const currentEmailSet = new Set(currentEmails.map((email) => email.email))
  const processedEmails = new Set<string>()
  const validEmails: EmailEntry[] = []
  let existingCount = 0
  let duplicateCount = 0

  newEmails.forEach((entry) => {
    const email = entry.email.toLowerCase().trim()

    if (existingEmailSet.has(email)) {
      existingCount++
    } else if (currentEmailSet.has(email) || processedEmails.has(email)) {
      duplicateCount++
    } else {
      validEmails.push({ email, type: entry.type })
      processedEmails.add(email)
    }
  })

  return {
    validEmails,
    existingCount,
    duplicateCount,
  }
}
