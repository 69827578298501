import { STATUS_SUCCESS } from '@/constants/status'
import { APIFetch, MutProps, useTokenAndOrgId } from '../utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Organization, UserOrgsReturn } from './types'

// Function to get the list of organizations that the user belongs to
export const getUserOrgs = async (token: string): Promise<UserOrgsReturn> => {
  const result = await APIFetch.post('getUserOrgs', { token, orgId: 0 })

  if (result.error) {
    console.error(`Error from API: ${result.error}`)
    throw new Error(`Error from API: ${result.error}`)
  }

  const orgsData = result.data?.orgs

  if (orgsData && Array.isArray(orgsData)) {
    const adaptedData: UserOrgsReturn = {
      organizations: orgsData.map(
        (orgItem): Organization => ({
          orgId: orgItem.org.org_id,
          name: orgItem.org.name,
          userId: orgItem.org_user.user_id,
          role: {
            created_at: orgItem.role.created_at,
            description: orgItem.role.description,
            role_id: orgItem.role.role_id,
            role_name: orgItem.role.role_name,
            updated_at: orgItem.role.updated_at,
          },
          org: {
            created_at: orgItem.org.created_at,
            name: orgItem.org.name,
            org_id: orgItem.org.org_id,
            updated_at: orgItem.org.updated_at,
            metadata: {
              limits: {
                org_total_storage_gb:
                  orgItem.org.metadata.limits.org_total_storage_gb,
                org_per_user_storage_gb:
                  orgItem.org.metadata.limits.org_per_user_storage_gb,
                org_per_user_storage_total_gb:
                  orgItem.org.metadata.limits.org_per_user_storage_total_gb,
                org_per_user_storage_count:
                  orgItem.org.metadata.limits.org_per_user_storage_count,
                org_per_user_server_count:
                  orgItem.org.metadata.limits.org_per_user_server_count,
                org_total_active_server_count:
                  orgItem.org.metadata.limits.org_total_active_server_count,
              },
            },
            profile_metadata: {
              department_name: orgItem.org.profile_metadata.department_name,
              address1: orgItem.org.profile_metadata.address1,
              address2: orgItem.org.profile_metadata.address2,
              city: orgItem.org.profile_metadata.city,
              state: orgItem.org.profile_metadata.state,
              country: orgItem.org.profile_metadata.country,
              zip_code: orgItem.org.profile_metadata.zip_code,
              avatar_preview_url:
                orgItem.org.profile_metadata.avatar_preview_url,
              avatar_s3_path: orgItem.org.profile_metadata.avatar_s3_path,
            },
          },
          profile: {
            first_name: orgItem.profile.first_name,
            last_name: orgItem.profile.last_name,
            avatar_path: orgItem.profile.avatar_path,
            avatar_preview_url: orgItem.profile.avatar_preview_url,
          },
        })
      ),
    }
    return adaptedData
  } else {
    throw new Error('No organizations found for the user')
  }
}

export const useGetUserOrgs = () => {
  const { token } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['get-user-orgs', token],
    enabled: !!token,
    queryFn: async () => getUserOrgs(token),
  })
}

export const ORG_NOT_FOUND_ERROR_MSG = 'Organization not found'
export const useGetOrgUserProfile = (orgId: number) => {
  const { token } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['get-org-user-profile', orgId, token],
    enabled: !!token && !!orgId && orgId > 0,
    queryFn: async () => {
      const userOrgs = await getUserOrgs(token)
      const org = userOrgs.organizations.find((org) => org.orgId === orgId)

      if (!org) {
        throw new Error(ORG_NOT_FOUND_ERROR_MSG)
      }

      return org
    },
  })
}

// MUTATIONS
export const useRequestOTP = (props: MutProps) => {
  return useMutation({
    mutationFn: async (email: string) => {
      const result = await APIFetch.post_public('request-otp', {
        body: { email },
      })

      if (result.error) {
        throw new Error(`Error: ${result.error}`)
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error('Failed to request OTP, please try again.')
      }

      return { success: true }
    },
    ...props,
  })
}

export const useLogin = (props: MutProps) => {
  return useMutation({
    mutationFn: async (body: {
      email: string
      otp: string
      inviteCode?: string
    }) => {
      const { email, otp, inviteCode } = body

      const result = await APIFetch.post_public('login', {
        body: { email, otp, invite_code: inviteCode },
      })

      if (result.error) {
        throw new Error(`Error: ${result.error}`)
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          'Login failed, please check your credentials and try again.'
        )
      }

      const data = result.data

      return data // Ensure that the response contains a token and role
    },
    ...props,
  })
}
