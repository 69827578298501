import { ComponentProps, useEffect, useState, ReactNode } from 'react'
import { Button } from './ui/button'
import { CheckIcon, Copy as CopyIcon } from 'lucide-react'
import { cn } from '@/lib/utils'

type CopyButtonProps = ComponentProps<typeof Button> & {
  label?: string
  textToCopy: string
  icon?: ReactNode
}

export const CopyButton = ({
  className,
  label = 'Copy',
  textToCopy,
  icon,
  onClick,
  ...otherProps
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false)
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [copied])

  return (
    <Button
      className={cn('flex items-center gap-2', className)}
      onClick={(e) => {
        navigator.clipboard.writeText(textToCopy)
        setCopied(true)

        if (onClick) {
          onClick(e)
        }
      }}
      {...otherProps}
    >
      {copied ? (
        <>
          <CheckIcon className="w-4 h-4" />
          Copied!
        </>
      ) : (
        <>
          {icon ?? <CopyIcon className="w-4 h-4" />}
          {label}
        </>
      )}
    </Button>
  )
}
