import { useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import dayjs from 'dayjs'
import {
  useGetOrgServerAnalytics,
  useGetOrgStorageAnalytics,
} from '@/services/home'
import { CalendarPopover } from '@/components/calendar-popover'
import { Spinner } from '@/components/spinner'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'
import { UsersDataTable, usersTableColumns } from './UsersTable'
import {
  Table,
  TableCell,
  TableFooter,
  TableRow,
} from '@/components/ui/table-complex'
import { cn, formatCurrency, getTimeData } from '@/lib/utils'
import { useGetOrgUserProfile } from '@/services/auth'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { getFormattedUnixDate } from '@/helpers/time'

type AnalyticsType = 'server' | 'storage'

export const DashboardAnalyticsTab = () => {
  const { orgId } = useActiveOrgId()
  const { data: org, isLoading: isOrgLoading } = useGetOrgUserProfile(orgId)

  const [range, setRange] = useState<DateRange>({
    from: dayjs().subtract(1, 'week').toDate(),
    to: dayjs().toDate(),
  })

  const [activeTab, setActiveTab] = useState<AnalyticsType>('server')

  const rangeData = {
    start_at: getFormattedUnixDate(range.from, { time: 'startOfDay' }),
    end_at: getFormattedUnixDate(range.to, { time: 'endOfDay' }),
  }

  const { data: serverAnalytics, isLoading: isServerAnalyticsLoading } =
    useGetOrgServerAnalytics(rangeData)

  const { data: storageAnalytics, isLoading: isStorageAnalyticsLoading } =
    useGetOrgStorageAnalytics(rangeData)

  const activeData = activeTab === 'server' ? serverAnalytics : storageAnalytics
  const isActiveLoading =
    activeTab === 'server'
      ? isServerAnalyticsLoading
      : isStorageAnalyticsLoading
  const activeItems = activeData?.items || []

  const totalDuration = useMemo(() => {
    if (!activeData?.total_duration_seconds) return null

    const { hours, minutes, seconds } = getTimeData(
      activeData.total_duration_seconds
    )
    return `${hours}h ${minutes}m ${seconds}s`
  }, [activeData])

  const totalSpent = useMemo(() => {
    if (!activeData?.total_spent) return null
    return formatCurrency(activeData.total_spent / 100)
  }, [activeData])

  const onTabChange = (value: string) => setActiveTab(value as AnalyticsType)

  return (
    <div>
      <div className="flex items-center mb-4 ml-2">
        <h3 className="text-xl font-semibold mr-12">Users' Usage</h3>

        <RadioGroup
          className="flex items-center gap-4 mr-auto"
          defaultValue="server"
          value={activeTab}
          onValueChange={onTabChange}
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="server" id="r1" />
            <Label htmlFor="r1">Server</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="storage" id="r2" />
            <Label htmlFor="r2">Storage</Label>
          </div>
        </RadioGroup>

        <CalendarPopover
          range={range}
          onSelect={setRange}
          shouldDisable={(date: Date) =>
            isOrgLoading || dayjs(date).isBefore(dayjs(org?.org.created_at))
          }
        />
      </div>

      <div className="rounded-md border min-h-[500px]">
        {isActiveLoading ? (
          <div className="flex justify-center items-center h-[500px]">
            <Spinner />
          </div>
        ) : activeData ? (
          <>
            <div
              className={cn(
                'bg-secondary/50',
                !activeItems.length &&
                  'min-h-[400px] flex items-center justify-center'
              )}
            >
              {activeItems.length ? (
                <UsersDataTable
                  columns={usersTableColumns}
                  data={activeItems}
                />
              ) : (
                <p className="text-muted-foreground">
                  No usage data available for this time period
                </p>
              )}
            </div>
            <Table>
              <TableFooter>
                <TableRow>
                  <TableCell className="py-6 pl-12 font-semibold">
                    Total Account Usage
                  </TableCell>
                  <TableCell className="py-6 font-semibold">
                    {totalDuration || '0h 0m 0s'}
                  </TableCell>
                  <TableCell className="py-6 font-semibold">
                    {totalSpent || formatCurrency(0)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </>
        ) : null}
      </div>
    </div>
  )
}
