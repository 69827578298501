import { create } from 'zustand'
import { ServerViewType } from '@/pages/DashboardServers/types'

interface ServerViewState {
  activeTab: ServerViewType
  setActiveTab: (tab: ServerViewType) => void
}

export const useServerViewStore = create<ServerViewState>()((set) => ({
  activeTab: ServerViewType.MY_SERVER,
  setActiveTab: (tab) => set({ activeTab: tab }),
})) 