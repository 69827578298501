import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query'
import { APIFetch, useTokenAndOrgId, processPaginatedData } from '../utils'

// Define specific notification types
export type NotificationTypeEnum =
  | 'spending_limit'
  | 'user_spending_limit'
  | 'add_org_credit'
  // Add other known notification types here as needed
  | string // Keep string for potential unknown types from the API

export interface Notification {
  notification_id: number
  notification_type: NotificationTypeEnum // Use the specific enum type
  org_id: number
  title: string
  detail: string
  is_read: boolean
  is_email_sent: boolean
  metadata: {
    remaining: number
    spent: number
    total: number
    usage: number
  }
  created_at: string
}

const fetchNotifications = async ({
  pageParam = 1,
  orgId,
  token,
  unRead = false,
  pageSize = 5,
}: {
  pageParam?: number
  orgId: number
  token: string
  unRead?: boolean
  pageSize?: number
}): Promise<{
  instances: Notification[]
  hasNextPage: boolean
  total: number
}> => {
  const body = {
    org_id: orgId,
    page_number: pageParam,
    page_size: pageSize,
    ...(unRead && { un_read: true }),
  }

  try {
    const result = await APIFetch.post('listNotifications', {
      token,
      body,
      orgId,
    })

    if (!result || result.status !== 'success' || !result.data) {
      throw new Error('Invalid response from server')
    }

    const { notifications, total } = result.data

    return {
      instances: notifications,
      hasNextPage: notifications.length === pageSize,
      total,
    }
  } catch (error) {
    console.error('Error fetching notifications:', error)
    return {
      instances: [],
      hasNextPage: false,
      total: 0,
    }
  }
}

export const useListNotifications = (pageSize = 5) => {
  const { orgId, token } = useTokenAndOrgId()

  return useInfiniteQuery({
    queryKey: ['list-notifications', orgId, token, pageSize],
    enabled: !!orgId && !!token,
    queryFn: ({ pageParam = 1 }) =>
      fetchNotifications({ pageParam, orgId, token, pageSize }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.hasNextPage ? allPages.length + 1 : undefined,
    select: (data) => {
      const { allInstances: allNotifications, total } =
        processPaginatedData<Notification>(data.pages)
      return { allNotifications, total }
    },
  })
}

export const useTotalUnreadCount = () => {
  const { orgId, token } = useTokenAndOrgId()

  return useQuery<number, Error>({
    queryKey: ['total-unread-count', orgId, token],
    queryFn: async () => {
      if (!orgId || !token) return 0

      const result = await fetchNotifications({
        pageParam: 1,
        orgId,
        token,
        unRead: true,
      })

      return result.total
    },
    enabled: !!orgId && !!token,
    staleTime: 1000 * 60,
  })
}

interface MarkNotificationResponse {
  success: boolean
  error?: string
}

const markNotificationRead = async (
  notificationId: number,
  token: string,
  orgId: number
): Promise<MarkNotificationResponse> => {
  const body = { notification_id: notificationId }

  const result = await APIFetch.post('markNotificationRead', {
    token,
    body,
    orgId,
  })

  if (!result || result.status !== 'success') {
    throw new Error('Failed to mark notification as read')
  }

  return result.data as MarkNotificationResponse
}

export const useMarkNotificationRead = () => {
  const queryClient = useQueryClient()
  const { token, orgId } = useTokenAndOrgId()

  return useMutation({
    mutationFn: async (notificationId: number) =>
      markNotificationRead(notificationId, token, orgId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['list-notifications', orgId, token],
      })
      queryClient.invalidateQueries({
        queryKey: ['total-unread-count', orgId, token],
      })
    },
  })
}

const fetchAllNotifications = async (
  orgId: number,
  token: string,
  unRead: boolean = false
): Promise<Notification[]> => {
  let notifications: Notification[] = []
  let page = 1
  const hasMorePages = true
  while (hasMorePages) {
    const result = await fetchNotifications({
      pageParam: page,
      orgId,
      token,
      unRead,
    })
    notifications = notifications.concat(result.instances)
    if (!result.hasNextPage) break
    page++
  }
  return notifications
}

const markAllNotificationsRead = async (
  token: string,
  orgId: number
): Promise<void> => {
  const unreadNotifications = await fetchAllNotifications(orgId, token, true)
  const unreadIds = unreadNotifications.map((n) => n.notification_id)
  if (unreadIds.length === 0) return

  await Promise.all(
    unreadIds.map((id) => markNotificationRead(id, token, orgId))
  )
}

export const useMarkAllNotificationsRead = (): UseMutationResult<
  void,
  Error,
  void,
  unknown
> => {
  const queryClient = useQueryClient()
  const { token, orgId } = useTokenAndOrgId()

  return useMutation<void, Error, void>({
    mutationFn: async () => {
      await markAllNotificationsRead(token, orgId)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['list-notifications', orgId, token],
      })
      queryClient.invalidateQueries({
        queryKey: ['total-unread-count', orgId, token],
      })
    },
  })
}
