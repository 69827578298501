import { Link, useLocation } from '@tanstack/react-router'
import {
  Archive,
  Key,
  LayoutGrid,
  Unplug,
  User,
  Users,
  FileChartLine,
  DollarSign,
} from 'lucide-react'
import { Button } from '@/components/ui/button'

import { cn } from '@/lib/utils'
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { isRoleAdmin } from '@/services/auth/utils'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'

export const DashboardSidebarContent = () => {
  const { orgId: activeOrgId } = useActiveOrgId()

  const { data } = useActiveOrgProfile()
  const isAdmin = isRoleAdmin(data?.role.role_name)

  const location = useLocation()
  const matchLocation = (path: string) => {
    return location.pathname.trim().includes(path)
  }

  return (
    <SidebarContent className="px-3 py-2">
      <SidebarGroup>
        <SidebarGroupLabel className="text-xs font-medium text-muted-foreground px-3 mb-1">
          Manage
        </SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            {[
              {
                name: 'Dashboard',
                icon: LayoutGrid,
                link: 'home',
              },
              {
                name: 'Servers',
                icon: Unplug,
                link: 'servers',
              },
              {
                name: 'Storage',
                icon: Archive,
                link: 'storage',
              },
              {
                name: 'SSH Key',
                icon: Key,
                link: 'sshkeys',
              },
              // {
              //   name: 'API Keys',
              //   icon: LinkIcon,
              //   link: 'ApiKeys',
              // },
            ].map((item) => {
              if (!item) return null

              return (
                <SidebarMenuItem key={item.link}>
                  <SidebarMenuButton asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className={cn(
                        'justify-start w-full rounded-md h-9 px-3 text-sm transition-colors',
                        matchLocation(item.link)
                          ? 'bg-primary/10 font-medium text-primary hover:bg-primary/15'
                          : 'text-foreground/80 hover:text-foreground hover:bg-muted/60'
                      )}
                    >
                      <Link
                        to={'/$orgId/dashboard/' + item.link}
                        params={{ orgId: activeOrgId?.toString() }}
                      >
                        {item.icon && <item.icon className="h-4 w-4 mr-2 flex-shrink-0" />}
                        {item.name}
                      </Link>
                    </Button>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )
            })}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

      <SidebarGroup className="mt-6">
        <SidebarGroupLabel className="text-xs font-medium text-muted-foreground px-3 mb-1">
          My Account
        </SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            {[
              {
                name: 'Profile',
                icon: User,
                link: 'profile',
              },
              isAdmin
                ? {
                    name: 'User Management',
                    icon: Users,
                    link: 'users',
                  }
                : null,
              isAdmin
                ? {
                    name: 'Audit Log',
                    icon: FileChartLine,
                    link: 'audit',
                  }
                : null,
              {
                name: 'Billing And Invoice',
                icon: DollarSign,
                link: 'Billing',
              },
            ].map((item) => {
              if (!item) return null

              return (
                <SidebarMenuItem key={item.link}>
                  <SidebarMenuButton asChild>
                    <Button
                      asChild
                      variant="ghost"
                      className={cn(
                        'justify-start w-full rounded-md h-9 px-3 text-sm transition-colors',
                        matchLocation(item.link)
                          ? 'bg-primary/10 font-medium text-primary hover:bg-primary/15'
                          : 'text-foreground/80 hover:text-foreground hover:bg-muted/60'
                      )}
                    >
                      <Link
                        to={'/$orgId/dashboard/' + item.link}
                        params={{ orgId: activeOrgId?.toString() }}
                      >
                        {item.icon && <item.icon className="h-4 w-4 mr-2 flex-shrink-0" />}
                        {item.name}
                      </Link>
                    </Button>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )
            })}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      {isAdmin && (
        <SidebarGroup className="mt-6">
          <SidebarGroupLabel className="text-xs font-medium text-muted-foreground px-3 mb-1">
            Settings
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <Button
                    asChild
                    variant="ghost"
                    className={cn(
                      'justify-start w-full rounded-md h-9 px-3 text-sm transition-colors',
                      matchLocation('settings')
                        ? 'bg-primary/10 font-medium text-primary hover:bg-primary/15'
                        : 'text-foreground/80 hover:text-foreground hover:bg-muted/60'
                    )}
                  >
                    <Link
                      to={'/$orgId/dashboard/settings'}
                      params={{ orgId: activeOrgId?.toString() }}
                    >
                      <Users className="h-4 w-4 mr-2 flex-shrink-0" />
                      Organization Settings
                    </Link>
                  </Button>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      )}
    </SidebarContent>
  )
}
