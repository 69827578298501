import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { CreditLimit } from './CreditLimit'
import { ServerLimit } from './ServerLimit'
import { StorageVolume } from './StorageVolume'

export const DashboardSettings = () => {
  const [activeTab, setActiveTab] = useState('credit')

  return (
    <div className="p-6">
      <h1 className="text-3xl font-medium mb-2">User Limit Management</h1>
      <p className="text-muted-foreground dark:text-muted-foreground mb-4">
        Configure resource limits for your organization's users
      </p>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <div className="relative flex justify-start">
          <TabsList className="grid grid-cols-3 w-[500px] mb-6">
            <TabsTrigger value="credit">Credit Limit</TabsTrigger>
            <TabsTrigger value="server">Server Limit</TabsTrigger>
            <TabsTrigger value="storage">Storage Volume</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="credit" className="mt-6">
          <CreditLimit />
        </TabsContent>
        <TabsContent value="server" className="mt-6">
          <ServerLimit />
        </TabsContent>
        <TabsContent value="storage" className="mt-6">
          <StorageVolume />
        </TabsContent>
      </Tabs>
    </div>
  )
}
