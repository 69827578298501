import React from 'react'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import { ApiKeyCard } from './ApikeyCard'
import { CreateApiKeyDialog } from './CreateApiKeyDialog'

export const DashboardApiKeys = () => {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <div className="w-full mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-medium">Your API Keys</h1>
        <Button onClick={() => setOpenDialog(true)}>
          <Plus className="w-4 h-4 mr-2" />
          Create API Key
        </Button>
      </div>

      <ApiKeyCard />

      <CreateApiKeyDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </div>
  )
}
