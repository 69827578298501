import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

interface Options {
  started_at?: string
  hourly_price_cents?: number
}

export const useRunningCostDisplay = (opt: Options): number => {
  const [cost, setCost] = useState(0)

  useEffect(() => {
    if (!opt.started_at || !opt.hourly_price_cents) {
      setCost(0)
      return
    }

    function updateCost() {
      const elapsedSeconds = dayjs().diff(dayjs(opt.started_at), 'second')
      const secondPriceCents = opt.hourly_price_cents! / 3600
      const totalCost = (elapsedSeconds * secondPriceCents) / 100
      setCost(Number(totalCost.toFixed(2)))
    }

    updateCost()
    const timer = setInterval(updateCost, 1000)

    return () => clearInterval(timer)
  }, [opt.started_at, opt.hourly_price_cents])

  return cost
}
