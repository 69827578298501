import { useEffect } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar'
import { useGetUserOrgs } from '@/services/auth'
import { CheckIcon, ChevronRight, PlusCircle } from 'lucide-react'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { SidebarHeader } from '@/components/ui/sidebar'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { Link, useNavigate } from '@tanstack/react-router'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'
import { useTheme } from '@/components/theme-provider'
import { Spinner } from '@/components/spinner'

export const DashboardSidebarHeader = () => {
  const { orgId: activeOrgId } = useActiveOrgId()
  const navigate = useNavigate()
  const { theme } = useTheme()

  return (
    <SidebarHeader className="p-4 flex flex-col border-b border-border/50">
      <div className="flex items-center my-2">
        <img
          src={theme === 'dark' ? '/logo-wide-white.png' : '/logo-wide.png'}
          alt="Inference.ai"
          className="h-8 w-auto dark:py-1 dark:pl-2 mr-auto cursor-pointer transition-opacity hover:opacity-80"
          // on click navigate to home page
          onClick={() => {
            navigate({
              to: '/$orgId/dashboard',
              params: { orgId: activeOrgId?.toString() || '' },
            })
          }}
        />

        <Badge
          variant="outline"
          className="border-primary/60 font-normal text-primary ml-6 cursor-default"
        >
          Beta
        </Badge>
      </div>

      <SidebarMenu className="mt-3">
        <SidebarMenuItem className="min-h-[72px]">
          <SwitchOrg />
        </SidebarMenuItem>
      </SidebarMenu>

      <Button 
        asChild 
        className="w-full mt-4 mb-1 h-10 shadow-sm gap-2 font-medium text-sm"
        size="default"
      >
        <Link
          to="/$orgId/dashboard/servers/new"
          params={{ orgId: activeOrgId?.toString() || '' }}
          className="flex items-center"
        >
          <PlusCircle className="w-4 h-4 mr-1.5" />
          Create New Server
        </Link>
      </Button>
    </SidebarHeader>
  )
}

const SwitchOrg = () => {
  const { data: activeOrg, isLoading, isError } = useActiveOrgProfile()
  const { data: userOrgs, isLoading: isUserOrgsLoading } = useGetUserOrgs()
  const { orgId: activeOrgId, setOrgId } = useActiveOrgId()
  const navigate = useNavigate()

  useEffect(() => {
    if (userOrgs && userOrgs.organizations?.length > 0) {
      const lastAccessedOrgId = localStorage.getItem('lastAccessedOrgId')
      const found = userOrgs.organizations.find(
        (org) => org.orgId.toString() === lastAccessedOrgId
      )

      if (found && found.orgId !== activeOrgId) {
        setOrgId(found.orgId)
        navigate({
          to:
            found.role.role_name === 'Admin'
              ? '/$orgId/dashboard/home'
              : '/$orgId/dashboard/servers/new',
          params: { orgId: found.orgId.toString() },
        })
      }
      else if (!found && !activeOrgId) {
        const first = userOrgs.organizations[0]
        setOrgId(first.orgId)
        navigate({
          to:
            first.role.role_name === 'Admin'
              ? '/$orgId/dashboard/home'
              : '/$orgId/dashboard/servers/new',
          params: { orgId: first.orgId.toString() },
        })
      }
    }
  }, [userOrgs, activeOrgId, setOrgId, navigate])

  if (isError) {
    return (
      <div className="text-destructive text-sm p-3 bg-destructive/10 rounded-md border border-destructive/30">
        Unable to get active org data!
      </div>
    )
  }

  if (isUserOrgsLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-[72px] bg-muted/30 rounded-md border border-border/50">
        <Spinner className="text-primary" />
      </div>
    )
  }

  const onlyOneOrg = userOrgs?.organizations.length === 1

  const btn = () => {
    return (
      <SidebarMenuButton
        disabled={isLoading}
        className={cn(
          'rounded-md min-h-[72px] border w-full',
          'transition-all duration-200 ease-in-out',
          'focus-visible:ring-2 focus-visible:ring-primary/30 focus-visible:outline-none',
          !onlyOneOrg
            ? 'bg-gradient-to-br from-muted/50 to-muted/20 border-border/60 hover:border-primary/50 hover:from-muted/60 hover:to-muted/30 hover:shadow-sm'
            : 'bg-gradient-to-br from-muted/50 to-muted/20 border-border/60'
        )}
      >
        <div className="flex items-center gap-3 px-1 w-full">
          <div className="relative flex-shrink-0">
            <OrgAvatar
              avatarUrl={activeOrg?.org.profile_metadata.avatar_preview_url || ''}
              name={activeOrg?.org.name || ''}
            />
          </div>

          <div className={cn('flex flex-col flex-grow overflow-hidden', !onlyOneOrg ? 'max-w-[80%]' : '')}>
            <div className="font-semibold text-foreground line-clamp-2 text-sm leading-tight break-words">
              {activeOrg?.org.name}
            </div>
            <div className="text-xs text-muted-foreground flex items-center gap-1.5 mt-0.5">
              <span className={cn(
                'inline-block w-1.5 h-1.5 rounded-full flex-shrink-0',
                activeOrg?.role.role_name === 'Admin' ? 'bg-primary' : 'bg-amber-500'
              )}></span>
              <span className="truncate">{activeOrg?.role.role_name}</span>
            </div>
          </div>

          {!onlyOneOrg && (
            <div className="ml-auto flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-muted text-muted-foreground">
              <ChevronRight className="h-4 w-4" />
            </div>
          )}
        </div>
      </SidebarMenuButton>
    )
  }

  if (onlyOneOrg) {
    return btn()
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{btn()}</DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        align="center"
        className="rounded-lg w-[calc(var(--radix-popper-anchor-width)*1.25)] min-w-[300px] border border-border/70 shadow-lg overflow-hidden"
      >
        <div className="px-5 py-3 text-sm font-medium border-b border-border/50 bg-muted/20">
          Switch Organization
        </div>
        <SwitchOrgOptions />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const SwitchOrgOptions = () => {
  const { orgId: activeOrgId, setOrgId } = useActiveOrgId()
  const { data: userOrgs, isLoading } = useGetUserOrgs()

  const navigate = useNavigate()

  const onSwitchOrgSelect = (orgId: string, roleName: string) => {
    localStorage.setItem('lastAccessedOrgId', orgId)
    setOrgId(Number(orgId))

    navigate({
      to:
        roleName === 'Admin'
          ? '/$orgId/dashboard'
          : '/$orgId/dashboard/servers/new',
      params: { orgId },
    })
  }

  if (isLoading) {
    return <Spinner className="mx-auto my-4" />
  }

  return (
    <div className="py-3">
      {userOrgs?.organizations.map((org) => (
        <DropdownMenuItem
          className={cn(
            'flex items-center text-left gap-3.5 py-3 px-5 mx-2 my-1.5 rounded-md transition-colors duration-150',
            activeOrgId === org.orgId 
              ? 'bg-primary/10 text-primary border border-primary/20'
              : 'hover:bg-muted/40'
          )}
          key={org.orgId}
          onSelect={() =>
            onSwitchOrgSelect(org.orgId.toString(), org.role.role_name)
          }
        >
          <div className="relative flex-shrink-0">
            <OrgAvatar
              avatarUrl={org.org.profile_metadata.avatar_preview_url || ''}
              name={org.org.name}
            />
          </div>

          <div className="flex flex-col flex-grow overflow-hidden max-w-[80%]">
            <span className="font-medium text-sm leading-tight line-clamp-2 break-words">{org.org.name}</span>
            <div className="text-xs flex items-center gap-1.5 mt-1.5 text-muted-foreground">
              <span className={cn(
                'inline-block w-1.5 h-1.5 rounded-full flex-shrink-0',
                org.role.role_name === 'Admin' ? 'bg-primary' : 'bg-amber-500'
              )}></span>
              <span className="truncate">{org.role.role_name}</span>
            </div>
          </div>

          {org.orgId === activeOrgId && (
            <CheckIcon className="w-5 h-5 text-primary ml-auto flex-shrink-0" />
          )}
        </DropdownMenuItem>
      ))}
    </div>
  )
}

const OrgAvatar = ({
  avatarUrl,
  name,
}: {
  avatarUrl: string
  name: string
}) => {
  if (!avatarUrl) {
    return (
      <Avatar className="h-9 w-9 border border-border/50 bg-muted/30 flex-shrink-0">
        <AvatarFallback className="font-medium text-muted-foreground bg-muted/40">
          {name && name[0].toUpperCase()}
        </AvatarFallback>
      </Avatar>
    )
  }

  return (
    <div className="w-9 h-9 rounded-full border border-border/50 bg-muted/30 flex-shrink-0 overflow-hidden">
      <img 
        src={avatarUrl} 
        alt={name} 
        className="w-full h-full object-cover" 
        style={{ aspectRatio: '1/1' }}
      />
    </div>
  )
}
