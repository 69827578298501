import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useUpdateOrgUserRole } from '@/services/user-management'
import { useUserStore } from '@/store/UserStore'
import { cn } from '@/lib/utils'
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { useState } from 'react'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

const roleOptions = {
  Admin: 1,
  User: 2,
} as const

type RoleType = keyof typeof roleOptions

const ChangeUserRoleSchema = z.object({
  role: z.enum(['Admin', 'User'] as const),
})

type ChangeUserRoleFormValues = z.infer<typeof ChangeUserRoleSchema>

type ChangeUserRoleProps = {
  userId: string
  userEmail: string
  role: RoleType
  disabled?: boolean
}

export const ChangeUserRole = ({
  userId,
  userEmail,
  role: initialRole,
  disabled,
}: ChangeUserRoleProps) => {
  const myEmail = useUserStore((state) => state.user?.email)
  const isDisabled = myEmail === userEmail || disabled

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [pendingRole, setPendingRole] = useState<RoleType | null>(null)

  const form = useForm<ChangeUserRoleFormValues>({
    resolver: zodResolver(ChangeUserRoleSchema),
    defaultValues: {
      role: initialRole,
    },
  })

  const { mutate, isPending } = useUpdateOrgUserRole({
    onSuccess: () => {
      if (pendingRole) {
        form.setValue('role', pendingRole)
      }
      setPendingRole(null)
      setIsConfirmOpen(false)
    },
    onError: () => {
      setPendingRole(null)
      setIsConfirmOpen(false)
    },
  })

  const onRoleChange = (newRole: RoleType) => {
    const currentRole = form.getValues('role')
    if (newRole === currentRole) return

    setPendingRole(newRole)
    setIsConfirmOpen(true)
  }

  const onConfirmChangeRole = () => {
    if (!pendingRole) return
    mutate({ userId: Number(userId), roleId: roleOptions[pendingRole] })
  }

  const onCancelChange = () => {
    setPendingRole(null)
    setIsConfirmOpen(false)
  }

  return (
    <Form {...form}>
      <form className="w-fit">
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select
                  value={field.value}
                  onValueChange={onRoleChange}
                  disabled={isDisabled || isPending}
                >
                  <SelectTrigger
                    className={cn(
                      'border-primary text-primary',
                      'focus:ring-primary/20',
                      'hover:bg-primary/5',
                      isPending && 'opacity-50'
                    )}
                  >
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Admin">Admin</SelectItem>
                    <SelectItem value="User">User</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />

        <ConfirmationDialog
          open={isConfirmOpen}
          onOpenChange={setIsConfirmOpen}
          title="Change user role?"
          description={
            <>
              This action will change the role of user with email:{' '}
              <b className="text-foreground/90">{userEmail}</b> from{' '}
              <b className="text-foreground/90">{form.getValues('role')}</b> to{' '}
              <b className="text-foreground/90">{pendingRole}</b>.
            </>
          }
          buttons={{
            confirm: {
              label: isPending ? 'Changing role...' : 'Change role',
              isLoading: isPending,
            },
            cancel: {
              label: 'Cancel',
            },
          }}
          onConfirm={onConfirmChangeRole}
          onCancel={onCancelChange}
        />
      </form>
    </Form>
  )
}
