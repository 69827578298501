import { useState } from 'react'
import {
  CircleDollarSign,
  AlertCircle,
  Info,
  Check,
  Bell,
  AlertTriangle,
} from 'lucide-react'

// UI Components
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Avatar } from '@/components/ui/avatar'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Spinner } from '@/components/spinner'

// Services & Utils
import {
  useListNotifications,
  useMarkNotificationRead,
  useTotalUnreadCount,
  useMarkAllNotificationsRead,
} from '@/services/notification'
import { cn } from '@/lib/utils'

// Local components
import { DefaultNotification } from './DefaultNotification'
import { TopUpNotification } from './TopUpNotification'
import { SpendingLimitNotification } from './SpendingLimitNotification'

// Types
import {
  NotificationTypeIconProps,
  NotificationItemProps,
  MarkAsReadButtonProps,
} from './types'

// MAIN EXPORTED COMPONENT
export const NotificationsTab = () => {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListNotifications(20)

  const markNotificationRead = useMarkNotificationRead()
  const markAllNotificationsReadMutation = useMarkAllNotificationsRead()

  const [loadingId, setLoadingId] = useState<number | null>(null)
  const [markAllLoading, setMarkAllLoading] = useState(false)

  const allNotifications = data?.allNotifications || []
  const { data: totalUnreadCount = 0 } = useTotalUnreadCount()

  if (isLoading) return <Spinner className="mx-auto my-10" />

  if (isError) {
    return (
      <Alert variant="destructive" className="mx-auto max-w-2xl my-10">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>Failed to load notifications.</AlertDescription>
      </Alert>
    )
  }

  if (allNotifications.length === 0) {
    return (
      <Alert className="mx-auto max-w-2xl my-10">
        <Info className="h-4 w-4" />
        <AlertTitle>No Notifications</AlertTitle>
        <AlertDescription>
          You don't have any notifications at this time.
        </AlertDescription>
      </Alert>
    )
  }

  const onMarkAsRead = (notificationId: number) => {
    setLoadingId(notificationId)
    markNotificationRead.mutate(notificationId, {
      onSuccess: () => {
        const notification = allNotifications.find(
          (n) => n.notification_id === notificationId
        )
        if (notification) notification.is_read = true
        setLoadingId(null)
      },
      onError: () => setLoadingId(null),
    })
  }

  const onMarkAllAsRead = () => {
    setMarkAllLoading(true)
    markAllNotificationsReadMutation.mutate(undefined, {
      onSuccess: () => setMarkAllLoading(false),
      onError: () => setMarkAllLoading(false),
    })
  }

  return (
    <div className="max-w-3xl mt-6">
      <header className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-bold">Notifications</h2>
        <div className="flex items-center gap-4">
          {totalUnreadCount > 0 && (
            <Badge variant="destructive" className="text-sm px-3 py-1">
              {totalUnreadCount} unread
            </Badge>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={onMarkAllAsRead}
            disabled={markAllLoading || totalUnreadCount === 0}
            className="flex items-center gap-2"
          >
            {markAllLoading ? (
              <Spinner className="h-3 w-3" />
            ) : (
              <Check className="h-3 w-3" />
            )}
            <span>{markAllLoading ? 'Marking...' : 'Mark all as read'}</span>
          </Button>
        </div>
      </header>

      <div className="space-y-4">
        {allNotifications.map((notification) => (
          <NotificationItem
            key={notification.notification_id}
            notification={notification}
            isLoading={loadingId === notification.notification_id}
            onMarkAsRead={onMarkAsRead}
          />
        ))}
      </div>

      {isFetchingNextPage ? (
        <div className="text-center py-4">
          <Spinner className="mx-auto" />
        </div>
      ) : hasNextPage ? (
        <div className="text-center py-4">
          <Button
            variant="outline"
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            Load 20 more
          </Button>
        </div>
      ) : (
        <div className="text-center py-4">
          <p className="text-xs text-foreground/60">End of notifications</p>
        </div>
      )}
    </div>
  )
}

// Child components
const NotificationItem = ({
  notification,
  isLoading,
  onMarkAsRead,
}: NotificationItemProps) => {
  // Get accent color based on notification type
  const getAccentColor = () => {
    switch (notification.notification_type) {
      case 'spending_limit':
      case 'user_spending_limit':
        return 'border-l-4 border-destructive'
      case 'add_org_credit':
        return 'border-l-4 border-emerald-600'
      default:
        return 'border-l-4 border-primary'
    }
  }

  return (
    <div className="relative">
      {!notification.is_read && (
        <div className="absolute top-3 right-3 w-2 h-2 rounded-full bg-primary animate-pulse z-10" />
      )}
      <Card
        className={cn(
          'overflow-hidden transition-all',
          notification.is_read
            ? 'opacity-60 bg-background/50 grayscale'
            : 'bg-background shadow-md',
          getAccentColor()
        )}
      >
        {notification.notification_type === 'spending_limit' ? (
          <SpendingLimitNotification
            notification={notification}
            isLoading={isLoading}
            onMarkAsRead={onMarkAsRead}
          />
        ) : notification.notification_type === 'user_spending_limit' ? (
          <SpendingLimitNotification
            notification={notification}
            isLoading={isLoading}
            onMarkAsRead={onMarkAsRead}
          />
        ) : notification.notification_type === 'add_org_credit' ? (
          <TopUpNotification
            notification={notification}
            isLoading={isLoading}
            onMarkAsRead={onMarkAsRead}
          />
        ) : (
          <DefaultNotification
            notification={notification}
            isLoading={isLoading}
            onMarkAsRead={onMarkAsRead}
          />
        )}
      </Card>
    </div>
  )
}

export const NotificationTypeIcon = ({ type }: NotificationTypeIconProps) => {
  const iconMap = {
    spending_limit: {
      bg: 'bg-destructive/10 dark:bg-destructive/20',
      icon: <CircleDollarSign className="h-4 w-4 text-destructive" />,
    },
    user_spending_limit: {
      bg: 'bg-destructive/10 dark:bg-destructive/20',
      icon: <CircleDollarSign className="h-4 w-4 text-destructive" />,
    },
    add_org_credit: {
      bg: 'bg-emerald-600/20 dark:bg-emerald-600/30',
      icon: <CircleDollarSign className="h-4 w-4 text-emerald-600" />,
    },
    add_user_credit: {
      bg: 'bg-emerald-600/20 dark:bg-emerald-600/30',
      icon: <CircleDollarSign className="h-4 w-4 text-emerald-600" />,
    },
    shutdown_user_resource: {
      bg: 'bg-amber-500/10 dark:bg-amber-500/20',
      icon: <AlertTriangle className="h-4 w-4 text-destructive" />,
    },
    // Default notification (will be used for any type not explicitly defined)
    default: {
      bg: 'bg-secondary/10 dark:bg-secondary/20',
      icon: <Bell className="h-4 w-4 text-secondary" />,
    },
  }

  // Try to find the specific icon for this notification type
  // If not found, use the default notification icon
  const { bg, icon } = iconMap[type as keyof typeof iconMap] || iconMap.default

  return (
    <Avatar size="sm" className={cn('flex items-center justify-center', bg)}>
      {icon}
    </Avatar>
  )
}

export const MarkAsReadButton = ({
  isLoading,
  onMarkAsRead,
}: MarkAsReadButtonProps) => (
  <Button
    variant="ghost"
    size="sm"
    onClick={onMarkAsRead}
    disabled={isLoading}
    className="text-xs"
  >
    {isLoading ? (
      <Spinner className="h-3 w-3 mr-2" />
    ) : (
      <Check className="h-3 w-3 mr-2" />
    )}
    Mark as read
  </Button>
)

export const FormattedNotificationContent = ({
  content,
}: {
  content: string
}) => {
  // Highlight dollar amounts and percentages
  const formattedContent = content
    .replace(
      /(\$[\d,]+\.?\d*)/g,
      '<span class="font-medium text-primary">$1</span>'
    )
    .replace(
      /(\d+\.?\d*\s*%)/g,
      '<span class="font-medium text-destructive">$1</span>'
    )

  return (
    <p
      className="text-sm text-foreground/80 leading-relaxed"
      dangerouslySetInnerHTML={{ __html: formattedContent }}
    />
  )
}
