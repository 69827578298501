import React, { useState, useEffect } from 'react'
import { ManageServerHeader } from './Header'
import { ServerCard } from './ServerCard'
import { useListServerInstances } from '@/services/server'
import { ServerViewSortKeys, ServerViewType } from './types'
import { Button } from '@/components/ui/button'
import { Link, useNavigate, useRouterState } from '@tanstack/react-router'
import { Plus, ServerOff, AlertCircle } from 'lucide-react'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { Virtuoso } from 'react-virtuoso'
import { Spinner } from '@/components/spinner'
import { ServerInstance } from '@/services/server/types'
import { useServerViewStore } from '@/store/ServerViewStore'

// Extended interface to include instance_id
type ServerInstanceWithId = ServerInstance & {
  instance_id: number
}

export const DashboardServers = () => {
  const { orgId: activeOrgId } = useActiveOrgId()
  const navigate = useNavigate()

  // Get state from router if available
  const routerState = useRouterState({
    select: (state) => state.location.state,
  })

  // Use Zustand store for tab state management
  const { activeTab, setActiveTab } = useServerViewStore()

  const [serverSort, onServerSortChange] = useState(
    ServerViewSortKeys.STARTED_AT_DESC
  )
  const [searchTerm, setSearchTerm] = useState('')

  // Check for tab in state when component mounts or state changes
  useEffect(() => {
    if (
      routerState &&
      typeof routerState === 'object' &&
      'serverViewType' in routerState &&
      routerState.serverViewType
    ) {
      setActiveTab(routerState.serverViewType as ServerViewType)
    }
  }, [routerState, setActiveTab])

  const {
    data, // data contains { allInstances, total }
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListServerInstances({
    showAllUsers: activeTab !== ServerViewType.MY_SERVER,
    sort: serverSort,
    searchText: searchTerm,
    refetchInterval: 10000,
    staleTime: 5000,
  })

  const allServers: ServerInstanceWithId[] =
    (data?.allInstances as ServerInstanceWithId[]) || []
  const totalServers = data?.total || 0
  const currentCount = allServers.length

  return (
    <div className="pb-12 mt-4">
      <ManageServerHeader
        serverViewType={activeTab}
        onServerViewChange={setActiveTab}
        serverViewSortType={serverSort}
        onServerViewSortChange={onServerSortChange}
        onSearchChange={setSearchTerm}
      />

      {!isLoading && !isError && totalServers > 0 && (
        <div className="text-left ml-4 my-3 text-sm text-muted-foreground">
          Showing {currentCount} of {totalServers} servers
        </div>
      )}

      {isLoading ? (
        <div className="flex items-center justify-center mt-32">
          <Spinner className="h-10 w-10" />
        </div>
      ) : isError ? (
        <div className="flex flex-col items-center justify-center gap-4 mt-28 text-center">
          <AlertCircle className="w-12 h-12 text-destructive/70" />
          <h3 className="text-2xl font-medium">Unable to load servers</h3>
          <p className="text-muted-foreground max-w-md">
            There was an error loading your server instances. Please try
            refreshing the page.
          </p>
        </div>
      ) : allServers.length === 0 ? (
        <div className="flex flex-col items-center gap-10 mt-20">
          {searchTerm.length > 0 ? (
            <>
              <h3 className="text-2xl font-medium">No search results</h3>
              <p className="text-muted-foreground">
                No servers match your search term "{searchTerm}"
              </p>
            </>
          ) : activeTab === ServerViewType.MY_SERVER ? (
            <>
              <h3 className="text-2xl font-medium">
                You have no servers running
              </h3>
              <ServerOff className="w-20 h-20 text-muted-foreground/50" />
              <p className="text-muted-foreground max-w-md text-center">
                Create your first server instance to get started with cloud
                computing resources
              </p>
            </>
          ) : (
            <>
              <h3 className="text-2xl font-medium">
                No servers are currently running
              </h3>
              <ServerOff className="w-20 h-20 text-muted-foreground/50" />
              <p className="text-muted-foreground max-w-md text-center">
                None of the users in this organization have any active server
                instances
              </p>
            </>
          )}

          <Button asChild size="lg" className="gap-2 mt-4">
            <Link
              to="/$orgId/dashboard/servers/new"
              params={{ orgId: activeOrgId.toString() }}
            >
              <Plus className="w-5 h-5" /> Create New Server
            </Link>
          </Button>
        </div>
      ) : (
        <div className="mt-6">
          <Virtuoso
            useWindowScroll
            data={allServers}
            endReached={() => {
              if (hasNextPage) {
                fetchNextPage()
              }
            }}
            itemContent={(_index, server) => (
              <div key={server.instance_id} className="mb-4">
                <ServerCard
                  key={server.instance_id}
                  metadata={{
                    name: server.instance_name,
                    status: server.status,
                    baseImage: server.template_name,
                    createdBy: server.email,
                    createdAt: server.created_at,
                    startedAt: server.started_at,
                  }}
                  pricing={{
                    price: server.price,
                  }}
                  instanceId={server.instance_id}
                  user_metadata={server.user_metadata}
                  onViewDetails={() => {
                    navigate({
                      to: '/$orgId/dashboard/servers/$serverId',
                      params: {
                        orgId: activeOrgId.toString(),
                        serverId: String(server.instance_id),
                      },
                    })
                  }}
                />
              </div>
            )}
            components={{
              Footer: () => (
                <div className="text-center py-6">
                  {isFetchingNextPage ? (
                    <div className="flex items-center justify-center gap-2">
                      <Spinner className="h-4 w-4" />
                      <span className="text-sm text-muted-foreground">
                        Loading more servers...
                      </span>
                    </div>
                  ) : hasNextPage ? null : (
                    <p className="text-sm text-muted-foreground">End of list</p>
                  )}
                </div>
              ),
            }}
          />
        </div>
      )}
    </div>
  )
}
