import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { isRoleAdmin } from '@/services/auth/utils'
import { DashboardHomeAdmin } from './Admin/Dashboard'
import { DashboardHomeUser } from './User/Dashboard'
import { Spinner } from '@/components/spinner'

export const DashboardHome = () => {
  const { data, isLoading } = useActiveOrgProfile()
  const isAdmin = isRoleAdmin(data?.role?.role_name)

  if (isLoading) {
    return <Spinner />
  }

  if (isAdmin) {
    return <DashboardHomeAdmin />
  }

  return <DashboardHomeUser />
}
