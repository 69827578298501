import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useQueryClient } from '@tanstack/react-query'

interface DeleteApiKeyDialogProps {
  apiKeyName: string
  apiKeyValue: string
  open: boolean
  onClose: () => void
  onConfirmDelete: () => void
}

export const DeleteApiKeyDialog = ({
  apiKeyName,
  apiKeyValue,
  open,
  onClose,
  onConfirmDelete,
}: DeleteApiKeyDialogProps) => {
  const queryClient = useQueryClient()

  const handleConfirm = () => {
    onConfirmDelete()
    onClose()
    queryClient.invalidateQueries({ queryKey: ['list-api-keys'] })
  }

  return (
    <>
      <ConfirmationDialog
        open={open}
        onOpenChange={onClose}
        title="Delete API Key"
        description={`You are deleting the API key "${apiKeyName}". This action is irreversible!`}
        buttons={{
          confirm: {
            label: 'Delete',
            isDestructive: true,
          },
          cancel: {
            label: 'Cancel',
          },
        }}
        confirmation={{
          text: apiKeyName,
          prompt:
            "If you want to continue, please type the API key's name below to confirm:",
        }}
        info={{
          warningPoints: [
            'This action is irreversible.',
            'Applications using this API key will no longer be able to authenticate.',
          ],
          alert: {
            title: 'API Key Details',
            description: (
              <div className="flex flex-col ">
                <p className="font-semibold">Name: {apiKeyName}</p>
                <p className="font-mono text-sm">Key: {apiKeyValue}</p>
              </div>
            ),
          },
        }}
        onConfirm={handleConfirm}
      />
    </>
  )
}
