import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { TriangleAlert } from 'lucide-react'
import { useCreateApiKey } from '@/services/apiKey'
import { useTokenAndOrgId } from '@/services/utils'
import { toast } from 'react-toastify'
import { Spinner } from '@/components/spinner'
import type { CreateApiKeyPayload } from '@/services/apiKey'
import { CopyButton } from '@/components/copy-button'

type CreateApiKeyDialogProps = {
  open: boolean
  onClose: () => void
}

const CreateApiKeySchema = z.object({
  name: z.string().min(1, { message: 'API key name is required' }),
})

type FormData = z.infer<typeof CreateApiKeySchema>

const CreateApiKeyForm = ({
  onSuccess,
}: {
  onSuccess: (key: string, formData: FormData) => void
}) => {
  const { orgId } = useTokenAndOrgId()

  const form = useForm<FormData>({
    resolver: zodResolver(CreateApiKeySchema),
    defaultValues: {
      name: '',
    },
  })

  const { mutateAsync, isPending } = useCreateApiKey({
    onSuccess: (data) => {
      onSuccess(data as string, form.getValues())
    },
    onError: (error: Error) => {
      toast.error(error.message)
    },
  })

  const onSubmit = async (data: FormData) => {
    await mutateAsync({
      name: data.name,
      org_id: orgId,
    } as CreateApiKeyPayload)
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle className="text-lg font-semibold">
          Create New API Key
        </DialogTitle>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Key Name</FormLabel>
                <FormControl>
                  <Input placeholder="Name the new key..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <DialogFooter>
            <div className="flex justify-between items-center w-full mt-2">
              <DialogClose asChild>
                <Button variant="outline">Cancel</Button>
              </DialogClose>
              <Button type="submit" disabled={isPending}>
                {isPending ? (
                  <>
                    <Spinner /> Generating...
                  </>
                ) : (
                  'Generate Key'
                )}
              </Button>
            </div>
          </DialogFooter>
        </form>
      </Form>
    </>
  )
}

// Component for displaying the generated API key
const ApiKeyDisplay = ({
  apiKey,
  keyName,
  generatedAt,
  onDone,
}: {
  apiKey: string
  keyName: string
  generatedAt: Date | null
  onDone: () => void
}) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey)
    toast.success('API key copied to clipboard.')
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle className="text-lg font-semibold">
          Your New API Key: {keyName}
        </DialogTitle>
        <p
          style={{
            color: 'hsl(var(--destructive))',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <TriangleAlert />
          Copy this key now. You won't see it again.
        </p>
      </DialogHeader>

      <div className="space-y-6 mt-4">
        <div className="flex items-center w-full">
          <p className="flex-1 rounded-sm p-3 font-mono max-w-[500px] truncate text-sm bg-primary/10">
            {apiKey}
          </p>
          <CopyButton
            textToCopy={apiKey}
            className="gap-2 ml-4"
            onCopy={handleCopyToClipboard}
          />
        </div>

        <p className="text-sm text-muted-foreground">
          Generated at: {generatedAt ? generatedAt.toLocaleString() : ''}
        </p>
      </div>

      <DialogFooter className="flex items-center w-full mt-4">
        <DialogClose asChild>
          <Button variant="outline" onClick={onDone}>
            Done
          </Button>
        </DialogClose>
      </DialogFooter>
    </>
  )
}

// Main dialog component managing state
export const CreateApiKeyDialog = ({
  open,
  onClose,
}: CreateApiKeyDialogProps) => {
  const [apiKey, setApiKey] = useState<string | null>(null)
  const [generatedAt, setGeneratedAt] = useState<Date | null>(null)
  const [keyName, setKeyName] = useState<string>('')

  const handleReset = () => {
    setApiKey(null)
    setKeyName('')
    setGeneratedAt(null)
    onClose()
  }

  const handleApiKeyCreated = (key: string, formData: FormData) => {
    setApiKey(key)
    setGeneratedAt(new Date())
    setKeyName(formData.name)
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={`${apiKey ? 'w-full max-w-3xl' : 'max-w-lg'} p-8 rounded-lg`}
      >
        {!apiKey ? (
          <CreateApiKeyForm onSuccess={handleApiKeyCreated} />
        ) : (
          <ApiKeyDisplay
            apiKey={apiKey}
            keyName={keyName}
            generatedAt={generatedAt}
            onDone={handleReset}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
