import React from 'react'
import { cn } from '@/lib/utils'

export type SpecCardProps = {
  icon: React.ReactNode
  title: string
  items: Array<{
    label: string
    value: string
  }>
  variant?: 'default' | 'gpu'
  minHeight?: string
}

export const SpecCard = ({
  icon,
  title,
  items,
  variant = 'default',
  minHeight = 'min-h-[100px]',
}: SpecCardProps) => (
  <div
    className={cn(
      'space-y-2 rounded-lg p-2.5 border transition-all',
      minHeight,
      variant === 'default' && ['bg-foreground/5', 'border-border/40'],
      variant === 'gpu' && ['bg-primary/5', 'border-primary/20']
    )}
  >
    <div
      className={cn(
        'flex items-center gap-2 border-b pb-2',
        variant === 'default' ? 'border-border/40' : 'border-primary/10'
      )}
    >
      <div
        className={cn(
          'rounded-md p-1',
          variant === 'default' ? 'bg-muted/90' : 'bg-primary/20'
        )}
      >
        {icon}
      </div>
      <span
        className={cn(
          'text-sm font-semibold',
          variant === 'gpu' && 'text-primary'
        )}
      >
        {title}
      </span>
    </div>
    <div className="space-y-1.5">
      {items.map(({ label, value }) => (
        <div key={label} className="flex items-center justify-between gap-3">
          <span className="text-xs text-muted-foreground">{label}</span>
          <span
            className={cn(
              'text-sm font-medium px-2 py-0.5 rounded-md border',
              variant === 'default'
                ? 'bg-background border-border/50'
                : 'bg-primary/5 border-primary/20'
            )}
          >
            {value}
          </span>
        </div>
      ))}
    </div>
  </div>
)
