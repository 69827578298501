import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useState, useEffect } from 'react'

dayjs.extend(relativeTime)

export const getExactElapsedTime = (started_at: string) => {
  const seconds = dayjs().diff(dayjs(started_at), 'second')

  const days = Math.floor(seconds / (24 * 3600))
  const hours = Math.floor((seconds % (24 * 3600)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const parts = []

  if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`)
  if (hours > 0) parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`)
  if (minutes > 0) parts.push(`${minutes} ${minutes === 1 ? 'min' : 'mins'}`)
  parts.push(
    `${remainingSeconds} ${remainingSeconds === 1 ? 'second' : 'seconds'}`
  )

  return parts.join(' ')
}

export const useElapsedTime = (started_at?: string) => {
  const [elapsed, setElapsed] = useState('')

  useEffect(() => {
    if (!started_at) {
      setElapsed('')
      return
    }

    function updateElapsed() {
      setElapsed(getExactElapsedTime(started_at!))
    }

    updateElapsed()
    const timer = setInterval(updateElapsed, 1000)

    return () => clearInterval(timer)
  }, [started_at])

  return elapsed
}

export const formatDate = (date: string) => {
  return dayjs(date).format('MMMM DD, YYYY')
}

// range picker time shift, so that we don't lose hours of data in between the days
export const getFormattedUnixDate = (
  date: Date | undefined,
  options?: {
    time: 'startOfDay' | 'endOfDay'
  }
) => {
  if (!date) return 0

  let d = dayjs.utc(date)

  if (options?.time === 'startOfDay') {
    d = d.startOf('day')
  } else if (options?.time === 'endOfDay') {
    d = d.endOf('day')
  }

  return d.unix()
}
