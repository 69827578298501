import { ORG_NOT_FOUND_ERROR_MSG, useGetOrgUserProfile } from '@/services/auth'
import { useEffect } from 'react'

export const useIsValidOrg = ({
  orgId,
  onInvalidOrgId,
}: {
  orgId: number
  onInvalidOrgId: () => void
}) => {
  const { isLoading, error, isError } = useGetOrgUserProfile(orgId || 0)

  useEffect(() => {
    if (isError && error.message === ORG_NOT_FOUND_ERROR_MSG) {
      onInvalidOrgId()
    }
  }, [isError, error, onInvalidOrgId])

  return { isLoading }
}
