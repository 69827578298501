import { useState, KeyboardEvent, ChangeEvent } from 'react'
import { X } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { EmailEntry, UserType } from './types'

type ManualEntryTabProps = {
  onEmailAdd: (email: EmailEntry) => { success: boolean; message: string }
}

export const ManualEntryTab = ({ onEmailAdd }: ManualEntryTabProps) => {
  const [state, setState] = useState({
    inputValue: '',
    userType: UserType.USER,
    errorMessage: '',
  })

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      inputValue: e.target.value,
      errorMessage: '',
    }))
  }

  const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onAddClick()
    }
  }

  const onUserTypeChange = (value: string) => {
    setState((prev) => ({ ...prev, userType: value as UserType }))
  }

  const onAddClick = () => {
    if (!state.inputValue.trim()) return

    const result = onEmailAdd({
      email: state.inputValue.trim(),
      type: state.userType,
    })

    if (result.success) {
      setState((prev) => ({ ...prev, inputValue: '', errorMessage: '' }))
    } else {
      setState((prev) => ({ ...prev, errorMessage: result.message }))
    }
  }

  const onClearInput = () => {
    setState((prev) => ({ ...prev, inputValue: '', errorMessage: '' }))
  }

  return (
    <div className="space-y-4 py-4">
      <div className="flex items-center gap-2">
        <div className="relative flex-grow">
          <Input
            type="email"
            placeholder="Enter email addresses"
            value={state.inputValue}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
            className="pr-20"
          />
          {state.inputValue && (
            <button
              onClick={onClearInput}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-foreground/40 hover:text-foreground/60"
            >
              <X className="h-4 w-4" />
            </button>
          )}
        </div>
        <Select
          defaultValue={UserType.USER}
          value={state.userType}
          onValueChange={onUserTypeChange}
        >
          <SelectTrigger className="w-[100px]">
            <SelectValue placeholder="Select role" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={UserType.USER}>User</SelectItem>
            <SelectItem value={UserType.ADMIN}>Admin</SelectItem>
          </SelectContent>
        </Select>
        <Button onClick={onAddClick}>Add</Button>
      </div>
      {state.errorMessage && (
        <p className="text-destructive text-sm flex items-center gap-1">
          <span className="bg-destructive/10 text-destructive-foreground rounded-full w-4 h-4 flex items-center justify-center text-xs">
            !
          </span>
          {state.errorMessage}
        </p>
      )}
    </div>
  )
}
