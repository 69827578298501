import React from 'react'
import { BalanceCard, CreditCard } from './BalanceCard/BalanceCard'
import { TableDemo } from './InvoiceTable/InvoiceTable'
import { OrgUserInvoicesTable } from './InvoiceTable/UserInvoiceTable'
import { isRoleAdmin } from '@/services/auth/utils'
import { useGetOrgUserProfile } from '@/services/auth'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { User } from 'lucide-react'
import { useState } from 'react'

export const DashboardBilling = () => {
  const { orgId } = useActiveOrgId()
  const { data: orgProfile } = useGetOrgUserProfile(orgId)
  const [activeCreditCardTopUp, setActiveCreditCardTopUp] = useState(false)

  const isAdminUser = isRoleAdmin(orgProfile?.role?.role_name)

  if (isAdminUser) {
    return (
      <div>
        <h1 className="text-3xl font-medium mb-4">Billing And Invoice</h1>
        <BalanceCard userId={orgProfile.userId} />
        <div className="mt-6">
          <TableDemo />
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="text-3xl font-medium mb-4">Billing And Invoice</h1>
      {orgProfile?.userId ? (
        <>
          <div className="max-w-[500px]">
            <CreditCard
              type="personal"
              title="Personal Credits"
              icon={User}
              userId={Number(orgProfile.userId)}
              isActive={activeCreditCardTopUp}
              onOpenChange={setActiveCreditCardTopUp}
            />
          </div>
          <div className="mt-6">
            <OrgUserInvoicesTable userId={orgProfile.userId} />
          </div>
        </>
      ) : (
        <p>Loading user info or no valid user found.</p>
      )}
    </div>
  )
}
