import { APIFetch, useTokenAndOrgId, processPaginatedData } from '../utils'
import { useInfiniteQuery } from '@tanstack/react-query'

export interface AuditLog {
  id: number
  log_type: string
  org_id: number
  user_id: number
  created_at: string
  metadata: Record<string, unknown>
  user: {
    user_id: number
    email: string
    created_at: string
    updated_at: string
  } | null
}

interface AuditLogItem {
  id: number
  log_type: string
  org_id: number
  user_id: number
  created_at: string
  metadata?: Record<string, unknown>
  User?: {
    user_id: number
    email: string
    created_at: string
    updated_at: string
  } | null
}

const fetchAuditLogs = async ({
  pageParam = 1,
  orgId,
  token,
  startAt,
  endAt,
  sortBy = 'created_at',
  sortDirection = 'desc',
  userId,
}: {
  pageParam?: number
  orgId: number
  token: string
  startAt: number
  endAt: number
  sortBy?: string
  sortDirection?: string
  userId?: number
}): Promise<{
  instances: AuditLog[]
  hasNextPage: boolean
  total: number
}> => {
  const pageSize = 20
  const body = {
    org_id: orgId,
    page_number: pageParam,
    page_size: pageSize,
    start_at: startAt,
    end_at: endAt,
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...(userId && { user_id: userId }),
  }

  try {
    const result = await APIFetch.post('getAuditLogs', {
      token,
      body,
      orgId,
    })

    if (!result || result.status !== 'success' || !result.data) {
      throw new Error('Invalid response from server')
    }

    const { items, total } = result.data

    return {
      instances: items.map((log: AuditLogItem) => ({
        id: log.id,
        log_type: log.log_type,
        org_id: log.org_id,
        user_id: log.user_id,
        created_at: log.created_at,
        metadata: log.metadata || {},
        user: log.User
          ? {
              user_id: log.User.user_id,
              email: log.User.email,
              created_at: log.User.created_at,
              updated_at: log.User.updated_at,
            }
          : null,
      })),
      hasNextPage: items.length === pageSize,
      total,
    }
  } catch (error) {
    console.error('Error fetching audit logs:', error)
    return {
      instances: [],
      hasNextPage: false,
      total: 0,
    }
  }
}

export const useGetAuditLogs = (
  startAt: number,
  endAt: number,
  sortBy: string = 'created_at',
  sortDirection: string = 'desc',
  userId?: number
) => {
  const { orgId, token } = useTokenAndOrgId()

  return useInfiniteQuery({
    queryKey: [
      'audit-logs',
      orgId,
      startAt,
      endAt,
      sortBy,
      sortDirection,
      userId,
    ],
    enabled: !!orgId && !!token,
    queryFn: ({ pageParam = 1 }) =>
      fetchAuditLogs({
        pageParam,
        orgId: Number(orgId),
        token,
        startAt,
        endAt,
        sortBy,
        sortDirection,
        userId,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.hasNextPage ? allPages.length + 1 : undefined,
    select: (data) => {
      const { allInstances: allLogs, total } = processPaginatedData<AuditLog>(
        data.pages
      )
      return { allLogs, total }
    },
  })
}
