import { ReactNode, useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { AlertTriangleIcon, ShieldAlertIcon } from 'lucide-react'
import { cn } from '@/lib/utils'

type ReactText = string | ReactNode

type ConfirmationDialogProps = {
  className?: string
  open: boolean
  onOpenChange: (open: boolean) => void

  title: string
  description?: ReactText
  buttons: {
    confirm: {
      label?: string
      isDestructive?: boolean
      isLoading?: boolean
    }
    cancel?: {
      label?: string
    }
  }
  confirmation?: {
    text: string
    prompt?: string
  }
  info?: {
    alert?: {
      title: ReactText
      description: ReactText
    }
    warningPoints?: string[]
  }
  onConfirm: () => void
  onCancel?: () => void
}

export const ConfirmationDialog = ({
  className,
  open,
  onOpenChange,
  title,
  description,
  buttons = {
    confirm: {
      label: 'Confirm',
      isDestructive: false,
      isLoading: false,
    },
    cancel: {
      label: 'Cancel',
    },
  },
  confirmation,
  info,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) => {
  const [inputValue, setInputValue] = useState('')
  const isConfirmDisabled = confirmation
    ? inputValue.trim() !== confirmation.text.trim()
    : false
  const isDestructiveAction = buttons.confirm.isDestructive

  const handleConfirm = () => {
    if (confirmation && !isConfirmDisabled) {
      onConfirm()
    } else if (!confirmation) {
      onConfirm()
    }
  }

  const handleCancel = () => {
    setInputValue('')
    onCancel?.()
    onOpenChange(false)
  }

  const isSimpleDialog =
    !confirmation && !info?.warningPoints?.length && !info?.alert

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        if (!newOpen) handleCancel()
        onOpenChange(newOpen)
      }}
    >
      <DialogContent className={cn('w-full max-w-xl', className)}>
        <DialogHeader
          className={cn(
            'pb-2',
            isDestructiveAction &&
              !isSimpleDialog &&
              'border-b border-destructive/20'
          )}
        >
          <div className="flex items-center gap-3">
            {isDestructiveAction && (
              <div className="p-2 rounded-full bg-destructive/10 text-destructive">
                <ShieldAlertIcon className="h-5 w-5" />
              </div>
            )}
            <DialogTitle>{title}</DialogTitle>
          </div>
          {description && (
            <DialogDescription className="mt-3 text-base">
              {description}
            </DialogDescription>
          )}
        </DialogHeader>

        {!isSimpleDialog && (
          <div className="py-2">
            {info?.warningPoints && info.warningPoints.length > 0 && (
              <div
                className={cn(
                  'space-y-2 mt-2 mb-4 p-3 rounded-md',
                  isDestructiveAction ? 'bg-destructive/5' : 'bg-muted'
                )}
              >
                {info.warningPoints.map((point, index) => (
                  <div key={index} className="flex items-start gap-2">
                    <AlertTriangleIcon
                      className={cn(
                        'h-4 w-4 mt-0.5 flex-shrink-0',
                        isDestructiveAction
                          ? 'text-destructive'
                          : 'text-primary'
                      )}
                    />
                    <p
                      className={cn(
                        'text-sm',
                        isDestructiveAction
                          ? 'text-destructive'
                          : 'text-foreground'
                      )}
                    >
                      {point}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {info?.alert && (
              <Alert variant="default" className="bg-foreground/10 mb-4">
                <InfoCircledIcon className="h-4 w-4" />
                <AlertTitle>{info.alert.title}</AlertTitle>
                <AlertDescription>{info.alert.description}</AlertDescription>
              </Alert>
            )}

            {confirmation && (
              <div className="space-y-3 mt-4">
                <p
                  className={cn(
                    'text-sm font-medium',
                    isDestructiveAction ? 'text-destructive' : 'text-foreground'
                  )}
                >
                  {confirmation.prompt ||
                    `Please type "${confirmation.text}" to confirm this action:`}
                </p>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={
                    isDestructiveAction
                      ? `Type "${confirmation.text}"`
                      : 'Type to confirm'
                  }
                  className={cn(
                    'w-full',
                    isDestructiveAction &&
                      'border-destructive/30 focus-visible:ring-destructive/30'
                  )}
                />
              </div>
            )}
          </div>
        )}

        <DialogFooter
          className={cn(
            'pt-2',
            isDestructiveAction &&
              !isSimpleDialog &&
              'border-t border-destructive/20'
          )}
        >
          <Button
            variant="outline"
            onClick={handleCancel}
            disabled={buttons.confirm.isLoading}
            className="min-w-[80px]"
          >
            {buttons.cancel?.label || 'Cancel'}
          </Button>
          <Button
            variant={buttons.confirm.isDestructive ? 'destructive' : 'default'}
            onClick={handleConfirm}
            disabled={buttons.confirm.isLoading || isConfirmDisabled}
            className="min-w-[120px]"
          >
            {buttons.confirm.isLoading ? 'Loading...' : buttons.confirm.label}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
