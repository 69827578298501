import React, { useMemo, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Moon, Sun, BadgeInfo, TriangleAlert, Menu } from 'lucide-react'
import { useSidebar } from '@/components/ui/sidebar'
import { useTheme } from '@/components/theme-provider'
import { Link, useMatchRoute, useRouterState } from '@tanstack/react-router'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { useListServerInstances } from '@/services/server'
import { useUserStore } from '@/store/UserStore'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { useGetOrgBalanceSummary } from '@/services/home'
import { useGetOrgUserBalanceSummary } from '@/services/billing'
import { cn, formatCurrency } from '@/lib/utils'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { isRoleAdmin } from '@/services/auth/utils'
import {
  OrgBalanceDetailsDialog,
  UserBalanceDetailsDialog,
} from '@/components/balance-details-dialog'
import { Separator } from '@/components/ui/separator'

export const Header = () => {
  const { theme, setTheme } = useTheme()
  const toggleTheme = () => setTheme(theme === 'dark' ? 'light' : 'dark')

  const { data: orgProfile } = useActiveOrgProfile()
  const isAdmin = isRoleAdmin(orgProfile?.role?.role_name)

  return (
    <header className="flex flex-col items-left gap-6">
      <div className="flex gap-4 items-center px-6">
        <SidebarButton />
        <LinkBreadCrumbs />

        <div className="ml-auto flex items-center gap-4">
          <div className="flex items-center gap-4">
            {isAdmin && <BalanceData />}
            {isAdmin && <Separator orientation="vertical" className="h-8" />}
            <UserBalanceData />
          </div>
          <Button onClick={toggleTheme} variant="ghost" size="icon">
            {theme === 'dark' ? (
              <Sun className="w-5 h-5" />
            ) : (
              <Moon className="w-5 h-5" />
            )}
          </Button>
        </div>
      </div>

      <FirstServerAlert />
    </header>
  )
}

const BalanceData = () => {
  const { data, isLoading, isError } = useGetOrgBalanceSummary()
  const { orgId } = useActiveOrgId()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const dataFormatted = useMemo(() => {
    if (!data) {
      return {
        netBalance: '$0',
        total: '$0',
        totalOrgBalance: '$0',
        totalUserBalance: '$0',
        spentTotal: '$0',
        isNegative: false,
      }
    }

    const netValue = (data.total - data.spent_total) / 100
    return {
      netBalance: formatCurrency(netValue),
      total: formatCurrency(data.total / 100),
      totalOrgBalance: formatCurrency(data.breakdown.total_org_balance / 100),
      totalUserBalance: formatCurrency(data.breakdown.total_user_balance / 100),
      spentTotal: formatCurrency(data.spent_total / 100),
      isNegative: netValue < 0,
    }
  }, [data])

  if (isLoading)
    return (
      <div className="text-xs text-muted-foreground">Loading balance...</div>
    )
  if (isError)
    return <div className="text-xs text-destructive">Error loading balance</div>

  return (
    <OrgBalanceDetailsDialog
      open={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      trigger={
        <button
          type="button"
          className="appearance-none bg-transparent border-0"
        >
          <div className="flex flex-row items-center gap-2 cursor-pointer group">
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground group-hover:text-foreground/70">
                Organization
              </span>
              <div className="flex items-center gap-1">
                {dataFormatted.isNegative && (
                  <TriangleAlert className="w-4 h-4 text-destructive" />
                )}
                <span
                  className={cn(
                    'font-semibold text-sm',
                    dataFormatted.isNegative
                      ? 'text-destructive'
                      : 'text-foreground'
                  )}
                >
                  {dataFormatted.netBalance}
                </span>
              </div>
            </div>
          </div>
        </button>
      }
      data={{
        formattedTotal: dataFormatted.total,
        formattedNetBalance: dataFormatted.netBalance,
        formattedSpentTotal: dataFormatted.spentTotal,
        formattedTotalOrgBalance: dataFormatted.totalOrgBalance,
        formattedTotalUserBalance: dataFormatted.totalUserBalance,
        usage_percent: data?.usage_percent || 0,
        showBillingLink: true,
        orgId: orgId?.toString(),
      }}
    />
  )
}

const UserBalanceData = () => {
  const { data: orgProfile } = useActiveOrgProfile()
  const { orgId } = useActiveOrgId()
  const userId = orgProfile?.userId ?? 0
  const { data, isLoading, isError } = useGetOrgUserBalanceSummary(userId)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const dataFormatted = useMemo(() => {
    if (!data) {
      return {
        netBalance: '$0',
        total: '$0',
        spendingLimit: '$0',
        totalUserBalance: '$0',
        spentTotal: '$0',
        isNegative: false,
      }
    }

    const netValue = (data.total - data.spent_total) / 100
    return {
      netBalance: formatCurrency(netValue),
      total: formatCurrency(data.total / 100),
      spendingLimit: formatCurrency(data.breakdown.spending_limit / 100),
      totalUserBalance: formatCurrency(data.breakdown.total_user_balance / 100),
      spentTotal: formatCurrency(data.spent_total / 100),
      isNegative: netValue < 0,
    }
  }, [data])

  if (isLoading) {
    return (
      <div className="text-sm text-muted-foreground">
        Loading user balance...
      </div>
    )
  }
  if (isError) {
    return (
      <div className="text-sm text-destructive">Error loading user balance</div>
    )
  }

  return (
    <UserBalanceDetailsDialog
      open={isDialogOpen}
      onOpenChange={setIsDialogOpen}
      trigger={
        <button
          type="button"
          className="appearance-none bg-transparent border-0"
        >
          <div className="flex flex-row items-center gap-2 cursor-pointer group">
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground group-hover:text-foreground/70">
                Personal
              </span>
              <div className="flex items-center gap-1">
                {dataFormatted.isNegative && (
                  <TriangleAlert className="w-4 h-4 text-destructive" />
                )}
                <span
                  className={cn(
                    'font-semibold text-sm',
                    dataFormatted.isNegative
                      ? 'text-destructive'
                      : 'text-foreground'
                  )}
                >
                  {dataFormatted.netBalance}
                </span>
              </div>
            </div>
          </div>
        </button>
      }
      data={{
        formattedTotal: dataFormatted.total,
        formattedNetBalance: dataFormatted.netBalance,
        formattedSpendingLimit: dataFormatted.spendingLimit,
        formattedTotalUserBalance: dataFormatted.totalUserBalance,
        formattedSpentTotal: dataFormatted.spentTotal,
        usage_percent: data?.usage_percent || 0,
        orgId: orgId?.toString(),
        showBillingLink: true,
      }}
    />
  )
}

const LinkBreadCrumbs = () => {
  const { orgId } = useActiveOrgId()
  const _breadcrumbs = useRouterState({
    select: (state) => {
      return state.matches
        .map((match) => ({
          title: match.meta?.find((tag) => tag.title)?.title as string,
          path: match.pathname,
        }))
        .filter((crumb) => Boolean(crumb.title))
    },
  })

  const breadcrumbs = React.useMemo(() => {
    const orgLink = '/' + orgId + '/dashboard/'
    if (_breadcrumbs[1]?.title === 'New Server') {
      return [
        { title: 'Home', path: '/' },
        { title: 'Servers', path: orgLink + '/servers' },
        { title: 'New', path: orgLink + '/servers/new' },
      ]
    }
    return _breadcrumbs
  }, [_breadcrumbs, orgId])

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((crumb, i) => {
          if (!crumb) return null
          return (
            <React.Fragment key={i}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={crumb.path}>{crumb.title}</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {i === breadcrumbs.length - 1 ? null : <BreadcrumbSeparator />}
            </React.Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

const SidebarButton = () => {
  const { isMobile, setOpenMobile } = useSidebar()
  if (!isMobile) return null
  return (
    <Button
      className="flex items-center gap-2"
      variant="ghost"
      onClick={() => setOpenMobile(true)}
    >
      <Menu className="w-4 h-4" />
      Open Sidebar
    </Button>
  )
}

const FirstServerAlert = () => {
  const hasCreatedFirstServer = useUserStore(
    (state) => state.hasCreatedFirstServer
  )
  const { orgId } = useActiveOrgId()
  const matchRoute = useMatchRoute()
  const isStoragePage = !!matchRoute({ to: '/$orgId/dashboard/storage' })
  const isSSHKeysPage = !!matchRoute({ to: '/$orgId/dashboard/sshkeys' })
  const { data, isLoading } = useListServerInstances({
    showAllUsers: false,
    refetchInterval: 10_000,
    staleTime: 5_000,
  })
  const servers = data?.allInstances || []

  if (hasCreatedFirstServer) return null
  if (isLoading) return null
  if (!isStoragePage && !isSSHKeysPage) return null
  if (servers.length > 0) return null

  return (
    <Alert className="flex items-center justify-between p-5">
      <BadgeInfo className="text-foreground/70" />
      <div className="flex flex-col">
        <AlertTitle className="font-semibold">
          Create your first server
        </AlertTitle>
        <AlertDescription className="text-muted-foreground">
          Create your first server to start using the platform.
        </AlertDescription>
      </div>
      <Button variant="outline" asChild className="hover:bg-accent">
        <Link
          to="/$orgId/dashboard/servers/new"
          params={{ orgId: orgId.toString() }}
        >
          Create server
        </Link>
      </Button>
    </Alert>
  )
}
