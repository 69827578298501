// TODO: return ts-typed object
export const decodeToken = (token: string) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]))
    return payload
  } catch (error) {
    console.error('Failed to decode token:', error)
    return null
  }
}

export const isTokenExpired = (token: string): boolean => {
  try {
    const payload = decodeToken(token)
    if (!payload) return true
    const exp = payload.exp * 1000
    return Date.now() > exp
  } catch (error) {
    return true
  }
}
