import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Info, ArrowUp, ArrowDown, CreditCard, DollarSign } from 'lucide-react'
import { Progress } from '@/components/ui/progress'
import { Link } from '@tanstack/react-router'
import { cn } from '@/lib/utils'

type ChildBreakdownItem = {
  label: string
  value: string
  tooltip?: string
}

type BreakdownItemType = ChildBreakdownItem & {
  children?: ChildBreakdownItem[]
}

type BalanceDetailsDialogProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  trigger?: React.ReactNode

  title: string

  data: {
    breakdown: BreakdownItemType[]
    footer: { label: string; value: string }[]
    progress: { label: string; value: number }
  }
  showBillingLink?: boolean
  orgId?: string
}

export const BalanceDetailsDialog = ({
  open,
  onOpenChange,
  trigger,
  title,
  data,
  showBillingLink,
  orgId,
}: BalanceDetailsDialogProps) => {
  const renderBreakdownItem = (
    item: BreakdownItemType | ChildBreakdownItem,
    key: number | string,
    depth = 0
  ) => {
    // Determine if the value is positive (income), negative (expense), or neutral
    const valueType = item.value.startsWith('+')
      ? 'income'
      : item.value.startsWith('-')
        ? 'expense'
        : 'neutral'

    return (
      <div key={key} className={cn('rounded-lg', depth === 0 && 'mb-3')}>
        <div
          className={cn(
            'flex justify-between items-center py-3 px-4 rounded-md',
            depth === 0 && 'bg-muted/40'
          )}
          style={{ marginLeft: depth > 0 ? `${depth * 1}rem` : 0 }}
        >
          <div className="flex items-center gap-2">
            {depth === 0 && valueType === 'income' && (
              <ArrowUp className="w-4 h-4 text-green-500 flex-shrink-0" />
            )}
            {depth === 0 && valueType === 'expense' && (
              <ArrowDown className="w-4 h-4 text-destructive flex-shrink-0" />
            )}

            <span
              className={cn(
                'text-sm',
                depth === 0 ? 'font-semibold' : 'text-muted-foreground'
              )}
            >
              {item.label}
            </span>

            {item.tooltip && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="w-3.5 h-3.5 text-muted-foreground cursor-help" />
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-[280px] p-3 text-sm bg-popover border border-border shadow-lg rounded-md"
                  sideOffset={5}
                >
                  <p className="leading-relaxed text-foreground">
                    {item.tooltip}
                  </p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          <span
            className={cn(
              'text-sm tabular-nums',
              depth === 0 && 'font-semibold',
              valueType === 'income' && 'text-green-500',
              valueType === 'expense' && 'text-destructive'
            )}
          >
            {item.value}
          </span>
        </div>

        {'children' in item && item.children && item.children.length > 0 && (
          <div
            className={cn(
              'border-l-2 border-dashed border-border ml-5 pl-2 my-1',
              depth === 0 && 'mt-2'
            )}
          >
            {item.children.map((child, childIndex) =>
              renderBreakdownItem(child, `${key}-${childIndex}`, depth + 1)
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>

      <DialogContent className="sm:max-w-[600px] p-6">
        <DialogHeader className="flex items-start mb-6">
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10 text-primary">
              <CreditCard className="w-4 h-4" />
            </div>
            <DialogTitle className="text-xl font-bold">{title}</DialogTitle>
          </div>
        </DialogHeader>

        <div className="space-y-6">
          <div className="space-y-2">
            {data.breakdown.map((item, index) =>
              renderBreakdownItem(item, index)
            )}
          </div>

          <div className="h-px bg-border" />

          <div className="bg-muted/30 rounded-lg p-4">
            <div className="flex flex-col space-y-4">
              {/* Current Balance Display */}
              {data.footer.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="text-base font-bold">{item.label}</span>
                  <span className="text-xl font-bold text-primary">
                    {item.value}
                  </span>
                </div>
              ))}

              {/* Usage Progress Section */}
              <div className="pt-3 mt-1 border-t border-border/40">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-sm font-medium">Balance Usage</div>
                  <div className="text-xs text-muted-foreground">
                    {data.progress.value.toFixed(0)}%
                  </div>
                </div>

                <div>
                  <div className="relative h-2 bg-muted rounded-full overflow-hidden">
                    <Progress
                      value={data.progress.value}
                      className="h-full transition-all duration-500 ease-in-out"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DialogFooter className="mt-6 gap-3">
          <DialogClose asChild>
            <Button type="button" variant="outline" size="sm" className="h-9">
              Close
            </Button>
          </DialogClose>

          {showBillingLink && orgId && (
            <DialogClose asChild>
              <Button
                type="button"
                variant="default"
                size="sm"
                className="h-9 gap-2"
                asChild
              >
                <Link to="/$orgId/dashboard/billing" params={{ orgId }}>
                  <DollarSign className="w-3.5 h-3.5" />
                  Manage Billing
                </Link>
              </Button>
            </DialogClose>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type DialogProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  trigger?: React.ReactNode
}

type OrgBalanceDetailsDialogProps = {
  formattedTotalOrgBalance: string
  formattedTotalUserBalance: string

  formattedTotal: string
  formattedNetBalance: string
  formattedSpentTotal: string

  usage_percent: number
  showBillingLink?: boolean
  orgId?: string
}

export const OrgBalanceDetailsDialog = ({
  open,
  onOpenChange,
  trigger,
  data,
}: DialogProps & { data: OrgBalanceDetailsDialogProps }) => {
  return (
    <BalanceDetailsDialog
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      title="Organization Balance Details"
      data={{
        breakdown: [
          {
            label: 'Total Balance',
            value: '+' + data.formattedTotal,
            tooltip:
              "Overall funds in your organization, including shared organization balance and all individual user's deposits",
            children: [
              {
                label: 'Shared Organization Balance',
                value: data.formattedTotalOrgBalance,
                tooltip: 'Shared balance across your organization.',
              },
              {
                label: 'User Additional Deposits',
                value: data.formattedTotalUserBalance,
                tooltip: 'Total additional deposits from all users',
              },
            ],
          },
          {
            label: 'Total Spending',
            value: '-' + data.formattedSpentTotal,
          },
        ],
        footer: [
          {
            label: 'Current Balance',
            value: data.formattedNetBalance,
          },
        ],
        progress: {
          label: `Usage`,
          value: data.usage_percent,
        },
      }}
      showBillingLink={data.showBillingLink}
      orgId={data.orgId}
    />
  )
}

export type UserBalanceDetailsDialogProps = {
  formattedTotal: string
  formattedNetBalance: string

  formattedSpentTotal: string
  formattedSpendingLimit: string
  formattedTotalUserBalance: string

  usage_percent: number
  showBillingLink?: boolean
  orgId?: string
}

export const UserBalanceDetailsDialog = ({
  open,
  onOpenChange,
  trigger,
  data,
}: DialogProps & { data: UserBalanceDetailsDialogProps }) => {
  return (
    <BalanceDetailsDialog
      open={open}
      onOpenChange={onOpenChange}
      trigger={trigger}
      title="Your Balance Details"
      data={{
        breakdown: [
          {
            label: 'Total Balance',
            value: '+' + data.formattedTotal,
            tooltip: 'Overall funds in your account.',
            children: [
              {
                label: 'Spending Limit',
                value: data.formattedSpendingLimit,
                tooltip: 'Spending limit provided to you by your organization.',
              },
              {
                label: 'Additional Top-Ups',
                value: data.formattedTotalUserBalance,
                tooltip: 'Funds you have personally deposited to your account.',
              },
            ],
          },
          {
            label: 'Total Spending',
            value: '-' + data.formattedSpentTotal,
          },
        ],
        footer: [
          {
            label: 'Current Balance',
            value: data.formattedNetBalance,
          },
        ],
        progress: {
          label: `Usage`,
          value: data.usage_percent,
        },
      }}
      showBillingLink={data.showBillingLink}
      orgId={data.orgId}
    />
  )
}
