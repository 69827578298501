import { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import {
  Download,
  ChevronsUpDown,
  ArrowUp,
  ArrowDown,
  Search,
} from 'lucide-react'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { CalendarPopover } from '@/components/calendar-popover'
import { DateRange } from 'react-day-picker'
import { useGetOrgUserInvoices } from '@/services/billing'
import dayjs from 'dayjs'
import Gravatar from 'react-gravatar'
import { getFormattedUnixDate } from '@/helpers/time'
import utc from 'dayjs/plugin/utc'
import { Spinner } from '@/components/spinner'
import { OrgUserInvoicesTableProps } from '../types'
dayjs.extend(utc)

export const OrgUserInvoicesTable = ({ userId }: OrgUserInvoicesTableProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dateRange, setDateRange] = useState<DateRange>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  })
  const [sortBy, setSortBy] = useState<string>('created_at')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetOrgUserInvoices(
    userId,
    {
      start_at: getFormattedUnixDate(dateRange.from, { time: 'startOfDay' }),
      end_at: getFormattedUnixDate(dateRange.to, { time: 'endOfDay' }),
      sort_by: sortBy,
      sort_direction: sortDirection,
      search: searchTerm,
    },
    10
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortDirection('desc')
    }
  }

  const invoices = data?.pages.flatMap((page) => page.data.items) || []

  const observerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!hasNextPage || isFetching) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchNextPage()
        }
      },
      { root: null, rootMargin: '100px', threshold: 1.0 }
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => observer.disconnect()
  }, [hasNextPage, isFetching, fetchNextPage])

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4 flex-grow">
          <h2 className="text-xl font-semibold">User Invoices</h2>
          <div className="relative flex-grow">
            <Search className="absolute w-4 h-4 left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
            <Input
              type="text"
              placeholder="Search Invoice ID, Date, or Amount..."
              className="pl-10 w-full"
              value={searchTerm}
              onChange={handleSearchChange}
              maxLength={100}
            />
          </div>
        </div>
        <CalendarPopover
          range={dateRange}
          onSelect={(range) => {
            if (range?.from && range?.to) {
              setDateRange({ from: range.from, to: range.to })
            }
          }}
        />
      </div>

      <Card>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : isError ? (
            <div className="flex justify-center items-center h-64 text-destructive">
              <p>Error loading invoices: {error?.message}</p>
            </div>
          ) : (
            <Table>
              <TableCaption>A list of your recent invoices.</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead
                    className="min-w-[120px] max-w-[160px] w-auto font-bold cursor-pointer whitespace-nowrap"
                    onClick={() => handleSort('stripe_invoice_id')}
                  >
                    <div className="flex items-center gap-2">
                      Invoice ID
                      {sortBy === 'stripe_invoice_id' ? (
                        sortDirection === 'asc' ? (
                          <ArrowUp size={16} />
                        ) : (
                          <ArrowDown size={16} />
                        )
                      ) : (
                        <ChevronsUpDown size={16} />
                      )}
                    </div>
                  </TableHead>
                  <TableHead
                    className="font-bold pl-6 cursor-pointer"
                    onClick={() => handleSort('created_at')}
                  >
                    <div className="flex items-center gap-2">
                      Date
                      {sortBy === 'created_at' ? (
                        sortDirection === 'asc' ? (
                          <ArrowUp size={16} />
                        ) : (
                          <ArrowDown size={16} />
                        )
                      ) : (
                        <ChevronsUpDown size={16} />
                      )}
                    </div>
                  </TableHead>
                  <TableHead className="font-bold">
                    <div className="flex items-center gap-2">Amount</div>
                  </TableHead>
                  <TableHead className="font-bold">
                    <div className="flex items-center gap-2">User</div>
                  </TableHead>
                  <TableHead className="font-bold">
                    <div className="flex items-center gap-2">Type</div>
                  </TableHead>
                  <TableHead className="font-bold"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoices.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center">
                      No data
                    </TableCell>
                  </TableRow>
                ) : (
                  invoices.map((invoice) => {
                    const userProfile =
                      invoice.UserOrganization?.profile_metadata || {}
                    const userEmail = invoice.User?.email || 'N/A'
                    const downloadUrl = invoice.metadata?.download_page || ''

                    return (
                      <TableRow key={invoice.stripe_invoice_id}>
                        <TableCell className="font-medium">
                          {invoice.stripe_invoice_id}
                        </TableCell>
                        <TableCell className="pl-6">
                          {invoice.formattedDate}
                        </TableCell>
                        <TableCell>{invoice.formattedAmount}</TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            {userProfile.avatar_preview_url ? (
                              <img
                                src={userProfile.avatar_preview_url}
                                alt={`${userProfile.first_name} ${userProfile.last_name}`}
                                className="w-8 h-8 rounded-full"
                              />
                            ) : (
                              <Gravatar
                                email={userEmail}
                                className="w-8 h-8 rounded-full"
                              />
                            )}
                            <div>
                              <p>
                                {userProfile.first_name} {userProfile.last_name}
                              </p>
                              <p className="text-sm text-muted-foreground">
                                {userEmail}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{invoice.invoiceTypeLabel}</TableCell>
                        <TableCell>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="flex items-center gap-2 disabled:opacity-50"
                            disabled={!downloadUrl}
                          >
                            <a
                              href={downloadUrl || '#'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Download className="w-4 h-4" />
                              Download
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          )}
          {isFetching && hasNextPage && (
            <div className="flex justify-center py-4">
              <Spinner />
            </div>
          )}
        </CardContent>
      </Card>

      <div ref={observerRef} className="h-10" />
    </div>
  )
}
