import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { SquarePen, Info } from 'lucide-react'
import { useEffect, useState } from 'react'
import {
  useGetOrgSpendingLimit,
  useCreateOrgSpendingLimit,
} from '@/services/settings'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

interface OrgCreditLimitCardProps {
  onLimitChange?: (newLimit: number) => void
}

export const OrgCreditLimitCard = ({
  onLimitChange,
}: OrgCreditLimitCardProps) => {
  const [isEditingOrgLimit, setIsEditingOrgLimit] = useState(false)
  const [creditLimit, setCreditLimit] = useState<number>(0)
  const [isConfirming, setIsConfirming] = useState(false)
  const [draftCreditLimit, setDraftCreditLimit] = useState<number>(0)

  const { data: orgSpendingLimit, refetch: refetchOrgSpendingLimit } =
    useGetOrgSpendingLimit()

  useEffect(() => {
    if (orgSpendingLimit) {
      const newLimit = orgSpendingLimit.max_spend_cent / 100
      setCreditLimit(newLimit)
      if (!isEditingOrgLimit) {
        setDraftCreditLimit(newLimit)
      }

      if (onLimitChange) {
        onLimitChange(newLimit)
      }
    }
  }, [orgSpendingLimit, isEditingOrgLimit, onLimitChange])

  const createOrgSpendingLimitMutation = useCreateOrgSpendingLimit({
    onSuccess: () => {
      refetchOrgSpendingLimit()
      setIsEditingOrgLimit(false)
      setIsConfirming(false)
    },
  })

  return (
    <>
      <Card
        className={`p-8 rounded-lg shadow ${
          isEditingOrgLimit ? 'border-2 border-primary' : 'border'
        }`}
      >
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Current Organization-Wide Credit Limit
            </h2>
            <p className="text-muted-foreground mt-1">
              This limit will apply to all users without specific overrides
            </p>
          </div>
          <div className="flex flex-col items-center">
            {/* Show the saved creditLimit in the header */}
            <p className="text-2xl font-bold">
              ${creditLimit}{' '}
              <span className="text-base font-normal">per user</span>
            </p>
            {!isEditingOrgLimit && (
              <Button
                variant="outline"
                onClick={() => {
                  // Copy the saved value into the draft
                  setDraftCreditLimit(creditLimit)
                  setIsEditingOrgLimit(true)
                }}
                className="mt-2 text-foreground dark:text-foreground border-foreground 
                dark:border-foreground hover:bg-foreground dark:hover:bg-foreground hover:text-background dark:hover:text-background"
              >
                <SquarePen className="mr-2" size={16} />
                Update Org Credit Limit
              </Button>
            )}
          </div>
        </div>

        {isEditingOrgLimit && (
          <>
            <div className="border-t border my-4"></div>
            <div className="mt-4 space-y-2">
              <p className="text-sm text-foreground">
                Set new org limit (in dollars)
              </p>
              <div className="flex items-center space-x-4">
                {/* Bind the input to draftCreditLimit */}
                <Input
                  type="text"
                  value={draftCreditLimit}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '')
                    const normalizedValue = numericValue.replace(/^0+/, '')
                    setDraftCreditLimit(
                      normalizedValue ? Number(normalizedValue) : 0
                    )
                  }}
                  className="w-[5.5rem] rounded-full text-center"
                />
                <Tooltip>
                  <TooltipTrigger>
                    <Info className="text-primary cursor-pointer" size={20} />
                  </TooltipTrigger>
                  <TooltipContent className="bg-primary/10 text-foreground p-3 shadow-md rounded-md max-w-xs">
                    <p className="text-sm">
                      Updating the organization-wide limit will override all
                      existing custom user limits. Proceeding with this change
                      will apply the new limit to all users.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
              <div className="flex space-x-4">
                <Button
                  variant="outline"
                  onClick={() => {
                    // Cancel discards changes
                    setIsEditingOrgLimit(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="w-[150px] mx-auto"
                  onClick={() => setIsConfirming(true)}
                >
                  Apply to All Users
                </Button>
              </div>
            </div>
          </>
        )}
      </Card>

      {/* Confirm Org Limit Update Dialog */}
      <ConfirmationDialog
        open={isConfirming}
        onOpenChange={setIsConfirming}
        title={`Update Organization-Wide Credit Limit`}
        description={
          <>
            You are about to update the organization-wide credit limit to{' '}
            <b className="text-foreground/90">${draftCreditLimit} per user</b>.
            This change will impact all users and active resources.
          </>
        }
        buttons={{
          confirm: {
            label: 'Apply to All Users',
            isDestructive: true,
            isLoading: createOrgSpendingLimitMutation.isPending,
          },
        }}
        confirmation={{
          text: String(draftCreditLimit),
        }}
        info={{
          warningPoints: [
            'All customized user limits will be overwritten.',
            'Some running resources may be affected if the limit is lowered.',
          ],
        }}
        onConfirm={() => {
          createOrgSpendingLimitMutation.mutate(
            { cent: draftCreditLimit * 100 },
            {
              onSuccess: () => {
                setIsConfirming(false)
                setIsEditingOrgLimit(false)
              },
              onError: (err) => {
                console.error(err)
              },
            }
          )
        }}
        onCancel={() => {
          setIsConfirming(false)
        }}
      />
    </>
  )
}
