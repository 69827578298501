import React from 'react'
import { Badge } from '@/components/ui/badge'
import { CopyButton } from '@/components/copy-button'
import { ServerInstance } from '@/services/server/types'
import { Copy as CustomCopyIcon, Fan, Microchip, Disc3 } from 'lucide-react'
import { SpecCard } from '@/components/spec-card'

// Types
type InfoSectionProps = {
  label: string
  content: React.ReactNode
}

type ServiceUrlInfo = {
  name: string
  url: string
  fullUrl: string
  mappedPort: number | string
}

export const ServerDetailContent = ({
  serverInstance,
}: {
  serverInstance: ServerInstance
}) => {
  const {
    metadata,
    service_urls,
    storage_volume_name,
    storage_volume_id,
    cpu_request_count,
    cpu_limit_count,
    ram_request_gb,
    ram_limit_gb,
    gpu_request_count,
    gpu_request_gmem,
    image,
  } = serverInstance
  const { ssh, extra } = metadata || {}

  // Derived values
  const sshCommand = ssh
    ? `ssh ${ssh.username}@${ssh.ip} -p ${String(ssh.port)}`
    : 'SSH information not available'

  // Transform service_urls into a more structured format
  const serviceUrls: ServiceUrlInfo[] = (service_urls || []).map((service) => {
    const isJupyter = service.name === 'jupyter-labs'
    const fullUrl = isJupyter
      ? `${service.url}?token=${metadata?.extra?.notebook_token || ''}`
      : service.url

    return {
      name: service.name,
      url: service.url,
      fullUrl,
      mappedPort: service.mapped_port,
    }
  })

  return (
    <div className="space-y-8">
      <InfoSection
        label="Hardware Specifications"
        content={
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <SpecCard
              icon={<Fan className="h-5 w-5" />}
              title="GPU"
              items={[
                { label: 'Count', value: `${gpu_request_count ?? 0}x` },
                { label: 'VRAM', value: `${gpu_request_gmem ?? 0} GB` },
              ]}
              variant="gpu"
            />

            <SpecCard
              icon={<Microchip className="h-5 w-5" />}
              title="CPU"
              items={[
                {
                  label: 'Count',
                  value: `${cpu_request_count ?? cpu_limit_count ?? 0}x`,
                },
              ]}
            />

            <SpecCard
              icon={<Microchip className="h-5 w-5 opacity-50" />}
              title="Memory"
              items={[
                {
                  label: 'RAM',
                  value: `${ram_request_gb ?? ram_limit_gb ?? 0} GB`,
                },
              ]}
            />
          </div>
        }
      />

      <InfoSection
        label="SSH Access"
        content={
          ssh ? (
            <div className="flex items-center gap-3">
              <div className="flex-1 font-mono text-sm px-4 py-3 rounded-lg bg-muted/50 dark:bg-muted/30 border">
                {sshCommand}{' '}
                <span className="text-muted-foreground">
                  -i &lt;ssh_key_path&gt;
                </span>
              </div>
              <CopyButton
                textToCopy={sshCommand}
                icon={<CustomCopyIcon className="w-4 h-4" />}
                className="bg-primary text-primary-foreground hover:bg-primary/90"
              >
                Copy
              </CopyButton>
            </div>
          ) : (
            <p className="text-muted-foreground">Not enabled</p>
          )
        }
      />

      <InfoSection
        label="Storage Mount"
        content={
          !storage_volume_id ? (
            <p className="text-muted-foreground">No Storage Mounted</p>
          ) : (
            <div className="rounded-lg border bg-card p-4">
              <p className="text-foreground">
                Volume{' '}
                <span className="font-semibold">"{storage_volume_name}"</span>{' '}
                attached
              </p>
              <p className="text-sm text-muted-foreground mt-1">
                Volume ID: {storage_volume_id}
              </p>
            </div>
          )
        }
      />

      <InfoSection
        label="HTTP Ports"
        content={
          serviceUrls.length ? (
            <div className="space-y-3 rounded-lg border bg-card p-4">
              {serviceUrls.map((service) => (
                <ServiceUrlItem key={service.mappedPort} service={service} />
              ))}
            </div>
          ) : (
            <p className="text-muted-foreground">Not available</p>
          )
        }
      />

      <InfoSection
        label="Extra"
        content={
          extra && Object.keys(extra).length ? (
            <div className="rounded-lg border bg-card p-4 space-y-2">
              {Object.entries(extra).map(([key, value]) => (
                <p key={key} className="text-sm">
                  <span className="font-medium text-muted-foreground">
                    {key}:
                  </span>{' '}
                  <span className="text-foreground">{value}</span>
                </p>
              ))}
            </div>
          ) : (
            <p className="text-muted-foreground">No extra data</p>
          )
        }
      />
    </div>
  )
}

// Child components
const InfoSection = ({ label, content }: InfoSectionProps) => (
  <div className="space-y-2">
    <h3 className="text-base font-semibold text-foreground">{label}</h3>
    <div className="w-full">{content}</div>
  </div>
)

const ServiceUrlItem = ({ service }: { service: ServiceUrlInfo }) => (
  <div className="flex items-center gap-3">
    <Badge className="bg-primary/15 text-primary hover:bg-primary/20 rounded-lg px-3 py-1.5 text-sm font-medium">
      :{service.mappedPort}
    </Badge>
    <span className="text-sm font-medium text-foreground">{service.name}</span>
    <div className="flex-1 font-mono text-sm px-3 py-2 rounded-md bg-muted/50 dark:bg-muted/30 border">
      <a
        href={service.fullUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary hover:underline break-all"
      >
        {service.fullUrl}
      </a>
    </div>
    <CopyButton
      textToCopy={service.fullUrl}
      className="bg-primary text-primary-foreground hover:bg-primary/90 h-8 px-3 rounded-md text-sm"
    >
      Copy
    </CopyButton>
  </div>
)
