import { useUserStore } from '@/store/UserStore'
import { Navigate, Outlet, useNavigate } from '@tanstack/react-router'
import { DashboardSidebar } from './sidebar'
import { Header } from './header'
import { useIsValidOrg } from '@/hooks/useIsValidOrg'
import { toast } from 'react-toastify'
import { SidebarProvider } from '@/components/ui/sidebar'
import { useActiveOrgId } from '@/hooks/useActiveOrgId'
import { Spinner } from '@/components/spinner'

export const Dashboard = () => {
  const { token } = useUserStore((state) => ({ token: state.user?.token }))
  const { orgId } = useActiveOrgId()
  const navigate = useNavigate()
  const { isLoading } = useIsValidOrg({
    orgId: Number(orgId),
    onInvalidOrgId: () => {
      toast.error('Invalid organization ID')
      navigate({ to: '/404' })
    },
  })
  if (!token) {
    return <Navigate to="/login" />
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    )
  }

  return (
    <SidebarProvider className="flex min-h-screen w-full bg-background">
      <DashboardSidebar />
      <div className="flex-1 p-4">
        <Header />
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </SidebarProvider>
  )
}
