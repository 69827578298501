import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { OverviewTab } from '../shared/OverviewTab'
import { useActiveOrgProfile } from '@/hooks/useActiveOrgProfile'
import { Spinner } from '@/components/spinner'

export const DashboardHomeUser = () => {
  const { data, isLoading } = useActiveOrgProfile()

  if (isLoading) {
    return <Spinner />
  }

  const userId = data?.userId

  if (!userId) {
    return <Spinner />
  }

  return (
    <Tabs defaultValue="overview" className="w-full sm:w-auto">
      <div className="mb-4 flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          {/* NOTIFICATIONS DISABLED FOR NOW */}
          <TabsTrigger disabled value="notifications">
            Notifications
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value="overview">
        <OverviewTab userId={userId} />
      </TabsContent>
      <TabsContent value="notifications">
        <div>Notifications</div>
      </TabsContent>
    </Tabs>
  )
}
