import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Lightbulb, Loader, Check } from 'lucide-react'
import { cn } from '@/lib/utils'
import { SearchableUserSelect } from '../components/SearchableUserSelect'
import { ButtonStatus } from '../components/types'
import { OrgUser } from '@/services/user-management'
import { useEffect, useState } from 'react'
import { useUpdateOrgUserLimit, useGetOrgUserLimit } from '@/services/settings'
import { getOrgUsers, LIST_USERS_PAGE_SIZE } from '@/services/user-management'
import { useQuery } from '@tanstack/react-query'
import { useTokenAndOrgId } from '@/services/utils'
import { ConfirmationDialog } from '@/components/confirmation-dialog'

interface UserStorageVolumeCardProps {
  orgStorageLimit: number
}

export const UserStorageVolumeCard = ({
  orgStorageLimit,
}: UserStorageVolumeCardProps) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null)
  const [userLimit, setUserLimit] = useState('')
  const [storageCountLimit, setStorageCountLimit] = useState('')
  const [currentUserLimit, setCurrentUserLimit] = useState<number | null>(null)
  const [currentStorageCountLimit, setCurrentStorageCountLimit] = useState<
    number | null
  >(null)
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('idle')
  const [isConfirming, setIsConfirming] = useState(false)

  const { token, orgId } = useTokenAndOrgId()
  const updateOrgUserLimitMutation = useUpdateOrgUserLimit()

  const { data: orgUsersData, isLoading: isUsersLoading } = useQuery<{
    users: OrgUser[]
    total: number
  }>({
    queryKey: ['org-users', token, orgId],
    queryFn: () =>
      getOrgUsers(token, Number(orgId), LIST_USERS_PAGE_SIZE, 1, 'active', ''),
  })
  const userList = orgUsersData?.users || []

  const { data: userLimitData, refetch: refetchOrgUserLimit } =
    useGetOrgUserLimit(selectedUser ? Number(selectedUser) : 0)

  useEffect(() => {
    if (selectedUser && userLimitData) {
      setCurrentUserLimit(userLimitData.org_per_user_storage_gb)
      setUserLimit(String(userLimitData.org_per_user_storage_gb))
      setCurrentStorageCountLimit(userLimitData.org_per_user_storage_count)
    } else {
      setCurrentUserLimit(null)
      setCurrentStorageCountLimit(null)
      setUserLimit('')
      setStorageCountLimit('')
    }
  }, [selectedUser, userLimitData])

  useEffect(() => {
    if (buttonStatus === 'success') {
      const timer = setTimeout(() => setButtonStatus('idle'), 2000)
      return () => clearTimeout(timer)
    }
  }, [buttonStatus])

  return (
    <Card
      className={cn(
        'p-8 rounded-lg shadow border',
        selectedUser && 'border-2 border-primary'
      )}
    >
      <h2 className="text-2xl font-bold mb-4">Override Specific Users</h2>
      <p className="text-muted-foreground mb-4">
        Set custom limits for specific users
      </p>
      <div className="border-t my-4">
        <div className="mt-6 space-y-3">
          <div className="storage-volume-override">
            {isUsersLoading ? (
              <p className="text-muted-foreground">Loading users...</p>
            ) : (
              <SearchableUserSelect
                userList={userList}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            )}
          </div>

          {selectedUser && (
            <div className="mt-6 space-y-3">
              <div className="w-full max-w-md flex items-center space-x-2 p-3 rounded-sm text-sm bg-muted">
                <Lightbulb className="text-muted-foreground" size={16} />
                <p className="text-muted-foreground">
                  This user currently has a limit of{' '}
                  <strong>
                    {currentUserLimit !== null
                      ? currentUserLimit
                      : orgStorageLimit}{' '}
                    GB
                  </strong>
                  {currentStorageCountLimit !== null && (
                    <>
                      {' '}
                      and{' '}
                      <strong>
                        {currentStorageCountLimit} storage volumes
                      </strong>
                    </>
                  )}
                  .
                </p>
              </div>

              <div className="flex w-full max-w-md items-center space-x-2">
                <Input
                  type="text"
                  value={userLimit}
                  onChange={(e) => {
                    let numericValue = e.target.value.replace(/\D/g, '')
                    numericValue = numericValue.replace(/^0+/, '')
                    if (!numericValue) {
                      numericValue = '0'
                    }
                    setUserLimit(numericValue)
                  }}
                  placeholder={
                    currentUserLimit === null
                      ? orgStorageLimit.toString()
                      : currentUserLimit.toString()
                  }
                  className={cn(
                    'w-32 rounded-full text-center',
                    userLimit && 'border-primary'
                  )}
                />
                <span className="text-sm text-foreground">
                  <strong>GB</strong> Storage Volume
                </span>
              </div>

              <div className="flex w-full max-w-md items-center space-x-2">
                <Input
                  type="text"
                  value={storageCountLimit}
                  onChange={(e) => {
                    let numericValue = e.target.value.replace(/\D/g, '')
                    numericValue = numericValue.replace(/^0+/, '')
                    if (!numericValue) {
                      numericValue = '0'
                    }
                    setStorageCountLimit(numericValue)
                  }}
                  placeholder={
                    currentStorageCountLimit === null
                      ? 'Enter count limit'
                      : currentStorageCountLimit.toString()
                  }
                  className={cn(
                    'w-32 rounded-full text-center',
                    storageCountLimit && 'border-primary'
                  )}
                />
                <span className="text-sm text-foreground">
                  <strong>Max</strong> storage volumes
                </span>
              </div>

              <div className="flex space-x-4 mt-3">
                <Button
                  variant="outline"
                  onClick={() => {
                    setUserLimit('')
                    setStorageCountLimit('')
                    setSelectedUser(null)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="w-[150px] mx-auto"
                  disabled={
                    !userLimit.trim() ||
                    !storageCountLimit.trim() ||
                    buttonStatus === 'loading' ||
                    buttonStatus === 'success'
                  }
                  onClick={() => {
                    if (buttonStatus === 'loading') return
                    setIsConfirming(true)
                  }}
                >
                  {buttonStatus === 'idle' && 'Apply Override'}
                  {buttonStatus === 'loading' && (
                    <Loader className="animate-spin" size={16} />
                  )}
                  {buttonStatus === 'success' && (
                    <>
                      Applied <Check className="inline ml-1" size={16} />
                    </>
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={isConfirming}
        onOpenChange={setIsConfirming}
        title="Override User Storage Volume Limit"
        description={
          <>
            You are about to update the storage volume limits for{' '}
            <b className="text-foreground/90">
              {userList.find((user) => user.user_id.toString() === selectedUser)
                ?.email || selectedUser}
            </b>{' '}
            to <b className="text-foreground/90">{userLimit} GB storage</b> and{' '}
            <b className="text-foreground/90">
              {storageCountLimit} storage volumes
            </b>
            . This change will override the organization-wide limit for this
            user.
          </>
        }
        buttons={{
          confirm: {
            label: 'Apply Override',
            isDestructive: true,
            isLoading: buttonStatus === 'loading',
          },
        }}
        confirmation={{
          text: `${userLimit},${storageCountLimit}`,
          prompt:
            "Please type the storage limit and volume count in format 'GB,Count' to confirm:",
        }}
        info={{
          warningPoints: [
            `This user's storage limit will be set to ${userLimit} GB with a maximum of ${storageCountLimit} storage volumes.`,
            'This limit will apply to future resources. Existing resources will continue running based on the limits from when they were created.',
          ],
        }}
        onConfirm={() => {
          setButtonStatus('loading')
          updateOrgUserLimitMutation.mutate(
            {
              org_per_user_storage_gb: Number(userLimit),
              org_per_user_storage_count: Number(storageCountLimit),
              user_id: Number(selectedUser),
            },
            {
              onSuccess: () => {
                setButtonStatus('success')
                refetchOrgUserLimit()
                setIsConfirming(false)
              },
              onError: (err) => {
                console.error(err)
                setButtonStatus('idle')
                setIsConfirming(false)
              },
            }
          )
        }}
        onCancel={() => {
          setIsConfirming(false)
        }}
      />
    </Card>
  )
}
