import { z } from 'zod'

// Base schema for file uploads
export const FileUploadSchema = z.instanceof(File).optional()

// Common validation messages
export const ValidationMessages = {
  required: (field: string) => `${field} is required`,
  maxLength: (field: string, max: number) =>
    `${field} must be less than ${max} characters.`,
} as const

// Common form field types
export type FileUploadField = z.infer<typeof FileUploadSchema>

// Form specific types
export type ProfileFormValues = {
  firstName: string
  lastName: string
  avatar: FileUploadField
}

export type OrgProfileFormValues = {
  orgName: string
  department: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  logo: FileUploadField
}
