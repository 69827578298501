import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  getFormattedNewBalance,
  useGetOrgUserBalanceSummary,
  useCreateOrgUserPayment,
} from '@/services/billing'
import { DollarSign, ArrowRight, User, PlusCircle } from 'lucide-react'
import { useState, useEffect } from 'react'
import { presets } from './consts'
import { Spinner } from '@/components/spinner'
import { UserBalanceDetailsDialog } from '@/components/balance-details-dialog'
import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { cn } from '@/lib/utils'

export interface UserTopUpFormProps {
  userId: number
  variant?: 'display-only' | 'full'
  onClose?: () => void
}

export const UserTopUpForm = ({
  userId,
  variant = 'full',
  onClose,
}: UserTopUpFormProps) => {
  const [amount, setAmount] = useState<number | null>(null)
  const [amountError, setAmountError] = useState<string | null>(null)
  const [hasDecimal, setHasDecimal] = useState<boolean>(false)
  const [animatedBalance, setAnimatedBalance] = useState<string | null>(null)
  const { mutate: createOrgUserPayment, isPending } = useCreateOrgUserPayment()

  const { data, isLoading, isError, error } =
    useGetOrgUserBalanceSummary(userId)

  // Animation effect when amount changes
  useEffect(() => {
    if (data && variant === 'full' && amount !== null) {
      setAnimatedBalance(getFormattedNewBalance(data.netBalance, amount))
    } else {
      setAnimatedBalance(null)
    }
  }, [amount, data, variant])

  const handleTopUp = () => {
    if (!amount || amount <= 0) {
      alert('Please enter a valid amount')
      return
    }
    if (amount < 10) {
      alert('Amount must be at least $10')
      return
    }
    createOrgUserPayment(
      { user_id: userId, amount },
      {
        onSuccess: (response) => {
          const stripeUrl = response.data?.id
          if (stripeUrl) {
            window.location.href = stripeUrl
          } else {
            alert('Failed to retrieve Stripe payment URL. Please try again.')
          }
        },
        onError: (error) => {
          console.error('Payment error:', error)
          alert('Failed to initiate payment. Please try again.')
        },
      }
    )
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    
    // Check if input contains a decimal point
    const containsDecimal = value.includes('.')
    setHasDecimal(containsDecimal)
    
    // Allow decimal input to be displayed as-is, but still handle validation
    const numValue = value === '' ? null : Number(value)
    setAmount(numValue)

    if (numValue !== null) {
      if (numValue < 10) {
        setAmountError('Minimum amount is $10')
      } else {
        setAmountError(null)
      }
    } else {
      setAmountError(null)
    }
  }

  const handlePillClick = (value: number) => {
    setAmount(value)
    setAmountError(null)
    setHasDecimal(false)
  }

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return (
      <p className="text-sm text-destructive">
        Error fetching personal balance: {error?.toString()}
      </p>
    )
  }

  if (!data) {
    return (
      <p className="text-sm text-destructive">
        No personal balance data available
      </p>
    )
  }

  const balanceContent = (
    <div className="space-y-4">
      <div>
        {variant === 'display-only' && (
          <div>
            <p className="text-2xl font-bold text-primary">
              {data.formattedNetBalance}
            </p>
          </div>
        )}

        {variant === 'full' && (
          <>
            <div className="flex items-center justify-between text-sm text-foreground/60 mb-2">
              <span>Balance Summary</span>
              <DollarSign className="w-4 h-4" />
            </div>

            <div className="p-4 bg-foreground/5 rounded-lg border">
              {/* Current and New Balance Display */}
              <div className="relative flex justify-between items-baseline min-h-[64px]">
                <div>
                  <span className="text-sm text-foreground/60">Current</span>
                  <p className="text-xl font-semibold">
                    {data.formattedNetBalance}
                  </p>
                </div>

                {amount && amount > 0 && (
                  <>
                    <div className="absolute left-1/2 top-[40%] -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-foreground/60 z-10">
                      <div className="flex items-center bg-background/90 rounded-full px-3 py-1.5 shadow-sm border">
                        <PlusCircle className="w-4 h-4 mr-1.5 flex-shrink-0 text-green-500" />
                        <span className="text-sm font-medium">
                          ${amount.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    <div className="text-right">
                      <span className="text-sm text-foreground/60">
                        New Balance
                      </span>
                      <p className="text-xl font-bold text-primary animate-pulse">
                        {animatedBalance}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {variant === 'display-only' && (
        <UserBalanceDetailsDialog
          trigger={
            <Button
              variant="link"
              className="h-auto p-0 text-sm text-foreground/60"
            >
              View Balance Details
              <ArrowRight size={16} className="ml-1" />
            </Button>
          }
          data={{
            ...data,
            usage_percent: data.usage_percent,
            showBillingLink: false,
          }}
        />
      )}
    </div>
  )

  if (variant === 'display-only') {
    return balanceContent
  }

  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle className="flex items-center gap-2">
          <User className="w-5 h-5 text-primary" />
          Top Up Personal Credits
        </DialogTitle>
      </DialogHeader>

      <div className="space-y-6">
        {balanceContent}

        <div className="space-y-5">
          <div className="grid grid-cols-4 gap-3">
            {presets.map((preset) => (
              <Button
                key={preset}
                variant={amount === preset ? 'default' : 'outline'}
                onClick={() => handlePillClick(preset)}
                className={cn('w-full transition-all')}
              >
                ${preset}
              </Button>
            ))}
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <span className="text-sm text-foreground/60 w-16 text-right">
                Custom:
              </span>
              <Input
                type="number"
                placeholder="Enter amount"
                value={amount || ''}
                onChange={handleAmountChange}
                step="1"
                min="10"
                className={cn(
                  'focus-visible:ring-primary',
                  amountError || hasDecimal ? 'border-destructive' : ''
                )}
              />
            </div>
            {amountError && (
              <p className="text-sm text-destructive pl-16">{amountError}</p>
            )}
            {hasDecimal && (
              <p className="text-sm text-destructive pl-16">Only whole amounts are allowed</p>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-2">
          {onClose && (
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            variant="default"
            onClick={handleTopUp}
            disabled={isPending || !amount || amount < 10 || hasDecimal}
          >
            {isPending ? (
              <>
                <Spinner className="mr-2" /> Processing...
              </>
            ) : (
              <>Top Up ${amount?.toLocaleString() || '0'}</>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
