import { useMutation, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { APIFetch, MutProps, useTokenAndOrgId } from '../utils'

// Helper for formatting currency
const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

// Helper for calculating new balance with additional amount
export const getFormattedNewBalance = (
  currentBalance: number,
  additionalAmount: number | null
) => {
  if (!additionalAmount || additionalAmount <= 0) {
    return formatCurrency(currentBalance)
  }
  return formatCurrency(currentBalance + additionalAmount)
}

// ======== useCreateOrgPayment ========

interface CreateOrgPaymentPayload {
  amount: number
}

interface CreateOrgPaymentResponse {
  status: string
  data: {
    url: string
    id: string
  }
}

export const useCreateOrgPayment = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()

  const getBillingUrl = (): string => {
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href)
      return `${currentUrl.origin}${currentUrl.pathname}`
    }
    return ''
  }

  return useMutation<CreateOrgPaymentResponse, Error, CreateOrgPaymentPayload>({
    mutationFn: async (payload: CreateOrgPaymentPayload) => {
      const billingUrl = getBillingUrl()

      const result = await APIFetch.post('createOrgPayment', {
        token,
        body: {
          org_id: orgId,
          amount: payload.amount,
          callback_cancel_url: billingUrl,
          callback_success_url: billingUrl,
        },
        orgId,
      })

      return result
    },
    ...props,
  })
}

// ======== useCreateUserPayment ========
interface CreateOrgUserPaymentPayload {
  user_id: number
  amount: number
}

interface CreateOrgUserPaymentResponse {
  status: string
  data: {
    url: string
    id: string
  }
}

export const useCreateOrgUserPayment = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId()

  const getBillingUrl = (): string => {
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href)
      return `${currentUrl.origin}${currentUrl.pathname}`
    }
    return ''
  }

  return useMutation<
    CreateOrgUserPaymentResponse,
    Error,
    CreateOrgUserPaymentPayload
  >({
    mutationFn: async (payload: CreateOrgUserPaymentPayload) => {
      const billingUrl = getBillingUrl()

      const result = await APIFetch.post('createOrgUserPayment', {
        token,
        body: {
          org_id: orgId,
          user_id: payload.user_id,
          amount: payload.amount,
          callback_cancel_url: billingUrl,
          callback_success_url: billingUrl,
        },
        orgId,
      })
      return result
    },
    ...props,
  })
}

// ======== useGetUserBalance ========
interface GetOrgUserBalanceSummaryReturn {
  total: number
  breakdown: {
    spending_limit: number
    total_user_balance: number
  }
  spent_total: number
  usage_percent: number
}

interface FormattedOrgUserBalanceSummary
  extends GetOrgUserBalanceSummaryReturn {
  netBalanceCents: number
  netBalance: number
  formattedNetBalance: string
  formattedTotal: string
  formattedSpentTotal: string
  formattedSpendingLimit: string
  formattedTotalUserBalance: string
}

export const useGetOrgUserBalanceSummary = (user_id: number) => {
  const { token, orgId } = useTokenAndOrgId()

  return useQuery({
    queryKey: ['org-user-balance-summary', token, orgId, user_id],
    enabled: !!token && !!orgId && !!user_id,
    queryFn: async (): Promise<FormattedOrgUserBalanceSummary> => {
      const result = await APIFetch.post('getOrgUserBalanceSummary', {
        token,
        body: {
          org_id: orgId,
          user_id,
        },
        orgId,
      })

      if (result.error) {
        throw new Error(`Error: ${result.error}`)
      }
      if (result.status !== 'success') {
        throw new Error(
          'Failed to load org user balance summary, please try again.'
        )
      }

      const data = result.data as GetOrgUserBalanceSummaryReturn
      const netBalanceCents = data.total - data.spent_total
      const netBalance = netBalanceCents / 100

      return {
        ...data,
        netBalanceCents,
        netBalance,
        formattedNetBalance: formatCurrency(netBalance),
        formattedTotal: formatCurrency(data.total / 100),
        formattedSpentTotal: formatCurrency(data.spent_total / 100),
        formattedSpendingLimit: formatCurrency(
          data.breakdown.spending_limit / 100
        ),
        formattedTotalUserBalance: formatCurrency(
          data.breakdown.total_user_balance / 100
        ),
      }
    },
  })
}

// ======== useGetOrgInvoices ========
interface GetOrgInvoicesPayload {
  start_at?: number
  end_at?: number
  page_number?: number
  page_size?: number
  sort_by?: string
  sort_direction?: 'asc' | 'desc'
  search?: string
}

interface InvoiceItem {
  amount_cent: number
  created_at: string
  invoice_type: string
  metadata: {
    download_page?: string
  }
  org_id: number
  stripe_invoice_id: string
  User: {
    created_at: string
    email: string
    updated_at: string
    user_id: number
  }
  UserOrganization?: {
    profile_metadata?: {
      first_name?: string
      last_name?: string
      avatar_preview_url?: string
    }
  }
  user_id: number
}

interface FormattedInvoiceItem extends InvoiceItem {
  formattedAmount: string
  formattedDate: string
  invoiceTypeLabel: string
}

interface GetOrgInvoicesResponse {
  data: {
    items: FormattedInvoiceItem[]
    total: number
  }
  error?: string
  status: string
}

export const useGetOrgInvoices = (
  filters: Omit<GetOrgInvoicesPayload, 'page_number' | 'page_size'>,
  pageSize: number = 10
) => {
  const { token, orgId } = useTokenAndOrgId()

  return useInfiniteQuery<GetOrgInvoicesResponse, Error>({
    queryKey: ['org-invoices', token, orgId, filters],
    enabled: !!token && !!orgId,
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = await APIFetch.post('getOrgInvoices', {
        token,
        body: {
          org_id: orgId,
          start_at: filters.start_at,
          end_at: filters.end_at,
          sort_by: filters.sort_by,
          sort_direction: filters.sort_direction,
          search: filters.search,
          page_number: pageParam,
          page_size: pageSize,
        },
        orgId,
      })

      // Transform data here
      if (response.data?.items) {
        response.data.items = response.data.items.map(
          (invoice: InvoiceItem) => {
            let invoiceTypeLabel = 'N/A'
            switch (invoice.invoice_type) {
              case 'user_credit':
                invoiceTypeLabel = 'User Credit'
                break
              case 'org_credit':
                invoiceTypeLabel = 'Organization Credit'
                break
              default:
                break
            }

            return {
              ...invoice,
              formattedAmount: formatCurrency(invoice.amount_cent / 100),
              formattedDate: new Date(invoice.created_at).toLocaleDateString(
                'en-US',
                {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }
              ),
              invoiceTypeLabel,
            }
          }
        )
      }

      return response
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalItems = lastPage.data?.total || 0
      const fetchedItems = allPages.length * pageSize
      return fetchedItems < totalItems ? allPages.length + 1 : undefined
    },
  })
}

// ======== useGetOrgUserInvoices ========

interface GetOrgUserInvoicesPayload {
  start_at?: number
  end_at?: number
  page_number?: number
  page_size?: number
  sort_by?: string
  sort_direction?: 'asc' | 'desc'
  search?: string
}

interface GetOrgUserInvoicesResponse {
  data: {
    items: FormattedInvoiceItem[]
    total: number
  }
  error?: string
  status: string
}

export const useGetOrgUserInvoices = (
  user_id: number,
  filters: Omit<GetOrgUserInvoicesPayload, 'page_number' | 'page_size'>,
  pageSize: number = 10
) => {
  const { token, orgId } = useTokenAndOrgId()

  return useInfiniteQuery<GetOrgUserInvoicesResponse, Error>({
    queryKey: ['org-user-invoices', token, orgId, user_id, filters],
    enabled: !!token && !!orgId && !!user_id,
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const response = await APIFetch.post('getOrgUserInvoices', {
        token,
        body: {
          user_id,
          org_id: orgId,
          start_at: filters.start_at,
          end_at: filters.end_at,
          sort_by: filters.sort_by,
          sort_direction: filters.sort_direction,
          page_number: pageParam,
          page_size: pageSize,
          search: filters.search,
        },
        orgId,
      })

      if (response.error) {
        throw new Error(`Error: ${response.error}`)
      }

      // Transform data here
      if (response.data?.items) {
        response.data.items = response.data.items.map(
          (invoice: InvoiceItem) => {
            let invoiceTypeLabel = 'N/A'
            switch (invoice.invoice_type) {
              case 'user_credit':
                invoiceTypeLabel = 'User Credit'
                break
              case 'org_credit':
                invoiceTypeLabel = 'Organization Credit'
                break
              default:
                break
            }

            return {
              ...invoice,
              formattedAmount: formatCurrency(invoice.amount_cent / 100),
              formattedDate: new Date(invoice.created_at).toLocaleDateString(
                'en-US',
                {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }
              ),
              invoiceTypeLabel,
            }
          }
        )
      }

      return response
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalItems = lastPage.data?.total || 0
      const fetchedItems = allPages.length * pageSize
      return fetchedItems < totalItems ? allPages.length + 1 : undefined
    },
  })
}
