import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from '@/components/ui/dialog'
import {
  ChevronRight,
  ChevronLeft,
  Edit2,
  Plus,
  X,
  Microchip,
  Fan,
  Disc3,
  ChevronDown,
  Lock,
  HardDrive,
  Network,
} from 'lucide-react'
import { useListServerTemplates } from '@/services/template'
import { useCreateServerInstance } from '@/services/server'
import { useListStorageVolumes } from '@/services/storage'
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select'
import { StorageVolume } from '@/types'
import { useTokenAndOrgId } from '@/services/utils'
import { useNavigate } from '@tanstack/react-router'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { toast } from 'react-toastify'
import { ServerTemplateCard as TemplateCard } from '../DashboardTemplates/ServerTemplateCard'
import { ServerTemplateResponse } from '@/services/template/types'
import { useGetSSHKeys } from '@/services/sshkeys'
import { Spinner } from '@/components/spinner'
import { cn } from '@/lib/utils'
import { ServerInstanceRequestData } from './types'
import { useUserStore } from '@/store/UserStore'
import { SetupSSHKeysDialog } from './SetupSSHKeysDialog'
import { SetupStorageDialog } from './SetupStorageDialog'
import { useCurrentUserOrgLimit } from '@/hooks/useCurrentUserOrgLimit'
import { Link } from '@tanstack/react-router'
import { SpecCard } from '@/components/spec-card'

const formSchema = z.object({
  serverName: z
    .string()
    .min(2, {
      message: 'Server name must be at least 2 characters.',
    })
    .max(100, {
      message: 'Server name cannot exceed 100 characters.',
    }),
  sshAccess: z.boolean(),
  storageVolumeId: z.string().nullable().optional(),
  httpPorts: z.array(z.string().regex(/^\d+$/, 'Must be a valid port number')),
})

export const DashboardNewServer = () => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const { setHasCreatedFirstServer } = useUserStore((s) => ({
    setHasCreatedFirstServer: s.setHasCreatedFirstServer,
  }))

  const [step, setStep] = useState(0)
  const [selectedTemplate, setSelectedTemplate] =
    useState<ServerTemplateResponse | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [newPort, setNewPort] = useState('')
  const { orgId } = useTokenAndOrgId()
  const navigate = useNavigate()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false)

  const userLimit = useCurrentUserOrgLimit()

  const serverInstanceLimit = userLimit
    ? userLimit.org_per_user_server_count
    : 0

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      serverName: '',
      sshAccess: false,
      storageVolumeId: undefined,
      httpPorts: [],
    },
  })

  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
  } = useListServerTemplates()
  const {
    data: volumeData,
    isLoading: isLoadingVolumes,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isError: isErrorVolumes,
  } = useListStorageVolumes({})
  const { data: sshKeys, isLoading: isLoadingSSHKeys } = useGetSSHKeys()

  // Flatten the volumes from all pages into a single array
  const volumes: StorageVolume[] =
    (volumeData?.allVolumes as StorageVolume[]) || []

  const handleTemplateSelect = (template: ServerTemplateResponse) => {
    setSelectedTemplate(template)
    form.setValue('serverName', template.template_name)
    form.setValue('sshAccess', template.support_ssh)
  }

  useEffect(() => {
    if (!isLoadingTemplates) {
      if (templates && templates.length > 0) {
        setSelectedTemplate(templates[0])
        form.setValue('serverName', templates[0].template_name)
        form.setValue('sshAccess', templates[0].support_ssh)
      }
    }
  }, [templates, isLoadingTemplates, form])

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleNext = () => {
    if (selectedTemplate) {
      setStep(1)
    }
  }

  const handleNameChange = () => {
    if (selectedTemplate) {
      const newName = form.getValues('serverName')
      setSelectedTemplate({ ...selectedTemplate, template_name: newName })
      setIsDialogOpen(false)
    }
  }

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false)
  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const maxPorts = 3 // Max number of ports

  const handleAddPort = () => {
    if (!selectedTemplate) {
      console.error('selected template is null', { selectedTemplate })
      toast.error('template error')
      return
    }

    if (!newPort) {
      form.setError('httpPorts', {
        type: 'manual',
        message: 'Invalid port number.',
      })
      return
    }

    if (
      selectedTemplate.service_ports
        .map((p) => p.port)
        .includes(Number(newPort))
    ) {
      form.setError('httpPorts', {
        type: 'manual',
        message: 'This port is already in use by the template.',
      })
      return
    }

    const currentPorts = form.getValues('httpPorts')

    const portNumber = parseInt(newPort, 10)
    if (isNaN(portNumber) || portNumber < 1000 || portNumber > 99999) {
      form.setError('httpPorts', {
        type: 'manual',
        message: 'Port must be a number between 1000 and 99999.',
      })
      return
    }

    if (currentPorts.includes(newPort)) {
      form.setError('httpPorts', {
        type: 'manual',
        message: 'This port has already been added.',
      })
      return
    }

    if (currentPorts.length >= maxPorts) {
      form.setError('httpPorts', {
        type: 'manual',
        message: `You can only add up to ${maxPorts} ports.`,
      })
      return
    }

    form.setValue('httpPorts', [...currentPorts, newPort])
    setNewPort('')
    setIsInputDisabled(currentPorts.length + 1 === maxPorts)
    setIsAddButtonDisabled(currentPorts.length + 1 === maxPorts)
    form.clearErrors('httpPorts')
  }

  const handlePortInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (/^\d{0,5}$/.test(value)) {
      setNewPort(value)
      if (value) {
        setIsAddButtonDisabled(false)
      }
    }
  }

  const handleRemovePort = (port: string) => {
    const updatedPorts = form
      .getValues('httpPorts')
      .filter((p: string) => p !== port)

    form.setValue('httpPorts', updatedPorts)

    if (updatedPorts.length < maxPorts) {
      setIsInputDisabled(false)
      setIsAddButtonDisabled(false)
    }
  }

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const createServerMutation = useCreateServerInstance()
  const handleServerCreation = (values: z.infer<typeof formSchema>) => {
    if (!selectedTemplate || !orgId) {
      return
    }

    const requestData: ServerInstanceRequestData = {
      instanceName: values.serverName,
      templateId: selectedTemplate.template_id,
      orgId,
      allowSSH: values.sshAccess,
      extraPorts: values.httpPorts.map(Number),
    }

    if (values.storageVolumeId) {
      requestData.storageVolumeId = parseInt(values.storageVolumeId, 10)
    }

    createServerMutation.mutate(requestData, {
      onSuccess: () => {
        setHasCreatedFirstServer()
        navigate({ to: `/${orgId}/dashboard/servers` })
        setErrorMessage(null)
      },
      onError: (error: unknown) => {
        toast.error(`${error}`)
      },
    })
  }

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const trimmedValues = {
      ...values,
      serverName: values.serverName.trim(),
    }

    handleServerCreation(trimmedValues)
  }

  const handleDeployClick = () => {
    setIsConfirmationDialogOpen(true)
  }
  const handleConfirmDeploy = () => {
    setIsConfirmationDialogOpen(false)
    form.handleSubmit(onSubmit)() // Proceed with form submission
  }
  const handleCancelDeploy = () => {
    setIsConfirmationDialogOpen(false)
  }

  return (
    <Form {...form}>
      <form className="w-full mx-auto">
        {errorMessage && (
          <div className="text-destructive/60 mb-4">{errorMessage}</div>
        )}
        {step === 0 && (
          <div className="relative min-h-[400px]">
            <div className="flex items-center justify-between mb-8">
              <div>
                <h1 className="text-3xl font-bold mb-2">Choose A Template</h1>
                <p className="text-muted-foreground">
                  Select a template to configure your new server instance
                </p>
              </div>
              {templates && templates.length > 0 && (
                <p className="text-sm text-muted-foreground">
                  {templates.length} template{templates.length !== 1 ? 's' : ''}{' '}
                  available
                </p>
              )}
            </div>

            {isLoadingTemplates ? (
              <div className="flex items-center justify-center min-h-[400px]">
                <Spinner />
              </div>
            ) : isErrorTemplates ? (
              <div className="flex items-center justify-center min-h-[400px] text-destructive">
                <p>Failed to load templates. Please try again.</p>
              </div>
            ) : templates && templates?.length > 0 ? (
              <>
                <div
                  className={cn(
                    'grid gap-6',
                    templates.length === 1
                      ? 'max-w-4xl'
                      : 'grid-cols-1 lg:grid-cols-2'
                  )}
                >
                  {templates.map((template) => (
                    <TemplateCard
                      key={template.template_id}
                      id={String(template.template_id)}
                      name={template.template_name}
                      specs={{
                        cpu: {
                          count: Number(template.cpu_count),
                          ramGb: Number(template.ram_gb),
                        },
                        gpu: {
                          count: Number(template.gpu_request_count),
                          memoryGb: Number(template.gpu_request_gmem),
                        },
                        machineImage: template.machine_image,
                      }}
                      price={parseFloat((template.price / 100).toFixed(2))}
                      selected={selectedTemplate === template}
                      onSelect={() => handleTemplateSelect(template)}
                    />
                  ))}
                </div>

                {/* Clean, Minimal Sticky Footer */}
                {selectedTemplate && (
                  <div className="sticky bottom-5 mt-8 pb-2 z-20">
                    <div className="flex justify-start pointer-events-none">
                      <div className="pointer-events-auto flex items-center bg-card border rounded-lg shadow-lg p-4 animate-in fade-in slide-in-from-bottom-5">
                        <div className="mr-4">
                          <div className="text-sm text-muted-foreground mb-1">
                            Selected Template
                          </div>
                          <div className="font-medium">
                            {selectedTemplate.template_name}
                          </div>
                        </div>
                        <Button
                          type="button"
                          onClick={handleNext}
                          className="ml-2"
                        >
                          Configure <ChevronRight className="ml-2 h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center justify-center min-h-[400px] bg-muted/30 rounded-lg p-8 text-center">
                <p className="text-lg font-medium mb-2">
                  No templates available
                </p>
                <p className="text-sm text-muted-foreground">
                  Please contact your administrator to set up server templates
                </p>
              </div>
            )}
          </div>
        )}

        {step === 1 && selectedTemplate && (
          <div>
            <h1 className="text-3xl font-medum mb-6">Edit Configurations</h1>
            <Card>
              <CardHeader>
                <CardTitle className="flex justify-between items-center">
                  <span className="flex items-center text-2xl">
                    {selectedTemplate.template_name}
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <DialogTrigger asChild>
                        <Button variant="ghost" size="icon" className="ml-2">
                          <Edit2 className="h-4 w-4" />
                          <span className="sr-only">Edit Server Name</span>
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Edit Server Name</DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4 py-4">
                          <FormField
                            control={form.control}
                            name="serverName"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>
                                  New Server Name{' '}
                                  <span className="text-primary/50">
                                    (Server names have a maximum length of 50
                                    characters)
                                  </span>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="Enter new Server name"
                                    maxLength={50}
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <div className="flex justify-end">
                            <Button
                              onClick={handleNameChange}
                              disabled={!form.getValues('serverName')}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </span>
                </CardTitle>
              </CardHeader>

              <CardContent className="space-y-6">
                {/* Hardware Specs - Using the same card layout as the template selection */}
                <div className="space-y-3">
                  <h3 className="text-sm font-medium text-muted-foreground">
                    Hardware Specifications
                  </h3>
                  <div
                    className={cn(
                      'grid gap-4',
                      'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'
                    )}
                  >
                    <SpecCard
                      icon={<Fan className="h-5 w-5" />}
                      title="GPU"
                      items={[
                        {
                          label: 'Count',
                          value: `${selectedTemplate.gpu_request_count}x`,
                        },
                        {
                          label: 'VRAM',
                          value: `${selectedTemplate.gpu_request_gmem} GB`,
                        },
                      ]}
                      variant="gpu"
                      minHeight="min-h-[80px]"
                    />

                    <SpecCard
                      icon={<Microchip className="h-5 w-5" />}
                      title="CPU"
                      items={[
                        {
                          label: 'Count',
                          value: `${selectedTemplate.cpu_count}x`,
                        },
                      ]}
                      minHeight="min-h-[80px]"
                    />

                    <SpecCard
                      icon={<Microchip className="h-5 w-5 opacity-50" />}
                      title="Memory"
                      items={[
                        {
                          label: 'RAM',
                          value: `${selectedTemplate.ram_gb} GB`,
                        },
                      ]}
                      minHeight="min-h-[80px]"
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-3 bg-muted/30 rounded p-3">
                    <Disc3 className="h-5 w-5 text-primary" />
                    <span className="text-sm font-medium">Machine Image:</span>
                    <span className="text-sm text-muted-foreground">
                      {selectedTemplate.machine_image}
                    </span>
                  </div>
                </div>

                <div className="h-px bg-border/60 my-3"></div>

                {/* Advanced Options - Card Layout with prominent toggle */}
                <div className="space-y-3">
                  <button
                    type="button"
                    onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                    className={cn(
                      'w-full flex items-center justify-between py-3 px-4 rounded-lg border transition-all',
                      'hover:border-primary/50 hover:bg-muted/20 group',
                      showAdvancedOptions
                        ? 'bg-muted/30 border-primary/30'
                        : 'bg-muted/10 border-border/40'
                    )}
                  >
                    <div className="flex items-center gap-2">
                      <div
                        className={cn(
                          'rounded-md p-1.5 transition-colors',
                          showAdvancedOptions
                            ? 'bg-primary/20'
                            : 'bg-muted group-hover:bg-muted/80'
                        )}
                      >
                        <ChevronDown
                          className={cn(
                            'w-4 h-4 transition-transform duration-200',
                            showAdvancedOptions
                              ? 'transform rotate-180 text-primary'
                              : 'text-muted-foreground'
                          )}
                        />
                      </div>
                      <h3 className="text-sm font-medium">Advanced Options</h3>
                    </div>
                    <span
                      className={cn(
                        'text-xs px-2 py-1 rounded-full transition-colors',
                        showAdvancedOptions
                          ? 'bg-primary/20 text-primary'
                          : 'bg-muted/50 text-muted-foreground group-hover:bg-muted/70 group-hover:text-foreground'
                      )}
                    >
                      {showAdvancedOptions ? 'Hide options' : 'Show options'}
                    </span>
                  </button>

                  {showAdvancedOptions && (
                    <div
                      className={cn(
                        'grid gap-4 animate-in fade-in-50 pt-2',
                        'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'
                      )}
                    >
                      {/* SSH Access Card */}
                      <div
                        className={cn(
                          'space-y-2 rounded-lg p-3 min-h-[90px] border transition-all',
                          'bg-gradient-to-br from-muted/90 to-muted/40',
                          'border-border/40'
                        )}
                      >
                        <div className="flex items-center justify-between border-b border-border/40 pb-2">
                          <div className="flex items-center gap-2">
                            <div className="rounded-md p-1 bg-muted/90">
                              <Lock className="h-5 w-5" />
                            </div>
                            <span className="text-sm font-semibold">
                              SSH Access
                            </span>
                          </div>
                          <Link
                            to={`/${orgId}/dashboard/sshkeys`}
                            className="text-xs font-medium text-primary hover:text-primary/80 transition-colors"
                          >
                            Manage
                          </Link>
                        </div>

                        <FormField
                          control={form.control}
                          name="sshAccess"
                          render={({ field }) => (
                            <div className="space-y-2">
                              <div className="flex items-center justify-between gap-3">
                                <FormLabel className="text-xs text-muted-foreground m-0">
                                  Status
                                </FormLabel>
                                <div className="flex items-center gap-2">
                                  <span className="text-xs font-medium">
                                    {field.value ? 'Enabled' : 'Disabled'}
                                  </span>
                                  <FormControl>
                                    <Switch
                                      disabled={
                                        !sshKeys || sshKeys.length === 0
                                      }
                                      checked={field.value}
                                      onCheckedChange={field.onChange}
                                      className="data-[state=checked]:bg-primary"
                                    />
                                  </FormControl>
                                </div>
                              </div>

                              {(!sshKeys || sshKeys?.length === 0) &&
                                !isLoadingSSHKeys && (
                                  <div className="flex items-center justify-end">
                                    <SetupSSHKeysDialog orgId={orgId} />
                                  </div>
                                )}
                            </div>
                          )}
                        />
                      </div>

                      {/* Storage Volume Card */}
                      <div
                        className={cn(
                          'space-y-2 rounded-lg p-3 min-h-[90px] border transition-all',
                          'bg-gradient-to-br from-muted/90 to-muted/40',
                          'border-border/40'
                        )}
                      >
                        <div className="flex items-center justify-between border-b border-border/40 pb-2">
                          <div className="flex items-center gap-2">
                            <div className="rounded-md p-1 bg-muted/90">
                              <HardDrive className="h-5 w-5" />
                            </div>
                            <span className="text-sm font-semibold">
                              Storage Volume
                            </span>
                          </div>
                          <Link
                            to={`/${orgId}/dashboard/storage`}
                            className="text-xs font-medium text-primary hover:text-primary/80 transition-colors"
                          >
                            Manage
                          </Link>
                        </div>

                        <FormField
                          control={form.control}
                          name="storageVolumeId"
                          render={({ field }) => (
                            <div className="space-y-2">
                              <div className="flex items-center justify-between gap-3">
                                <FormLabel className="text-xs text-muted-foreground m-0">
                                  Attached
                                </FormLabel>
                                <div className="w-full max-w-[120px]">
                                  <FormControl>
                                    <Select
                                      onValueChange={field.onChange}
                                      value={field.value || undefined}
                                      disabled={
                                        !volumes || volumes.length === 0
                                      }
                                    >
                                      <SelectTrigger className="h-7 text-xs bg-background border-border/50">
                                        <SelectValue placeholder="Select..." />
                                      </SelectTrigger>
                                      <SelectContent>
                                        {volumes.map(
                                          (volume: StorageVolume) => (
                                            <SelectItem
                                              key={volume.id}
                                              value={volume.id.toString()}
                                              className="text-xs"
                                            >
                                              {volume.name} - {volume.size_gb}{' '}
                                              GB
                                            </SelectItem>
                                          )
                                        )}
                                      </SelectContent>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              {(!volumes || volumes?.length === 0) &&
                                !isLoadingVolumes && (
                                  <div className="flex items-center justify-end">
                                    <SetupStorageDialog orgId={orgId} />
                                  </div>
                                )}
                            </div>
                          )}
                        />
                      </div>

                      {/* HTTP Ports Card */}
                      <div
                        className={cn(
                          'space-y-2 rounded-lg p-3 min-h-[90px] border transition-all',
                          'bg-gradient-to-br from-muted/90 to-muted/40',
                          'border-border/40'
                        )}
                      >
                        <div className="flex items-center gap-2 border-b border-border/40 pb-2">
                          <div className="rounded-md p-1 bg-muted/90">
                            <Network className="h-5 w-5" />
                          </div>
                          <span className="text-sm font-semibold">
                            HTTP Ports
                          </span>
                        </div>

                        <FormField
                          control={form.control}
                          name="httpPorts"
                          render={() => (
                            <div className="space-y-2">
                              <div className="flex items-center justify-between gap-3">
                                <FormLabel className="text-xs text-muted-foreground m-0">
                                  Template
                                </FormLabel>
                                <div className="flex flex-wrap justify-end gap-1">
                                  {selectedTemplate.service_ports &&
                                  selectedTemplate.service_ports.length > 0 ? (
                                    selectedTemplate.service_ports.map(
                                      (port) => (
                                        <span
                                          key={port.port}
                                          className="text-xs font-medium px-2 py-0.5 bg-secondary/20 border border-secondary/20 rounded-md"
                                        >
                                          {port.port}
                                        </span>
                                      )
                                    )
                                  ) : (
                                    <span className="text-xs px-2 py-0.5 bg-background border border-border/50 rounded-md">
                                      None
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="flex items-center justify-between gap-3">
                                <span className="text-xs text-muted-foreground">
                                  Custom
                                </span>
                                <div className="flex flex-wrap justify-end gap-1">
                                  {form.watch('httpPorts').length > 0 ? (
                                    form.watch('httpPorts').map((port) => (
                                      <div
                                        key={port}
                                        className="group flex items-center text-xs font-medium px-2 py-0.5 bg-primary/10 border border-primary/20 rounded-md"
                                      >
                                        {port}
                                        <Button
                                          type="button"
                                          variant="ghost"
                                          size="icon"
                                          className="h-3.5 w-3.5 ml-1 opacity-70 group-hover:opacity-100"
                                          onClick={() => handleRemovePort(port)}
                                        >
                                          <X className="h-2.5 w-2.5" />
                                        </Button>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="flex items-center gap-1.5">
                                      <span className="text-xs px-2 py-0.5 bg-background border border-border/50 rounded-md">
                                        None
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex items-center gap-1 mt-1">
                                <FormControl className="flex-1">
                                  <Input
                                    value={newPort}
                                    onChange={handlePortInputChange}
                                    placeholder="Add port..."
                                    disabled={isInputDisabled}
                                    className="h-7 text-xs py-0"
                                  />
                                </FormControl>
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  onClick={handleAddPort}
                                  disabled={isAddButtonDisabled || !newPort}
                                  className="h-7 w-7"
                                >
                                  <Plus className="h-3.5 w-3.5" />
                                </Button>
                                <FormMessage />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>

            {/* Configuration Sticky Footer */}
            <div className="sticky bottom-5 mt-8 pb-2 z-20">
              <div className="flex justify-start pointer-events-none">
                <div className="pointer-events-auto flex items-center bg-card border rounded-lg shadow-lg p-4 animate-in fade-in slide-in-from-bottom-5">
                  <Button
                    type="button"
                    onClick={handleBack}
                    variant="outline"
                    size="sm"
                    className="mr-3"
                  >
                    <ChevronLeft className="mr-1 h-4 w-4" /> Back
                  </Button>

                  <div className="h-8 w-px bg-border mx-2"></div>

                  <div className="mx-3">
                    <div className="text-sm text-muted-foreground mb-1">
                      Ready to Deploy
                    </div>
                    <div className="font-medium">
                      {selectedTemplate.template_name}
                    </div>
                  </div>

                  <Button
                    type="button"
                    onClick={handleDeployClick}
                    className="ml-2"
                  >
                    Deploy Server <ChevronRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {form.formState.errors.serverName && (
          <p className="text-destructive ml-4 pt-5">
            {form.formState.errors.serverName.message}
          </p>
        )}

        <div className="flex justify-between mt-6 items-center">
          {/* Back button moved to sticky footer in step 1 */}
          {step === 0 && <div />}
          {step === 0 ? (
            <div></div> // Empty div since we now have the sticky footer
          ) : (
            <div></div> // Empty div since we now have the sticky footer for step 1 too
          )}
        </div>

        {/* Confirmation Dialog */}
        <Dialog
          open={isConfirmationDialogOpen}
          onOpenChange={setIsConfirmationDialogOpen}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Server Deployment</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <p>
                Are you sure you want to deploy this server with the selected
                configurations?
              </p>
            </div>
            <Alert variant="warning">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Organization Limits:</AlertTitle>
              <AlertDescription>
                {serverInstanceLimit} active server instances
              </AlertDescription>
            </Alert>
            <DialogFooter>
              <Button variant="ghost" onClick={handleCancelDeploy}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDeploy}>Confirm</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </form>
    </Form>
  )
}
