import { useMemo } from 'react'
import { Card } from '@/components/ui/card'
import {
  GetOrgGraphParams,
  useGetOrgGraph,
  useGetOrgMetrics,
  useGetOrgUsageGraph,
} from '@/services/home'
import {
  Activity,
  ArrowDown,
  ArrowUp,
  DollarSign,
  Minus,
  Timer,
} from 'lucide-react'
import { LucideIcon } from 'lucide-react'
import dayjs from 'dayjs'
import { formatCurrency, cn } from '@/lib/utils'

// Color values for consistent theming
const COLORS = {
  primary: 'bg-primary',
  primaryLight: 'bg-primary/10',
  primaryText: 'text-primary',
  positiveText: 'text-emerald-600',
  negativeText: 'text-red-500',
}

type TimeRange = {
  key: string
  params: GetOrgGraphParams
}

type StatCardData = {
  label: string
  value: string
  delta: number | null
  icon: LucideIcon
  loading: boolean
  color: string
}

const TIME_RANGES: Record<string, TimeRange> = {
  last24hrs: {
    key: 'last24hrs',
    params: {
      start_at: dayjs().subtract(1, 'day').unix(),
      end_at: dayjs().unix(),
      interval: 'day',
    },
  },
  first24hrs: {
    key: 'first24hrs',
    params: {
      start_at: dayjs().subtract(2, 'day').unix(),
      end_at: dayjs().subtract(1, 'day').unix(),
      interval: 'day',
    },
  },
}

type TopStatsProps = {
  userId?: number
}

export const TopStats = ({ userId }: TopStatsProps) => {
  const { data: orgMetric, isLoading: isOrgMetricLoading } =
    useGetOrgMetrics(userId)

  const { data: orgUsageGraphLast24, isLoading: isOrgUsageGraphLoadingLast24 } =
    useGetOrgUsageGraph(TIME_RANGES.last24hrs.params, userId)
  const { data: orgGraphLast24, isLoading: isOrgGraphLoadingLast24 } =
    useGetOrgGraph(TIME_RANGES.last24hrs.params, userId)

  const {
    data: orgUsageGraphFirst24,
    isLoading: isOrgUsageGraphLoadingFirst24,
  } = useGetOrgUsageGraph(TIME_RANGES.first24hrs.params, userId)
  const { data: orgGraphFirst24, isLoading: isOrgGraphLoadingFirst24 } =
    useGetOrgGraph(TIME_RANGES.first24hrs.params, userId)

  const displayedData = useMemo((): StatCardData[] => {
    // Calculate spending values
    const spendingLast24 =
      (orgUsageGraphLast24?.items || []).reduce(
        (acc, item) => acc + item.total_cost,
        0
      ) || 0
    const spendingFirst24 =
      (orgUsageGraphFirst24?.items || []).reduce(
        (acc, item) => acc + item.total_cost,
        0
      ) || 0

    // Calculate hours values
    const hoursLast24 =
      (orgGraphLast24?.server_graph_data || []).reduce(
        (acc, item) => acc + item.active_servers,
        0
      ) || 0
    const hoursFirst24 =
      (orgGraphFirst24?.server_graph_data || []).reduce(
        (acc, item) => acc + item.active_servers,
        0
      ) || 0

    // Calculate percentage changes
    const calculateDelta = (
      current: number,
      previous: number
    ): number | null =>
      previous === 0
        ? null
        : Math.round(((current - previous) / previous) * 100)

    // Helper function to determine color based on delta
    const getDeltaColor = (delta: number | null): string => {
      if (delta === null) return 'text-foreground/40'
      if (delta > 0) return COLORS.positiveText
      if (delta < 0) return COLORS.negativeText
      return 'text-foreground/40'
    }

    return [
      {
        label: "Last 24 hours' spending",
        value: formatCurrency(spendingLast24 / 100),
        delta: calculateDelta(spendingLast24, spendingFirst24),
        icon: DollarSign,
        loading: isOrgUsageGraphLoadingLast24 || isOrgUsageGraphLoadingFirst24,
        color: getDeltaColor(calculateDelta(spendingLast24, spendingFirst24)),
      },
      {
        label: "Last 24 hours' usage",
        value: `${hoursLast24} hours`,
        delta: calculateDelta(hoursLast24, hoursFirst24),
        icon: Timer,
        loading: isOrgGraphLoadingLast24 || isOrgGraphLoadingFirst24,
        color: getDeltaColor(calculateDelta(hoursLast24, hoursFirst24)),
      },
      {
        label: 'Active Now',
        value: `${orgMetric?.server_instance_count || 0} Servers`,
        delta: null,
        icon: Activity,
        loading: isOrgMetricLoading,
        color: 'text-foreground/40',
      },
    ]
  }, [
    orgUsageGraphLast24,
    orgGraphLast24,
    orgUsageGraphFirst24,
    orgGraphFirst24,
    orgMetric,
    isOrgUsageGraphLoadingLast24,
    isOrgUsageGraphLoadingFirst24,
    isOrgGraphLoadingLast24,
    isOrgGraphLoadingFirst24,
    isOrgMetricLoading,
  ])

  return (
    <div className="grid gap-4 grid-cols-3 mb-6">
      {displayedData.map((item, index) => (
        <Card
          key={index}
          className={cn(
            'p-6 border-border/30 relative overflow-hidden',
            'hover:border-primary/30 hover:shadow-sm transition-all'
          )}
        >
          <div className="absolute inset-x-0 top-0 h-1 bg-primary/80" />

          <div className="flex justify-between mb-3">
            <span className="text-foreground/70">{item.label}</span>
            <div className="p-2 rounded-full bg-primary/10">
              <item.icon className="w-4 h-4 text-primary" />
            </div>
          </div>

          {item.loading ? (
            <>
              <div className="h-7 w-2/3 bg-foreground/10 rounded animate-pulse" />
              {item.delta !== null && (
                <div className="h-4 w-1/2 bg-foreground/10 rounded mt-2 animate-pulse" />
              )}
            </>
          ) : (
            <>
              <div className="text-2xl font-bold">{item.value}</div>

              {item.delta !== null && (
                <div className="flex items-center gap-1 mt-1 text-sm">
                  {item.delta > 0 ? (
                    <ArrowUp className="w-3.5 h-3.5 text-emerald-600" />
                  ) : item.delta < 0 ? (
                    <ArrowDown className="w-3.5 h-3.5 text-destructive" />
                  ) : (
                    <Minus className="w-3.5 h-3.5 text-foreground/40" />
                  )}
                  <span className="text-foreground/70">
                    {`${Math.abs(item.delta)}% from day before`}
                  </span>
                </div>
              )}
            </>
          )}
        </Card>
      ))}
    </div>
  )
}
