import React, { useCallback } from 'react'
import { useParams, useNavigate } from '@tanstack/react-router'
import { useTokenAndOrgId } from '@/services/utils'
import { useGetServerInstance } from '@/services/server'
import { Spinner } from '@/components/spinner'
import { Separator } from '@/components/ui/separator'
import { useElapsedTime, formatDate } from '@/helpers/time'
import { ChevronLeft, Plus, DollarSign } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { DeleteServerDialog } from '../DashboardServers/DeleteServerDialog'
import { useRunningCostDisplay } from '@/hooks/useRunningCostDisplay'
import { ServerDetailHeader, ServerPricingInfo } from './ServerDetailHeader'
import { ServerDetailContent } from './ServerDetailContent'
import { SpecCard } from '@/components/spec-card'
import { useServerViewStore } from '@/store/ServerViewStore'

export const DashboardServerDetail = () => {
  const { orgId } = useTokenAndOrgId()
  const { serverId } = useParams({
    from: '/$orgId/dashboard/servers/$serverId',
  })
  const serverIdNum = Number(serverId)
  const navigate = useNavigate()
  
  // Get the active tab from Zustand store
  const activeTab = useServerViewStore((state) => state.activeTab)

  const {
    data: serverInstance,
    isLoading,
    isError,
  } = useGetServerInstance(serverIdNum, {
    enabled: !!serverIdNum,
  })

  // Derived data
  const starttime = serverInstance?.started_at
  const uptime = useElapsedTime(starttime)
  const runningCostDollars = useRunningCostDisplay({
    started_at: starttime,
    hourly_price_cents: serverInstance?.price,
  })
  const priceInDollars = (serverInstance?.price ?? 0) / 100
  const createdDate = serverInstance?.created_at
    ? formatDate(serverInstance.created_at)
    : ''

  // Pricing info object (grouping related data)
  const pricingInfo: ServerPricingInfo = {
    runningCostDollars: String(runningCostDollars),
    priceInDollars,
  }

  // Navigation handlers
  const onBackClick = useCallback(() => {
    if (orgId) {
      navigate({
        to: '/$orgId/dashboard/servers',
        params: { orgId: String(orgId) },
        // Pass the active tab as state
        state: { serverViewType: activeTab } as Record<string, unknown>,
      })
    } else {
      console.error('orgId is not available')
    }
  }, [orgId, navigate, activeTab])

  const onDeleteServerConfirm = () => {
    navigate({
      to: '/$orgId/dashboard/servers',
      params: { orgId: String(orgId) },
      // Pass the active tab as state
      state: { serverViewType: activeTab } as Record<string, unknown>,
    })
  }

  const onCreateNewServerClick = () => {
    if (orgId) {
      navigate({
        to: '/$orgId/dashboard/servers/new',
        params: { orgId: String(orgId) },
      })
    } else {
      console.error('orgId is not available')
    }
  }

  // Loading and error states
  if (isLoading) {
    return (
      <div className="p-6 max-w-3xl mx-auto">
        <BackButton onClick={onBackClick} />
        <Spinner />
      </div>
    )
  }

  if (isError || !serverInstance) {
    return (
      <div className="p-6 max-w-3xl mx-auto">
        <BackButton onClick={onBackClick} />
        <p className="text-destructive">Failed to load server details.</p>
      </div>
    )
  }

  // Only show the cost card when server is running
  const showCostCard = serverInstance.status === 'running'

  return (
    <div>
      <PageHeader onCreateNewServerClick={onCreateNewServerClick} />

      <div className="px-6 py-4 max-w-5xl mx-auto">
        <BackButton onClick={onBackClick} />

        <div className="bg-card rounded-lg border shadow-sm">
          <div className="p-6">
            <ServerDetailHeader
              serverId={serverIdNum}
              serverInstance={serverInstance}
              uptime={uptime}
              pricingInfo={pricingInfo}
              createdDate={createdDate}
            />

            {showCostCard && (
              <div className="mt-6">
                <SpecCard
                  icon={<DollarSign className="h-5 w-5" />}
                  title="Real-time Cost"
                  items={[
                    {
                      label: 'Current Cost',
                      value: `$${runningCostDollars}`,
                    },
                    {
                      label: 'Rate',
                      value: `$${priceInDollars.toFixed(2)}/hr`,
                    },
                  ]}
                  variant="default"
                  minHeight="min-h-[80px]"
                />
                <p className="text-xs text-muted-foreground mt-1 flex items-center gap-1">
                  <span className="h-2 w-2 rounded-full bg-emerald-600 animate-pulse"></span>
                  Cost is updated in real-time
                </p>
              </div>
            )}

            <Separator className="my-6" />
            <ServerDetailContent serverInstance={serverInstance} />
            <Separator className="my-6" />

            <div className="mt-8 flex justify-end">
              <DeleteServerDialog
                instanceId={(serverInstance as unknown as { instance_id: number }).instance_id}
                serverName={serverInstance.instance_name ?? 'this server'}
                onDeleteConfirm={onDeleteServerConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Child components
const BackButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="outline"
    size="default"
    onClick={onClick}
    className="mb-6 flex items-center gap-2 text-muted-foreground hover:text-foreground transition-colors group"
  >
    <ChevronLeft
      size={18}
      className="text-primary group-hover:transform group-hover:-translate-x-0.5 transition-transform"
    />
    <span className="font-medium">Back to servers list</span>
  </Button>
)

const PageHeader = ({
  onCreateNewServerClick,
}: {
  onCreateNewServerClick: () => void
}) => (
  <div className="w-full mx-auto">
    <div className="flex justify-between items-center mb-6">
      <h1 className="text-3xl font-medium">Running Instances</h1>
      <Button onClick={onCreateNewServerClick}>
        <Plus className="w-4 h-4 mr-2" />
        New Server
      </Button>
    </div>
  </div>
)
