import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

export type EditNameDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  initialValue: string
  onChange: (value: string) => void
}

export const EditNameDialog = ({
  isOpen,
  onClose,
  onSubmit,
  initialValue,
  onChange,
}: EditNameDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Server Name</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            value={initialValue}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter server name"
          />
          <div className="flex justify-end gap-3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit}>Save</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
