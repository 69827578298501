import { useState } from 'react'
import { OrgServerLimitCard } from './OrgServerLimitCard'
import { UserServerLimitCard } from './UserServerLimitCard'

export const ServerLimit = () => {
  const [orgServerLimit, setOrgServerLimit] = useState<number>(0)

  return (
    <div className="space-y-8 p-4">
      <OrgServerLimitCard onLimitChange={setOrgServerLimit} />
      <UserServerLimitCard orgServerLimit={orgServerLimit} />
    </div>
  )
}
