import { useMemo, useState } from 'react'
import { useGetAuditLogs } from '@/services/audit'
import { Virtuoso } from 'react-virtuoso'
import dayjs from 'dayjs'
import { CalendarPopover } from '../../components/calendar-popover'
import { DateRange } from 'react-day-picker'
import { ArrowUpDown, Download } from 'lucide-react'
import Papa from 'papaparse'
import { Button } from '@/components/ui/button'
import { Table, TableHeader, TableHead, TableRow } from '@/components/ui/table'
import utc from 'dayjs/plugin/utc'
import { getFormattedUnixDate } from '@/helpers/time'
import { Spinner } from '@/components/spinner'
dayjs.extend(utc)

const formatAuditLogType = (type: string) => {
  const words = type.split('_').filter(Boolean)

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const auditLogTypes = [
  'server_create',
  'server_delete',
  'storage_create',
  'storage_delete',
  'user_delete',
  'user_role_update',
  'user_invite',
  'user_accept_invite',
  'create_org_spending_limit',
  'create_org_user_spending_limit',
  'update_org_limit',
  'update_org_user_limit',
]

const actionMap = Object.fromEntries(
  auditLogTypes.map((type) => [type, formatAuditLogType(type)])
)

const formatMetadata = (metadata: Record<string, unknown>) => {
  return Object.entries(metadata)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ')
}

type SortDirection = 'asc' | 'desc'

export const DashboardAudit = () => {
  const [range, setRange] = useState<DateRange>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  })

  const [sortBy, setSortBy] = useState('created_at')
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc')

  const startAt = getFormattedUnixDate(range.from, { time: 'startOfDay' })
  const endAt = getFormattedUnixDate(range.to, { time: 'endOfDay' })

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
  } = useGetAuditLogs(startAt, endAt, sortBy, sortDirection)

  const allLogs = useMemo(() => data?.allLogs || [], [data])

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      )
    } else {
      setSortBy(columnId)
      setSortDirection('asc')
    }
  }

  const handleExportCSV = () => {
    if (!allLogs.length) return

    const formattedData = allLogs.map((log) => ({
      Timestamp: new Date(log.created_at).toLocaleString(),
      User: log.user?.email || 'N/A',
      Action: actionMap[log.log_type] || log.log_type,
      Metadata: formatMetadata(log.metadata),
    }))

    const csv = Papa.unparse(formattedData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'audit_logs.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (isLoading) return <Spinner />

  if (isError)
    return (
      <p className="text-sm text-destructive">
        {error instanceof Error ? error.message : String(error)}
      </p>
    )

  return (
    <div>
      <h1 className="text-3xl font-medium mb-4">Audit Log</h1>

      <div className="flex justify-between items-center mb-4">
        <CalendarPopover
          range={range}
          onSelect={(range) => {
            if (range?.from && range?.to) {
              setRange({ from: range.from, to: range.to })
            }
          }}
        />
        <Button variant="outline" onClick={handleExportCSV}>
          <Download className="w-4 h-4 mr-2" />
          Export CSV
        </Button>
      </div>

      <div className="rounded-md border">
        <Table className="w-full">
          <TableHeader className="bg-foreground/5">
            <TableRow className="flex">
              <TableHead
                className="flex-1 px-4 py-2 font-bold text-sm cursor-pointer text-foreground/90"
                onClick={() => handleSort('created_at')}
              >
                <div className="flex items-center">
                  Date
                  {sortBy === 'created_at' && (
                    <ArrowUpDown
                      className={`inline-block ml-2 h-4 w-4 ${sortDirection === 'asc' ? 'rotate-180' : ''}`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">
                User
              </TableHead>
              <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">
                Action
              </TableHead>
              <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">
                Metadata
              </TableHead>
            </TableRow>
          </TableHeader>
        </Table>

        {allLogs.length === 0 ? (
          <div className="text-center py-8 text-foreground/70">
            No audit logs found for the selected period.
          </div>
        ) : (
          <Virtuoso
            useWindowScroll
            data={allLogs}
            endReached={() => hasNextPage && fetchNextPage()}
            itemContent={(_, log) => (
              <div className="flex w-full border-b border-border">
                <div className="flex-1 text-left px-4 py-2 text-foreground">
                  {new Date(log.created_at).toLocaleString()}
                </div>
                <div className="flex-1 text-left px-4 py-2 text-foreground">
                  {log.user?.email || 'N/A'}
                </div>
                <div className="flex-1 text-left px-4 py-2 text-foreground">
                  {actionMap[log.log_type] || log.log_type}
                </div>
                <div className="flex-1 text-left px-4 py-2 text-foreground">
                  {formatMetadata(log.metadata)}
                </div>
              </div>
            )}
            components={{
              Footer: () => (
                <div className="text-center py-4 text-foreground/70">
                  {isFetchingNextPage ? (
                    <Spinner className="w-5 h-5" />
                  ) : (
                    'End of list.'
                  )}
                </div>
              ),
            }}
          />
        )}
      </div>
    </div>
  )
}
